import React, {Component, useContext} from "react";
import {AccountStateContext} from "../../Context";


const AboutUsComponent = () => {

    const {accountState} = useContext(AccountStateContext);
    const aboutUsLink = (accountState) => {
        if (accountState.isSignedIn){
            return (
                <>
                    <a className="btn btn-lg btn-light" href="/questions">Ask a question!</a>
                </>
            );
        }else{
            return (
                <>
                    <a className="btn btn-lg btn-light" href="/signup">Join now!</a>
                </>
            );
        }
    };


    return (
        <>
            <div className={"mx-auto container"} style={{marginTop: "50px"}}>

                {/*// <!-- Header-->*/}
                <header className="purple-bg bg-gradient text-white" style={{paddingTop: "50px", paddingBottom: "40px", marginBottom: "30px"}}>
                    <div className="container px-4 text-center">
                        <h1 className="fw-bolder">About us</h1>
                        <p className="lead">Welcome! Make yourself at home(:</p>
                        {aboutUsLink(accountState)}
                    </div>
                </header>
                {/*// <!-- About section-->*/}
                <section id="about">
                    <div className="container px-4">
                        <div className="row gx-4 justify-content-center">
                            <div className="col-lg-8">
                                <h2>What is boost</h2>
                                <p className="lead">
                                    We at boost are tired of seeing incorrect help on Chegg, and feeling like if you only knew the right person who's already taken this class, the whole quarter would be worlds easier.</p>

                                <p className={"lead"}>Sometimes, its hard to deny that</p>
                                <ul>
                                    <li>Occasionally, professors adopt a "do it my way or the highway" mentality</li>
                                    <li>UCLA students know more about how UCLA professors teach and expect work submitted</li>
                                    <li>Help from strangers online can be unsafe and unreliable</li>
                                    <li>Help from credible sources close to UCLA who have the right context can be much more helpful</li>
                                </ul>

                                <p className={"lead"}>As a result, boost was born into the UCLA community</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/*// <!-- Services section-->*/}
                <section className="bg-light" id="services">
                    <div className="container px-4">
                        <div className="row gx-4 justify-content-center">
                            <div className="col-lg-8">
                                <h2>Who is boost?</h2>
                                <p className="lead">The boost team is made up of UCLA students who's #1 goal is to make your life easier. We are not affiliated with UCLA and are </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/*// <!-- Services section-->*/}
                <section className="bg-light" id="services">
                    <div className="container px-4">
                        <div className="row gx-4 justify-content-center">
                            <div className="col-lg-8">
                                <h2>What can you do on boost?</h2>
                                <p className="lead">On boost, you can ask questions about specific UCLA classes. For each class supported, we have a team of qualified UCLA tutors ready to answer any question relevant to the subject. For current supported classes, <a href="/FAQ">click here</a></p>
                                <p className="lead">You can ask two kinds of questions</p>
                                <ul>
                                    <li>Public (basic) questions - These questions will be seen by anyone who visits <a href="/">boostucla.com</a> </li>
                                    <li>Private (premium) questions - These questions will only be seen by you and the tutor who is assigned to answer your question.</li>
                                    <li>Premium questions come with a more detailed response aiming to solidify your understanding of the subject.</li>
                                </ul>
                                <p className={"lead"}>Check out our list of FAQ's <a href="/FAQ">here</a>!</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/*// <!-- Contact section-->*/}
                <section id="contact">
                    <div className="container px-4">
                        <div className="row gx-4 justify-content-center">
                            <div className="col-lg-8">
                                <h2>Contact us</h2>
                                <p className="lead">The easiest way to contact us is through our ig @boostucla. If you have any questions at all, feel free to shoot a dm our way!</p>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    );
};

export default AboutUsComponent
