
import React, {useEffect, useState, createContext} from 'react';
import axios from "axios";
import _ from "lodash";

export const AccountStateContext = createContext();

export const AccountStateProvider = (props) => {

    const initialState = { // if no localStorage Exists
        isSignedIn: false,
        userID: '',
        firstname: '',
        lastname: '',
        profilepictureURL: ''
    }
    const localStorageState = JSON.parse(localStorage.getItem("accountState"));
    const [accountState, setAccountState] = useState(!!localStorageState ? localStorageState: initialState);

    useEffect(() => {

        axios.get("/api/isthisusersignedin").then(response => {
            let newState = {
                userID: response.data.userID,
                isSignedIn: true,
                firstname: response.data.firstname,
                lastname: response.data.lastname,
                profilepictureURL: response.data.profilepictureURL
            }

            // if localstorage session is different from our server, then reset account state
            if (!_.isEqual(localStorageState, newState)){
                localStorage.setItem('accountState', JSON.stringify(newState));
                setAccountState(prevState => newState);
            }
        }).catch(err => {
            if (!!err?.response?.data && err.response.data.toString() === "Not logged in on server side"){
                localStorage.removeItem('accountState');
            }else if (!!err?.response?.data && err.response.data.toString().startsWith("Proxy error: Could not proxy request")){
                localStorage.removeItem('accountState');
            }else if (!!err?.response?.data?.message && err.response.data.message.toString() === "Not signed in to boost"){
                localStorage.removeItem('accountState');
            }else{
                console.log("Error connecting to server: ", err.response.data.toString());
                localStorage.removeItem('accountState');
            }
        });
    }, [localStorageState]);
    // The array here in the parameters just means any dependencies
    // By not having any dependencies, this runs at the beginning of each react reload


    return (
        <AccountStateContext.Provider value={ {accountState, setAccountState}}>
            {props.children}
        </AccountStateContext.Provider>
    );
}



export const AccountStateConsumer = AccountStateContext.Consumer;