import React, {Component, useContext, useEffect, useState} from "react";
import AnswerPublicQComponent from "../components/AnswerPublicQ/AnswerPublicQComponent";
import AnswerPrivateQComponent from "../components/AnswerPrivateQ/AnswerPublicQComponent";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {AccountStateContext} from "../Context";
import axios from "axios";


const AnswerPrivateQ = () => {


    const searchQ = useLocation().search;
    const {QID} = queryString.parse(searchQ);
    const [question, setQuestion] = useState();
    const [comments, setComments] = useState([]);
    const {accountState} = useContext(AccountStateContext);

    useEffect(() => {
        const params = {
            QID: QID
        };
        axios.get('/api/admin/get/question', {params: params}).then(response => {
            console.log(response, 'i never ran')
            setQuestion(response.data.question);
        }).catch(err => {
            console.log(err);
        });


        const params2 = {
            QID: QID
        };
        axios.get('/api/admin/get/question/comments', {params: params2}).then(response => {
            console.log(response, 'tiny');
            setComments(response.data.comments)
        }).catch(err => {
            console.log(err);
        });
    },[QID]);

    return (
        <>
            <AnswerPrivateQComponent question={question} comments={comments}/>
        </>
    );
};

export default AnswerPrivateQ
