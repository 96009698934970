import React, {useContext} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useParams} from 'react-router';
import {AccountStateContext} from "../../Context";




const CounterofferMyQuestionModal = ({showCounterofferModal, handleCloseCounterofferModal, counterofferAmount, setCounterofferAmount, counterofferDetails}) => {
    /*
    *
    * This modal is when a user is countering back for a question that they asked themselves
    *
    *
    * */
    const {QID} = useParams();
    const {accountState} = useContext(AccountStateContext);

    const handleChangeCounter = (event) => {
        setCounterofferAmount(event.target.value);
    };

    const handleSubmitCounteroffer = (event) => {
        event.preventDefault();
        submitCounteroffer(QID, accountState.userID, counterofferAmount, counterofferDetails.countererID);
        handleCloseCounterofferModal();
    };

    return(
        <Modal
            show={showCounterofferModal}
            onHide={handleCloseCounterofferModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Counteroffer: ${counterofferAmount}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitCounteroffer} id={"submit-counteroffer-form"} >
                    <Form.Label>How much will you offer to pay? In USD</Form.Label>
                    <Form.Row >
                        <Form.Group controlId="signinformID">
                            {/*<Form.Label>How much are you offerring? remember that BookStack only takes a percentage to process credit/debit information. Maybe we can include a part that says how much they would get on the other side</Form.Label>*/}
                            <Form.Control autoComplete={"off"} required type="number" name="counteroffer" placeholder="Counteroffer" step={0.01} precision={2} value={counterofferAmount} onChange={handleChangeCounter}/>

                        </Form.Group>

                    </Form.Row>

                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I agree to terms of service"  name="counterAgreetoTermsofService" required />
                    </Form.Group>

                    <Form.Text className="text-muted">
                        Remember, you'll be able to send a message after this
                    </Form.Text>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCounterofferModal}>
                    Close
                </Button>
                <Button type={"submit"} form={"submit-counteroffer-form"} variant="primary">Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};


const submitCounteroffer = (QID, myUID, counterofferAmount, counterThisUID) => {

    const params = {
        QID: QID,
        UID: myUID,
        iCounterThisUID: counterThisUID,
        counterToMyQuestionPrice: counterofferAmount
    }
    axios.post('/api/counterthiscounterformyquestion', params).then(response => {
        alert("Successfully submitted counteroffer")
    }).catch(err => {
        console.error(err.message);
    });
};



export default CounterofferMyQuestionModal
