import React, {useEffect, useState, useRef} from "react";
import ManageCounterofferButtonManager from "./ManageCounterofferButtonManager";
import {sendMessageToSocket} from "../../Socket";
// In this component, we can assume that the counteroffer has already been made and the msg container exists



export default function ManageCounterDetailsComponent({conversation, socket, question, counterofferToMyQuestion}) {

    const [typedMessage, setTypedMessage] = useState("");
    const changeTypedMessage = (event) => {
        setTypedMessage(event.target.value);
    }
    const messagesEndRef = useRef(null);
    const scrollToBottomOfMessages = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    };
    useEffect(() => {
        scrollToBottomOfMessages();
    }, [conversation]);

    const sendMessage = () => {
        const roomID = question.QID.toString() + counterofferToMyQuestion.COID.toString();
        sendMessageToSocket(roomID, typedMessage, question.QID, 'question');
        setTypedMessage("");
    };

    const keyDownSendMessage = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }




    const awaitingPaymentButton = (state) => {
        if (state === "accepted"){
            return(
                <button className="border-0 py-14 br-12 shadow-2 px-20 bg-green text-white fs12 f-w-b">Pay</button>
            );
        }
    }

    const determineStateMessage = (counterState, questionState) => {

        if (counterState.toString() === "countered"){
            return "Awaiting your response";
        }else if (counterState.toString() === "counteredByAsker"){
            return "Awaiting their response";
        }else if(counterState.toString() === "accepted" && questionState === "countered") {
            return "Looks like they have accepted your last offer! Are you going to finalize and accept or counter?";
        }else if(counterState.toString() === "accepted" && questionState === "accepted") {
            return "Congrats ya'll have come to an agreement!!🎉🎉";
        }else {
            return "Processing ..."
        }
    }

    const fileCards = (attachments) => {
        return attachments.map(attachment => {
            return(
                <div className="mt-27 bg-white br-24 py-14 px-10">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="text-dark2">
                            <p className="fs20 mb-0 f-w-b">Attachment</p>
                            {/*<p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                        </div>
                        <button
                            className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right"
                                 width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1={17} y1={7} x2={7} y2={17}/>
                                <polyline points="8 7 17 7 17 16"/>
                            </svg>
                        </button>
                    </div>
                    <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src={attachment.picURL}/>
                </div>
            );
        });
    }

    const fileAttachments = (attachments) => {
        if (attachments.length > 0){
            return(
                <div className="h-390px overflow-y pr-16">
                    <div className="d-flex justify-content-between w-full">
                        <p className="fs24 mb-0">Attachments</p>
                        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={5} cy={12} r={1} />
                                <circle cx={12} cy={12} r={1} />
                                <circle cx={19} cy={12} r={1} />
                            </svg>
                        </button>
                    </div>
                    <div>
                        {fileCards(attachments)}
                    </div>
                </div>
            );
        }
    }

    const chatMessages = (chatMessages) => {

        if (chatMessages.length < 1){
            return (
                <div>
                    <br/>
                    <h5 className={"align-items-center"}>Looks like no one has sent a message yet! 🙈 Don't be shy!</h5>
                </div>
            );
        }else{
            return chatMessages.map(message => {

                const determineSenderLabel = (message) => {
                    if (!!message?.sentBy){
                        return "You";
                    }else{
                        return "Them";
                    }
                };

                return (
                    <div className="bg-skyb2 br-24 px-30 py-14 shadow-2 w-full mt-20">
                        <div className="d-flex align-items-start justify-content-between">
                            <div className="d-flex align-items-start">
                                {/*<img className="w-40px h-40px rounded-full object-cover" src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" alt="" />*/}
                                <div className="ml-12">
                                    <p className="fs18 f-w-b mb-0">{determineSenderLabel(message)}</p>
                                    <p className="text-dark2 mb-0 mt-4 fs14">
                                        {/*TODO Add dates to messages*/}
                                        {/*1-May 2021 <span className="ml-6">(12:00 pm)</span>*/}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="mt-20 text-dark2">
                            {message.message.text}
                        </p>
                    </div>
                );
            });
        }
    }

    const chatSection = (showSection) => {
        if (showSection){
            return(
                <div className="mt-20 ">
                    <div className="h-390px pr-16 overflow-y mt-20">
                        {chatMessages(conversation)}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="h-49px b-purple2 w-full br-12 pr-3 pl-3 pt-3 d-flex align-items-center overflow-y">
                            {/*TODO add feature to send attachments with each message*/}
                            {/*<div className="position-relative">*/}
                            {/*    <input type="file" className="opacity-0 w-40px h-40px rounded-full z-20 position-relative" />*/}
                            {/*    <button className="position-absolute w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">*/}
                            {/*        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-paperclip text-white" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                            {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                            {/*            <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />*/}
                            {/*        </svg>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            <textarea placeholder="Type your message here..." className="overflow-y bg-transparent ml-6 h-40px pl-3 border-0 p-g w-full  resize-none" value={typedMessage} onChange={changeTypedMessage} onKeyDown={keyDownSendMessage}></textarea>
                            <button onClick={sendMessage} className="w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-telegram" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }






    return (
        <div className="container mx-auto class-detail py-30">
            <div className="d-xl-flex">
                <div className="w-70">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="fs20 mb-0">
                                Cost: <span className="f-w-b">${question.offeringPrice}</span>
                            </p>
                            <p className="mb-0 fs15 ml-24">Date: 12/04/2021</p>
                        </div>
                        <div className="d-flex align-items-center mt-res2">

                            <ManageCounterofferButtonManager counterofferDetails={counterofferToMyQuestion} questionState={question.state} />
                        </div>
                    </div>
                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">
                        <p className="mb-0 fs25 f-w-b">{question.questionTitle}</p>
                        <p className="mb-0 fs12">
                            State: {determineStateMessage(counterofferToMyQuestion.state, question.state)}
                            <br/>
                            Their latest counteroffer: ${counterofferToMyQuestion.counterPrice}
                            <br/>
                            Your latest counteroffer: ${counterofferToMyQuestion.counterPriceFromAsker}
                            <br/>
                        </p>
                    </div>
                    <QuestionBadges badges={question.courseTags}/>
                    <div className="mt-20 ">
                        <h5>Messages</h5>
                        {chatSection(true)}
                    </div>
                </div>

                <div className="w-30 pl-22 mt-res">
                    {fileAttachments(question.files)}
                    {/*TODO: Make a "more questions like this" feature*/}
                    {/*<div className="mt-35 h-390px overflow-y pr-16">*/}
                    {/*    <div className="d-flex justify-content-between w-full">*/}
                    {/*        <p className="fs24 mb-0">Attachments</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mt-27 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-10 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}



const QuestionBadges = ({badges}) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mt-30">
                <p className="fs24 mb-0">Tags</p>
                <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx={5} cy={12} r={1} />
                        <circle cx={12} cy={12} r={1} />
                        <circle cx={19} cy={12} r={1} />
                    </svg>
                </button>
            </div>
            {IndividualQuestionBadges(badges)}
        </>
    );
}

const IndividualQuestionBadges = (badges) => {
    const badgesMapping = badges?.map(badge => {
        return(
            <>
                <div className="bg-orange rounded-full px-12 py-8 shadow-4 fs13 m-10"> {badge.courseSubjectAreaAbbrev} {badge.courseNumber}</div>
            </>
        );
    });

    return (
        <>
            <div className="d-flex flex-wrap align-items-center mt-10">
                {badgesMapping}
            </div>
        </>
    );
}
