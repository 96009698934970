import React, {useContext, useEffect, useState} from 'react'
import NotMyProfile from "../components/profile-page/not-my-profile";
import axios from "axios";
import {useLocation, Redirect} from 'react-router-dom';
import queryString from 'query-string';
import {AccountStateContext} from "../Context";
import Meta from "../components/Meta";


export default function Profile() {

    const searchQ = useLocation().search;
    const theirUID = queryString.parse(searchQ).UID;
    const [user, setUser] = useState();
    const [theirFriends, setTheirFriends] = useState([]);
    const [alreadyFriends, setAlreadyFriends] = useState(false);
    const {accountState} = useContext(AccountStateContext)
    const [badges, setBadges] = useState([]);
    const [coverPhoto, setCoverPhoto] = useState('');
    const [profilePictureURL, setProfilePictureURL] = useState('');

    useEffect(() => {
        let params = {
            searchedUID: theirUID
        }
        axios.get('/api/user', {params: params}).then(response => {

            setUser(response.data.userDetails);
        }).catch(err => {
            console.error(err.message);
        });
        let params2 = {
            theirUID: theirUID
        }
        axios.get('/api/get/their/friends', {params: params2}).then(response => {
            setTheirFriends(response.data.friends);
        }).catch(err => {
            console.error(err.message);
        });



        let params3 = {
            UID: accountState.userID,
            searchedBadgesUID: theirUID
        }

        axios.get('/api/profile/badges', {params: params3}).then(response => {
            setBadges(response.data.badges);
        }).catch(err => {
            console.log(err?.response?.data);
        });


        axios.get('/api/profile/profileImages', {params: {UID: accountState?.userID, searchedUID:theirUID}}).then(response => {
            setProfilePictureURL(response.data.profilePictureURL);
            setCoverPhoto(response.data.coverPhotoURL);
        }).catch(err => {
            console.error(err.message)
        });

    },[theirUID, accountState.userID]);

    useEffect(() => {
        let params2 = {
            theirUID: theirUID,
            UID: accountState.userID
        }
        if (!!accountState.userID && !!accountState.isSignedIn && !!theirUID){
            axios.get('/api/determineiffriends', {params: params2}).then(response => {
                if (response.data.areFriends){
                    setAlreadyFriends(true);
                }else{
                    setAlreadyFriends(false);
                }
            }).catch(err => {
                console.error(err.message);
            });
        }
    },[accountState.isSignedIn, theirUID, accountState.userID]);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    // Redirect to your own profile if you click on your external URL
    if (accountState.userID.toString() === theirUID){
        return (<Redirect to="/profile"/>);
    }else {
        let title = !!user?.firstname ? user.firstname : "Snoopin"
        return (
            <>
                <Meta title={title}/>
                <NotMyProfile profilePictureURL={profilePictureURL} coverPhoto={coverPhoto} badges={badges} friends={theirFriends} user={user} alreadyFriends={alreadyFriends}/>
            </>
        )
    }
}
