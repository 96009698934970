import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import useGA from './useGA';
const GAnalyticsRouterChange = ({history}) => {

    useGA();

    return <div></div>;
};

export default withRouter(GAnalyticsRouterChange);
