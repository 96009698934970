import React from "react";
import ExternalFooter from "../common/external-footer";




const HowItWorksComponent = () => {

    return (
        <>
            <div className="mx-auto container">

                {/* section1 */}
                <div className="mt-88">
                    <div className=" d-md-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div className="w-50">
                            <p className="fs60 f-w-b">
                                1. Pick from our <span className="purple-text"> 3 subscription plans</span>
                            </p>
                        </div>
                        <div className="w-50 position-relative  ">
                            <div className="w-section2 position-relative">
                                <img className="w-100pc "  alt="" src="/Images/Plans3.png" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* section2 */}
                <div className="mt-88 position-relative">
                    <div className=" d-md-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div className="w-50">
                            <p className="fs60 f-w-b">
                                2. Ask public and private questions <span className="purple-text">on boost </span>
                            </p>

                        </div>
                        <div className="w-50 position-relative  ">
                            <div className="w-section2 position-relative">
                                <img className="w-100pc "  alt="" src="/Images/RegisterClasses.png" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full d-flex flex-column align-items-end mt-97 ">
                        {/*<img alt="" className="mr-50" src="/Assets/Ellipse139.svg" />*/}
                    </div>
                </div>

                {/* section 3 */}
                <img alt="" src="/Assets/Path130.svg" className="w-img position-absolute end-0 filter50 mmr-180" />
                <div className="position-relative">
                    <div className="pt-215 ">

                        <div className="d-md-flex flex-column flex-md-row  align-items-start">
                            <div>
                                <img className="w-100pc" alt="" src="/Images/Unleash.png" />
                            </div>
                            <div className="w-50 ml-64 mt-img">
                                <p className="fs60 l72 position-relative mb-0">
                                    3. Get answered by a <span className="purple-text f-w-b">UCLA tutor within 24 hours</span>
                                </p>
                                <p className="mt-30 fs24 mb-0">Only students who have taken your class and know what they are talking about will answer</p>

                            </div>
                        </div>
                    </div>
                </div>

                {/*/!* section 5 *!/*/}
                {/*<div className="mt-328">*/}
                {/*    <div className=" position-relative">*/}
                {/*        <img alt="" src="/Assets/Group285.svg" className="w-img3 position-absolute z-0 top-0" />*/}
                {/*        <div className="px-40 d-md-flex flex-column flex-md-row align-items-center justify-content-between ">*/}
                {/*            <div className="w-50 position-relative">*/}
                {/*                <div className="card2 b-s br-5 w-full bg-white position-relative z-40">*/}
                {/*                    <p className="fs49 l45  f-w-b mb-0">*/}
                {/*                        5. Make <span class="purple-text "> real money</span> answering other students' questions*/}
                {/*                    </p>*/}
                {/*                    /!*<p class="mt-30 fs24 text-black mb-0">We know you're shy, we are too.</p>*!/*/}
                {/*                </div>*/}
                {/*                <img alt="" src="/Assets/Path398.svg" className="w-img3 position-absolute end-0 bottom-0 mmb-50 mmr-40 z-0" />*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*                <img className="w-img2 mt-img ml-res" alt="" src="/Images/MakeMoney.png" />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* section 6 */}
                {/*<div className="mt-189 w-full">*/}
                {/*    <div className="d-flex align-items-center justify-content-center w-full">*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="mml-60 z-20"></img>*/}
                {/*    </div>*/}
                {/*    <div className="d-flex align-items-center justify-content-center mt-88">*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="z-20"></img>*/}
                {/*        <div className="fs50 text-dark f-w-b ml-64">Post your own questions to any  <span className="purple-text">UCLA subject area or class </span> you want</div>*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="ml-64 z-20  mmt-30"></img>*/}
                {/*    </div>*/}
                {/*    <div className="mt-30 d-flex align-items-center justify-content-center">*/}
                {/*        <div className="fs27  t-s l13">*/}
                {/*            /!*See why millions of people across 195 <br /> countries use Boost.*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="d-md-flex flex-column flex-md-row align-items-center justify-content-center mt-70">*/}

                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};


export default HowItWorksComponent
