import React, {useContext, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CounterofferMyQuestionModal from "./CounterofferMyQuestionModal";
import AcceptCounterofferMyQuestionModal from "./AcceptCounterofferMyQuestionModal";
import axios from "axios";
import {AccountStateContext} from "../../Context";
import {useParams} from 'react-router';
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const ManageCounterofferButtonManager = ({questionState, counterofferDetails}) => {

    const {accountState} = useContext(AccountStateContext);
    const {QID} = useParams();
    // For counteroffer modal
    const handleCloseCounterofferModal = () => setShowCounterofferModal(false);
    const handleShowCounterofferModal = () => setShowCounterofferModal(true);
    const [showCounterofferModal, setShowCounterofferModal] = useState(false);
    const [counterofferAmount, setCounterofferAmount] = useState(0);

    // For accept modal
    const handleCloseAcceptModal = () => setShowAcceptModal(false);
    const handleShowAcceptModal = () => setShowAcceptModal(true);
    const [showAcceptModal, setShowAcceptModal] = useState(false);



    const handleCheckout = async(event) => {
        const stripe = await stripePromise;

        axios.post('/api/create-checkout-session', {QID: QID, UID: accountState.userID}).then(response => {
            return response.data;
        }).then(session => {
            return stripe.redirectToCheckout({sessionId: session.id})
        }).catch(err => {
            console.log("Error: ", err);
        });
    };


    if (!!counterofferDetails){
        return(
            <>
                {/*<button className="border-0 py-14 br-12 shadow-2 px-20 bg-green text-white fs12 f-w-b">Pay</button>*/}
                {awaitingPaymentButton(handleCheckout, questionState, counterofferDetails)}
                {acceptButton(questionState, counterofferDetails, showAcceptModal, handleShowAcceptModal, handleCloseAcceptModal)}
                {counterofferButton(questionState, counterofferDetails.state,showCounterofferModal,handleShowCounterofferModal, handleCloseCounterofferModal, counterofferAmount, setCounterofferAmount, counterofferDetails)}

            </>
        );
    }

};





const awaitingPaymentButton = (handleCheckout, questionState, counterofferDetails) => {
    // if the question is in accepted state (not yet paidFor state), and this is the counteroffer that was accepted also
    if (questionState === "accepted" && counterofferDetails.state === "accepted"){
        return(
            <button onClick={handleCheckout} className="border-0 py-14 br-12 shadow-2 px-20 bg-green text-white fs12 f-w-b">Pay</button>
        );
    }
}


const counterofferButton = (questionState, counterState, showCounterofferModal, handleShowCounteroffermodal, handleCloseCounterofferModal, counterofferAmount, setCounterofferAmount, counterofferDetails) => {
    if (counterState === "countered" || (counterState === "accepted" && questionState === "countered")){
        return (
        <>
            <OverlayTrigger
                key="auto"
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={counterWhileCounteredStatePopover()}
            >
                <button onClick={handleShowCounteroffermodal} className="border-0 py-14 br-12 shadow-2 px-20 bg-Dorange text-white fs12 f-w-b ml-24">Counter Offer</button>
            </OverlayTrigger>
            <CounterofferMyQuestionModal counterofferDetails={counterofferDetails} showCounterofferModal={showCounterofferModal} handleCloseCounterofferModal={handleCloseCounterofferModal} counterofferAmount={counterofferAmount} setCounterofferAmount={setCounterofferAmount} />
        </>
        );
    }else if (counterState === "counteredByAsker"){
        return (
            <OverlayTrigger
                key="auto"
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={counterWhileCounteredByAskerStatePopover()}
            >
                <button className="border-0 py-14 br-12 shadow-2 px-20 bg-Dorange2 text-white fs12 f-w-b ml-24">Awaiting their counter...</button>
            </OverlayTrigger>
        );
    }else if (counterState === "accepted"){
        return (
            <OverlayTrigger
                key="auto"
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={counterWhileAcceptedStatePopover()}
            >
                <button className="border-0 py-14 br-12 shadow-2 px-20 bg-Dorange2 text-white fs12 f-w-b ml-24">Processing...</button>
            </OverlayTrigger>
        );
    }
};

const acceptButton = (questionState, counterofferDetails, showAcceptModal, handleShowAcceptModal, handleCloseAcceptModal) => {
    if (!counterofferDetails || !questionState){
        return (null);
    }
    //when countered, or when counterer has already accepted, and asker has not yet accepted this offer
    if (counterofferDetails.state === "countered" || (counterofferDetails.state === "accepted" && questionState === "countered")){
        return (
            <>
                <OverlayTrigger
                    key="bottom"
                    delay={{ show: 250, hide: 400 }}
                    placement="bottom"
                    overlay={acceptWhileCounteredStatePopover()}
                >
                    <button onClick={handleShowAcceptModal} className="border-0 py-14 br-12 shadow-2 px-20 purple-bg text-white fs12 f-w-b ml-24">Accept</button>
                </OverlayTrigger>
                <AcceptCounterofferMyQuestionModal showAcceptModal={showAcceptModal} handleCloseAcceptModal={handleCloseAcceptModal} counterofferDetails={counterofferDetails} />
            </>
        );
    }else if (counterofferDetails.state === "counteredByAsker"){
        return (
            <OverlayTrigger
                key="bottom"
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={acceptWhileCounteredByAskerStatePopover()}
            >
                <button className="border-0 py-14 br-12 shadow-2 px-20 purple-bg2 text-white fs12 f-w-b ml-24">Accept</button>
            </OverlayTrigger>
        );
    }else if (counterofferDetails.state.toString() === "accepted" && questionState === "accepted"){
        return (
            <>
                <OverlayTrigger
                    key="auto"
                    delay={{ show: 250, hide: 400 }}
                    placement="bottom"
                    overlay={acceptWhileAcceptedStatePopover()}
                >
                    <button className="border-0 py-14 br-12 shadow-2 px-20 purple-bg2 text-white fs12 f-w-b ml-24">Accept</button>
                </OverlayTrigger>
            </>
        );
    }
};


const counterWhileAcceptedStatePopover = () => {
    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">It's a deal!🤝💯</Popover.Title>
            <Popover.Content>
                <strong>Looks like you've already come to an agreement with this deal!</strong>
            </Popover.Content>
        </Popover>
    );
}

const counterWhileCounteredByAskerStatePopover = () => {
    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">Now we play the waiting game🤔</Popover.Title>
            <Popover.Content>
                <strong>They've received your offer and are deciding what to do! Hang tight</strong>
            </Popover.Content>
        </Popover>
    );
};

const counterWhileCounteredStatePopover = () => {
    // This is when the asker has already returned a counteroffer to our potential answerer
    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">What are you gonna do?😲</Popover.Title>
            <Popover.Content>
                <strong>Ah, the ancient art of negotiation🤑 </strong>
            </Popover.Content>
        </Popover>
    );
};


const acceptWhileCounteredByAskerStatePopover = () => {

    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">Waiting for their decision👀</Popover.Title>
            <Popover.Content>
                <strong>'Patience is not the ability to wait, but the ability to keep a good attitude while waiting'<br/> -some person at some point in time🤯</strong>
            </Popover.Content>
        </Popover>
    );
};

const acceptWhileCounteredStatePopover = () => {

    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">You ready to rumble?😵‍💸</Popover.Title>
            <Popover.Content>
                <strong>Accept the question at the last price they last offered 💵🔌 </strong>
            </Popover.Content>
        </Popover>
    );
};


const acceptWhileAcceptedStatePopover = () => {
    return(
        <Popover id={`popover-positioned-bottom`}>
            <Popover.Title as="h3">Double trouble🥸</Popover.Title>
            <Popover.Content>
                <strong>Don't accept this twice😢😕 </strong>
            </Popover.Content>
        </Popover>
    );
}

export default ManageCounterofferButtonManager
