import {Link, useHistory} from "react-router-dom"
import Button from "react-bootstrap/Button";
import React, {useContext, useEffect, useState} from "react";
import queryString from "query-string";
import axios from "axios";
import {AccountStateContext} from "../Context";


const Header = ({ sidebar, setSidebar }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [profilePictureURL, setProfilePictureURL] = useState('');
    const {accountState} = useContext(AccountStateContext);
    const changeSearchTerm = (event) => {
        setSearchTerm(event.target.value)
    }
    const history = useHistory();

    const searchForSearchTerm = () => {

        const query = queryString.stringify({searchTerm: searchTerm});

        history.push(`/search?${query}`)
    };

    const keyDownSearchForSearchTerm = (event) => {
        if (event.key === "Enter"){
            event.preventDefault();
            searchForSearchTerm();
        }
    };

    useEffect(() => {

        let params = {
            UID: accountState.userID,
            searchedUID: accountState.userID
        }
        axios.get("/api/profile/profileImages", {params: params}).then(response => {
            setProfilePictureURL(response.data.profilePictureURL);
        }).catch(err => {
            console.error(err.response.data);
        })


    }, [accountState.userID]);


    return (
        <>
            <div className="header-spacer" />
            <div className="mobile-header align-items-center justify-content-between">
                <Link to="/"><img alt="" src="/Assets/Group539.svg" /></Link>
                <div className="bet-card-outer flex-shrink-0">
                    {!sidebar ? (
                        <img onClick={() => setSidebar(true)} className="light-img" src={"/Assets/playlist-play.png"} alt="" />
                    ) : (
                        <svg onClick={() => setSidebar(false)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                    )}
                </div>
            </div>
            <div className="mobile-header align-items-center justify-content-between">
                <div className="d-flex align-items-center w-80 h-64px br-10 b-input justify-content-center rounded-full flex-shrink-0">

                     <input onKeyDown={keyDownSearchForSearchTerm} value={searchTerm} onChange={changeSearchTerm} className="p-b fs15 w-full pl-20 border-0" placeholder="You trynna search somethin or what?" />
                </div>
                {/*<div className="ml-10 b-input br-10 h-64px pl-22 pr-22 w-35 d-flex pt-22 pb-22 ">*/}
                {/*    <select className="fs15 border-0 w-full focus-outline-none">*/}
                {/*        <option>Filter</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Button className="btn-hero text-white purple-bg border-0 fs17">*/}
                {/*        Search2*/}
                {/*    </Button>*/}
                {/*</div>*/}

                <div onClick={searchForSearchTerm} className="cursor-pointer ml-48 position-relative">

                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#24386d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx={10} cy={10} r={7} />
                        <line x1={21} y1={21} x2={15} y2={15} />
                    </svg>

                </div>
                <div className="ml-48 rounded-full w-46px h-46px position-relative flex-shrink-0">

                </div>
            </div>
            <div className="header d-lg-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center col-lg-8">
                    <div className="d-flex align-items-center w-40 h-64px br-10 b-input pt-22 pb-22 pl-22">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search h-30px w-30px" width={30} height={30} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#24386d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx={10} cy={10} r={7} />
                            <line x1={21} y1={21} x2={15} y2={15} />
                        </svg>
                        <input onKeyDown={keyDownSearchForSearchTerm} value={searchTerm} onChange={changeSearchTerm} className="p-b fs15 w-full pl-20 border-0" placeholder="Search questions..." />
                    </div>
                    {/*<div className="ml-10 b-input br-10 h-64px pl-22 pr-22 w-35 d-flex pt-22 pb-22 ">*/}
                    {/*    <select className="fs15 border-0 w-full focus-outline-none">*/}
                    {/*        <option>Filter</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <div>
                        <button className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14" onClick={searchForSearchTerm}>
                            Search
                        </button>
                    </div>
                </div>
                <div className="d-md-flex justify-content-md-end mt-md-2 mt-lg-0">
                    <div className="col-lg-4 ml-10 d-flex align-items-center justify-content-end pr-67">
                        {/*<div className="cursor-pointer ml-48 position-relative">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                        {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                        {/*        <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />*/}
                        {/*        <path d="M9 17v1a3 3 0 0 0 6 0v-1" />*/}
                        {/*    </svg>*/}
                        {/*    <div className="w-7px h-7px rounded-full position-absolute top-0 right-0 bg-red"></div>*/}
                        {/*</div>*/}
                        {/*<div className="ml-48 rounded-full w-46px h-46px">*/}
                        {/*    <Link to="/profile"><img alt={""} className={"w-46px h-46px"}  src={profilePictureURL}  /></Link>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Header;
