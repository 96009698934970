import React from "react";
import Modal from "react-bootstrap/Modal";


const GeneralModal = ({showModal, handleCloseModal, acceptButtonMessage, denyButtonMessage, bodyMessage, titleMessage, functionToExecuteUponSubmission}) => {


    return(
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            backdrop="static"
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{titleMessage} </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h4>{bodyMessage}</h4>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleCloseModal} className={"btn-hero text-white bg-gray border-0 fs17"}  >
                    {denyButtonMessage}
                </button>
                <button onClick={functionToExecuteUponSubmission} className={"btn-hero text-white purple-bg border-0 fs17"} type="submit" >
                    {acceptButtonMessage}
                </button>


                {/*<ConfirmAction showConfirmAction={showConfirmAction} handleCloseConfirmAction={handleCloseConfirmAction}/>*/}
            </Modal.Footer>
        </Modal>
    );
};

export default GeneralModal
