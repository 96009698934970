import {useState, useEffect, useContext} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {AccountStateContext} from "../Context";
import axios from "axios";
import GeneralModal from "./common/GeneralModal";



const Sidebar = ({sidebar}) => {
    const [active, setActive] = useState("");
    const location = useLocation();
    const {accountState, setAccountState} = useContext(AccountStateContext);
    const [stateMessage, setStateMessage] = useState('');
    const history = useHistory();


    useEffect(() => {

        axios.get("/api/accountstate", {params: {UID: accountState.userID}}).then(response => {
            console.log(response.data.state, "plentyt of time to panic")
            setStateMessage(response.data.state);
        }).catch(err => {
           console.log(err?.response?.data);
        });



    },[accountState.userID]);

    useEffect(() => {
        setActive(location.pathname);
    }, [location]);




    const signout = (e) => {


        // Make server destroy the session
        axios.get('/api/signout').then((response) => {
            localStorage.removeItem('accountState');
            history.push('/');

        }).catch(err => {
            console.log("Error: ", err);
        })


        let newSignedOutState = {
            isSignedIn: false,
            userID: '',
            firstname: '',
            lastname: '',
            rerender: "nosir"

        }
        setAccountState(prevstate => newSignedOutState);

    }



    const sidebarSignoutSignupButton = (accountSTate) => {
        if (accountSTate.isSignedIn){
            return (
                <>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout h-32px w-32px" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                            <path d="M7 12h14l-3 -3m0 6l3 -3" />
                        </svg>
                    </div>
                    <Link onClick={signout}><div className="fs22 ml-20">Sign Out</div></Link>
                </>
            );
        }else{
            return (
                <>
                    <>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout h-32px w-32px" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                <path d="M7 12h14l-3 -3m0 6l3 -3" />
                            </svg>
                        </div>
                        <Link to={"/signup"}><div className="fs22 ml-20">Sign Up</div></Link>
                    </>
                </>
            );
        }
    };

    const myQuestions = (accountState) => {
        if (accountState.isSignedIn){
            return (
                <>
                    <Link to="/questions">
                        <div className={`${active.indexOf("question") !== -1 && "item-active"} card4 text-gray4 d-flex align-items-center cursor-pointer`}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-zoom-question h-32px w-32px" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx={10} cy={10} r={7} />
                                    <path d="M21 21l-6 -6" />
                                    <line x1={10} y1={13} x2={10} y2="13.01" />
                                    <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474" />
                                </svg>
                            </div>
                            <div className="fs22 ml-20">My Questions</div>
                        </div>
                    </Link>
                </>
            );
        }
    };

    const FAQ = (accountState) => {
        return (
            <>
                <Link to="/FAQ">
                    <div className="card4 text-gray4 d-flex align-items-center cursor-pointer">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-book" viewBox="0 0 16 16">
                                <path
                                    d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                            </svg>
                        </div>
                        <div className="fs22 ml-20">FAQ</div>
                    </div>
                </Link>
            </>
        );
    };

    const howItWorksTab = (accountState) => {
        return (
            <>
                <Link to="/howitworks">
                    <div className="card4 text-gray4 d-flex align-items-center cursor-pointer">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-award" viewBox="0 0 16 16">
                                <path
                                    d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/>
                                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                            </svg>
                        </div>
                        <div className="fs22 ml-20">How it works</div>
                    </div>
                </Link>
            </>
        );
    };

    const aboutUsTab = (accountState) => {
        return (
            <>
                <Link to="/aboutus">
                    <div className="card4 text-gray4 d-flex align-items-center cursor-pointer">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-people-fill" viewBox="0 0 16 16">
                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                <path fill-rule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                            </svg>
                        </div>
                        <div className="fs22 ml-20">About us</div>
                    </div>
                </Link>
            </>
        );
    };

    const settingsButton = (accountState) => {
        if (accountState.isSignedIn){
            return (
                <>
                    <Link to="/settings">
                        <div className="card4 text-gray4 d-flex align-items-center cursor-pointer">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings h-32px w-32px" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                                    <circle cx={12} cy={12} r={3} />
                                </svg>
                            </div>
                            <div className="fs22 ml-20">Settings</div>
                        </div>
                    </Link>
                </>
            );
        }
    };



    return (
        <>
            <div className="sidebar-spacer" />
            <div className={`sidebar ${sidebar?"transform-0":"transform-left"}`}>
                <div className="desktop pl-20">
                    <Link to="/"><img alt="" src="/Assets/Group539.svg" /></Link>
                </div>
                <div className="mt-34">


                    <SidebarButton message={stateMessage}/>
                    <div className="mt-27">
                        <Link to="/">
                            <div className={`${active.indexOf("profile") !== -1 && "item-active"} card4 text-gray4 d-flex align-items-center cursor-pointer`}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user h-32px w-32px" width={32} height={32} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx={12} cy={7} r={4} />
                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    </svg>
                                </div>
                                <div className="fs22 ml-20">Home</div>
                            </div>
                        </Link>

                        <Link to="/browse/questions">
                            <div className={`${active.indexOf("class") !== -1 && "item-active"} card4 text-gray4 d-flex align-items-center cursor-pointer`}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                         className="bi bi-question-square" viewBox="0 0 16 16">
                                        <path
                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                        <path
                                            d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                    </svg>
                                </div>
                                <div className="fs22 ml-20">Public Questions</div>
                            </div>
                        </Link>


                        {myQuestions(accountState)}
                        {FAQ(accountState)}
                        {howItWorksTab(accountState)}
                        {aboutUsTab(accountState)}
                        {settingsButton(accountState)}
                    </div>
                </div>

                <div className="position-absolute bottom-0">
                    {/*<img className="w-full" alt="" src="/Assets/Group 541.png" />*/}
                    <div className="mt-26 pb-21 d-flex align-items-center cursor-pointer text-gray4">

                        {/*<div className="fs22 ml-20">Sign Out</div>*/}



                            <div className="fs22 ml-20">hit us up on ig @boostucla</div>
                    </div>
                    <div className="mt-26 pb-21 d-flex align-items-center cursor-pointer text-gray4">

                        {/*<div className="fs22 ml-20">Sign Out</div>*/}


                        {sidebarSignoutSignupButton(accountState)}
                    </div>
                </div>
            </div>
        </>
    );
};

const stripeStates = {
    EMAILUNVERIFIED: "email unverified",
    STRIPEUNINITIALIZED: "stripe uninitialized",
    STRIPEINITIALIZED: "stripe initialized",
    PICKSUBSCRIPTION1: "never subscribed",
    PICKSUBSCRIPTION2: "not subscribed now",
    SUBSCRIBEDBASIC: "basic",
    SUBSCRIBEDPREMIUM: "premium",
};

Object.freeze(stripeStates);





const SidebarButton = ({message}) => {

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const {accountState} = useContext(AccountStateContext);

    switch (message) {
        case stripeStates.EMAILUNVERIFIED:
            return <VerifyEmailSection UID={accountState.userID} showModal={showModal} handleShowModal={handleShowModal} handleCloseModal={handleCloseModal} />
        case stripeStates.PICKSUBSCRIPTION1:
        case stripeStates.PICKSUBSCRIPTION2:
            return <PickSubscriptionPlan/>
        case stripeStates.SUBSCRIBEDBASIC:
        case stripeStates.SUBSCRIBEDPREMIUM:
            return <ManageSubscriptionPlan UID={accountState.userID}/>
        case stripeStates.STRIPEUNINITIALIZED:
            return <SignUpForStripe UID={accountState.userID}/>;
        case stripeStates.STRIPEINITIALIZED:
            return <GoToStripeDashboard UID={accountState.userID}/>
        default:
            return null;
    }

};


const VerifyEmailSection = ({ UID, handleShowModal, showModal, handleCloseModal}) => {


    const bodyMessage = "Clicking send will resend a verification email📨";
    const titleMessage = "Resend verification email";

    return(

        <>
            <Link onClick={handleShowModal}>
                <div className="bg-graylight pl-24 pr-24 pb-16 pt-20 br-10 d-flex align-items-center justify-content-between">
                    <div>
                        {/*<div className="fs12 text-gray4">Balance Go to stripe dashboard</div>*/}
                        <div className="fs22 mt-9">Verify email🤩️</div>
                    </div>

                </div>
            </Link>
            <GeneralModal functionToExecuteUponSubmission={() => sendVerificationEmail(UID, handleCloseModal)} titleMessage={titleMessage} bodyMessage={bodyMessage} showModal={showModal} handleCloseModal={handleCloseModal} acceptButtonMessage={"Send"} denyButtonMessage={"Cancel"} />
        </>
    );
};


const sendVerificationEmail = (UID, handleCloseModal) => {

    const params = {
        UID: UID
    }
    axios.post("/api/resendemailverification", params).then(response => {
        alert(response.data.message);
        handleCloseModal();
    }).catch(err => {
        handleCloseModal();

    });
};

const manageSubWithStripe = (UID) => {
    axios.get('/api/stripe/customerportal', {params: {message: 'go into my dash', UID: UID}}).then(response => {
        window.location.href = response.data.session.url;
        console.log(response, 'yea babyz')
    }).catch(err => {
        console.log(err?.response?.data)
    });
};

const signupWithStripe = (UID) => {
    axios.post('/api/onboard-user', {message: 'Im the message', UID: UID}).then(response => {
        window.location.href = response.data.url;
    }).catch(err => {
        console.log(err?.response?.data)
    });
};


const fetchStripeLoginToDashboard = (UID) => {
    axios.post('/api/fetchuserdashboard', {message: 'go into my dash', UID: UID}).then(response => {
        window.location.href = response.data.url;
    }).catch(err => {
        console.log(err?.response?.data)
    });
};

const PickSubscriptionPlan = () => {
    return (
        <>
            <Link to={'/imhome'}>
                <div className="bg-graylight pl-24 pr-24 pb-16 pt-20 br-10 d-flex align-items-center justify-content-between">
                    <div>
                        {/*<div className="fs12 text-gray4">Balance Go to stripe dashboard</div>*/}
                        <div className="fs22 mt-9">Pick subscription💸💸</div>
                    </div>
                </div>
            </Link>
        </>
    );
};

const ManageSubscriptionPlan = ({UID}) => {
    return(
        <>
            <Link onClick={() => manageSubWithStripe(UID)}>
                <div className="bg-graylight pl-24 pr-24 pb-16 pt-20 br-10 d-flex align-items-center justify-content-between">
                    <div>
                        {/*<div className="fs12 text-gray4">Balance Go to stripe dashboard</div>*/}
                        <div className="fs22 mt-9">Manage Subscription 💸💸</div>
                    </div>
                </div>
            </Link>
        </>
    );
};

const SignUpForStripe = ({UID}) => {
    return(
        <>
            <Link onClick={() => signupWithStripe(UID)}>
                <div className="bg-graylight pl-24 pr-24 pb-16 pt-20 br-10 d-flex align-items-center justify-content-between">
                    <div>
                        {/*<div className="fs12 text-gray4">Balance Go to stripe dashboard</div>*/}
                        <div className="fs22 mt-9">Sign up to make 💸💸</div>
                    </div>
                </div>
            </Link>
        </>
    );
};


const GoToStripeDashboard = ({UID}) => {
    return(

        <>
            <Link onClick={() => fetchStripeLoginToDashboard(UID)}>
            <div className="bg-graylight pl-24 pr-24 pb-16 pt-20 br-10 d-flex align-items-center justify-content-between">
                <div>
                    {/*<div className="fs12 text-gray4">Balance Go to stripe dashboard</div>*/}
                    <div className="fs22 mt-9">Stripe dash🤑</div>
                </div>

            </div>
            </Link>
        </>

    );
};
export default Sidebar;
