import React, {useContext, useEffect, useState} from "react";
import NavigationTabs from "../common/navigation-tabs";
import AskedandInProgress from "./asked-inprogress-questions";
import AskedandFinalized from "./asked-finalized-questions";

import "pure-react-carousel/dist/react-carousel.es.css";
import axios from "axios";
import {AccountStateContext} from "../../Context";
export default function Questions() {

    const [viewingThisTab, setViewingThisTab] = useState("asker")
    const {accountState} = useContext(AccountStateContext);

    const [questionsAskedPublic, setQuestionsAskedPublic] = useState([]);
    const [questionsAskedPrivate, setQuestionsAskedPrivate] = useState([]);


    useEffect(() => {

        const params1 = {
            UID: accountState.userID,
            qType: "public"
        }

        axios.get('/api/dashboard/my/questions', {params: params1}).then(response => {
            setQuestionsAskedPublic(response.data.questions)
        }).catch(err => {
            console.error(err.message);
        });

        const params2 = {
            UID: accountState.userID,
            qType: "private"
        }

        axios.get('/api/dashboard/my/questions', {params: params2}).then(response => {
            setQuestionsAskedPrivate(response.data.questions)
        }).catch(err => {
            console.error(err.message);
        });


    }, [accountState.userID]);

    return (
        <div className="container question-alt">
            <NavigationTabs viewingThisTab={viewingThisTab} setViewingThisTab={setViewingThisTab}/>
            <AskedandInProgress questions={questionsAskedPublic}/>
            <AskedandFinalized questions={questionsAskedPrivate}/>
        </div>
    );
}
