import React from "react";
import HowItWorksComponent from "../components/HowItWorks/HowItWorksComponent";

import Meta from "../components/Meta";
import ExternalNavbar from "../components/common/external-navbar";
import ExternalFooter from "../components/common/external-footer";

const HowItWorks = () => {



    return (
        <>
            <Meta title="How it works"/>
            <ExternalNavbar/>
            <HowItWorksComponent/>
            <ExternalFooter/>
        </>
    );
};

export default HowItWorks
