import React, {Component, useContext} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
const PickSubComponent = () => {

    const {accountState} = useContext(AccountStateContext);
    const checkoutBasic = () => {
        checkoutPlan(accountState.userID, "basic").then(r => {
            console.log(r, "R MATEY")
        });
    };
    const checkoutPremium = () => {
        checkoutPlan(accountState.userID, "premium").then(r => {
            console.log(r, "R MATEY")
        });
    };

    return (
        <>
            <br/>
            <h1>Subscriptions</h1>
            <Card className="w-100pc text-center">
                    <Card.Header>Basic</Card.Header>
                    <Card.Body>
                        {/*<Card.Title>Special title treatment</Card.Title>*/}
                        <Card.Text>
                            With supporting text below as a natural lead-in to additional content.
                        </Card.Text>
                        <Button onClick={checkoutBasic} variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            <Card className="w-100pc text-center ">
                <Card.Header>Premium</Card.Header>
                <Card.Body>
                    {/*<Card.Title>Special title treatment</Card.Title>*/}
                    <Card.Text>
                        Ask both public and private questions
                    </Card.Text>
                    <Button onClick={checkoutPremium} variant="primary">Pick me (:</Button>
                </Card.Body>
                {/*<Card.Footer className="text-muted">2 days ago</Card.Footer>*/}
            </Card>



        </>
    );
};


const checkoutPlan = async(UID, subType) => {
    const stripe = await stripePromise;
    const params = {
        UID: UID,
        subscriptionType: subType
    };
    axios.post("/api/create-checkout-session-subscription", params).then(response => {
        console.log(response)
        stripe.redirectToCheckout({sessionId: response.data.id})
    }).catch(err => {
        console.log(err)
    });

};

export default PickSubComponent
