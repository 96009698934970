import React, {useContext, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import axios from "axios";
import {AccountStateContext} from "../../Context";




const SettingsComponent = ({userSettingsInfo}) => {
    return (
        <>
            <div className="container mx-auto class-detail py-30">

                <div className="">
                    <h3>Settings</h3>
                    <br/>
                    <br/>
                    {/*<UploadCoverPhoto userSettingsInfo={userSettingsInfo}/>*/}
                    {/*<UploadProfilePicture userSettingsInfo={userSettingsInfo}/>*/}
                    <SettingsForm userSettingsInfo={userSettingsInfo}/>

                </div>
            </div>
        </>
    );
/*
* firstname
* lastname
* major
* email
* profile picture
* verify email
* stripe dashboard button
*
* */

};




const UploadProfilePicture = ({userSettingsInfo}) => {
    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);

    useEffect(() => {
        const profileURL = null;
        // const profileURL = userSettingsInfo?.profilePicture;
        if (!!profileURL) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = profileURL;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(profileURL);
        }
    },[userSettingsInfo])

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }}
            />
            <div
                style={{
                    height: "100px",
                    width: "100px",
                    // border: "1px dashed black"
                }}
                onClick={() => imageUploader.current.click()}
            >
                <Image
                    roundedCircle
                    ref={uploadedImage}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute"
                    }}
                />
            </div>
            Click to upload new profile picture
        </div>
    );
}

const UploadCoverPhoto = ({userSettingsInfo}) => {
    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);


    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }}
            />
            <div
                style={{
                    height: "100px",
                    width: "100px",
                    // border: "1px dashed black"
                }}
                onClick={() => imageUploader.current.click()}
            >
                <Image
                    roundedCircle
                    ref={uploadedImage}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute"
                    }}
                />
            </div>
            Click to upload new cover photo
        </div>
    );
}

const SettingsForm = ({userSettingsInfo}) => {

    const {accountState} = useContext(AccountStateContext);
    const [validated, setValidated] = useState(false);
    const [majorSetting, setMajorSetting] = useState(userSettingsInfo?.major);
    const [profilePicToUpload, setProfilePicToUpload] = useState();
    const UID = accountState.userID;
    const [buttonMessage, setButtonMessage] = useState('Update');
    // We set as true if settings was changed
    const [settingsChanged, setSettingsChanged] = useState({
        major: false,
        profilePicture: false
    })
    const handleChangeMajor = (event) => {
        setMajorSetting(event.target.value);
        settingChangedMajor();
    };

    const handleUploadPFpic = (event) => {
        if (!!event.target.files && event.target.files.length === 1){
            setProfilePicToUpload(event.target.files[0]);
        }
        settingChangedProfilePic();
    };

    const settingChangedMajor = () => {
        setSettingsChanged(prevState => ({...prevState, major: true}));
        setButtonMessage('Apply Changes');
    }
    const settingChangedProfilePic = () => {
        setSettingsChanged(prevState => ({...prevState, profilePicture: true}));
        setButtonMessage('Apply Changes');
    }

    const handleSubmit = (event) => {
        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }
        //
        // setValidated(true);

        event.preventDefault();
        submitSettingsChange(profilePicToUpload, majorSetting, UID, settingsChanged);
        setButtonMessage("Update");
    };

    return (
        <Form enctype="multipart/form-data" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        disabled
                        required
                        type="text"
                        placeholder="First name"
                        value={userSettingsInfo?.firstname}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                        disabled
                        required
                        type="text"
                        placeholder="Last name"
                        defaultValue={userSettingsInfo?.lastname}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                        <Form.Control
                            disabled
                            type="text"
                            placeholder="Email"
                            aria-describedby="inputGroupPrepend"
                            value={userSettingsInfo?.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Row>

            {/*// The actual form data to be filled out*/}
            {/*<Row className="mb-3">*/}
            {/*    <Form.Group as={Col} md="6" controlId="validationCustom03">*/}
            {/*        <Form.Label>Major</Form.Label>*/}
            {/*        <Form.Control*/}
            {/*            name="major"*/}
            {/*            type="text"*/}
            {/*            placeholder="Can be anything you want😵‍💫"*/}
            {/*            defaultValue={userSettingsInfo?.major}*/}
            {/*            onChange={handleChangeMajor}*/}
            {/*            // value={userSettingsInfo?.major}*/}
            {/*        />*/}

            {/*    </Form.Group>*/}
            {/*</Row>*/}
            {/*<Row className="mb-3">*/}
            {/*    <Form.Group controlId="formFileMultiple" className="mb-3">*/}
            {/*        <Form.Label>Upload New Profile Picture</Form.Label>*/}
            {/*        <br/>*/}
            {/*        <Form.Control onChange={handleUploadPFpic} name={"file"} type="file" />*/}
            {/*    </Form.Group>*/}
            {/*</Row>*/}
            {/*<button className="btn-hero text-white purple-bg border-0 fs17" type="submit">*/}
            {/*    {buttonMessage}*/}
            {/*</button>*/}
        </Form>
    );
}



const submitSettingsChange = (profilepic, majorSetting, UID, settingsChanged) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(settingsChanged)) {
        if (key === "major" && value === true){
            formData.append('major', majorSetting);
        }else if (key === "profilePicture" && value === true){
            formData.append('profilePicture', profilepic);
        }
    }
    formData.append('UID', UID)
    axios.post("/api/update/settings", formData, {headers: {"Content-type": "multipart/form-data"}}).then(response => {
    }).catch(err => {
        console.error(err.message);
    });

};

export default SettingsComponent
