import React from "react";
export default function pagesCounter() {
    return (
        <div className="d-xl-flex  align-items-center justify-content-between w-full mt-43 mb-43">
            <div className="d-flex justify-content-center justify-content-between align-items-center">
                {/*<div className="h-49px br-12 bg-white  shadow-1 px-18 d-flex flex-column justify-content-center">*/}
                {/*    <select defaultValue={0} className="border-0 focus-outline-none bg-white fs14 text-dark2">*/}
                {/*        <option value={0} disabled>*/}
                {/*            Quarter*/}
                {/*        </option>*/}
                {/*        <option value={"page"}>Page</option>*/}
                {/*        <option value={"name"}>Name</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*<div className="h-49px br-12 bg-white  shadow-1 px-18 d-flex flex-column justify-content-center">*/}
                {/*    <select defaultValue={0} className="border-0 focus-outline-none bg-white fs14 text-dark2">*/}
                {/*        <option value={0} disabled>*/}
                {/*            Sort by*/}
                {/*        </option>*/}
                {/*        <option value={"page"}>Page</option>*/}
                {/*        <option value={"name"}>Name</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*<div className="mt-resmd d-none d-md-block h-49px br-12 bg-white  shadow-1 px-18 d-flex flex-column justify-content-center ml-10 fs14 text-dark2">*/}
                {/*    <div className="d-flex align-items-center">*/}
                {/*        <div>Result per page</div>*/}
                {/*        <div className="br-12 shadow-1 p-8 purple-text f-w-s bg-skyb ml-20">48</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className=" w-250px h-49px br-12 ml-10 d-flex align-items-center b-input pl-6 flex-shrink-0">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#455a64" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                {/*        <circle cx={10} cy={10} r={7} />*/}
                {/*        <line x1={21} y1={21} x2={15} y2={15} />*/}
                {/*    </svg>*/}
                {/*    <input placeholder="Search..." className="fs14 h-full border-0 p-g pl-5 bg-transparent" />*/}
                {/*</div>*/}
            </div>
            <div className="mt-res h-49px br-12 bg-white  shadow-1 px-6 d-flex flex-column justify-content-center ml-10 fs14 text-dark2">
                <div className="d-flex align-items-center justify-content-between">
                    {/*<button className="p-10 bg-white shadow-1 br-12 text-dark2 border-0">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={24} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*        <polyline points="15 6 9 12 15 18" />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    {/*<div className="d-flex align-items-center text-dark2 ml-20  mr-20">*/}
                    {/*    <div className="fs14 cursor-pointer ">01</div>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <button className="p-12 purple-light shadow-1 br-12 text-dark2 border-0 fs14  text-white ml-6">24</button>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <div className="fs14  ml-6">.</div>*/}
                    {/*    <div className="fs14 cursor-pointer ml-6">48</div>*/}
                    {/*</div>*/}
                    {/*<button className="p-10 bg-white shadow-1 br-12 text-dark2 border-0">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={24} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*        <polyline points="9 6 15 12 9 18" />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
}
