import React, {useContext} from "react";
import ClassDetail from "../components/class-detail/class-detail";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";

export default function Index() {

    const {accountState} = useContext(AccountStateContext);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return <ClassDetail />;
}
