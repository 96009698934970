import React, {useContext, useEffect, useState} from "react";
import QuestionDetail from "../components/question-detail/question-detail";
import axios from "axios";
import {AccountStateContext} from "../Context";
import {Redirect, useLocation} from "react-router-dom";
import queryString from "query-string";
import Meta from "../components/Meta";

export default function Question() {


    const {accountState} = useContext(AccountStateContext);
    const searchQ = useLocation().search;
    const {QID} = queryString.parse(searchQ);
    //The question that is going to be managed
    const [question, setQuestion] = useState({
        QID: '',
        state: '',
        files:[{}],
        questionTitle: '',
        questionDescription: '',
        dateNeededBy: '',
        remainAnonymousBool: true,
        offeringPrice: 0,
        wantQuestionPublicBool: false,
    });
    const [comments, setComments] = useState([]);
    const [activeSubscription, setActiveSubscription] = useState(false);



    useEffect(() => {
        let params = {
            QID: QID
        };
        if (accountState.isSignedIn){
            params["UID"] = accountState.userID
        }
        axios.get('/api/question', {params: params}).then(response => {
            setQuestion(response.data.question);
        }).catch(err => {
            console.log("hey dude")
            console.error(err.response.data)
        });

        let params2 = {
            QID: QID,

        };
        if (accountState.isSignedIn){
            params2["UID"] = accountState.userID
        }
        axios.get('/api/question/comments', {params: params2}).then(response => {

            setComments(response.data.comments);
            setActiveSubscription(true);
        }).catch(err => {
           console.error(err.response.data);
            if (err.response.data.toString() === "Looks like you don't have an active subscription rn! :/"){
                setActiveSubscription(false);
            }
        });
    }, [QID, accountState.userID, accountState.isSignedIn])

    return (
        <>
            <Meta title="Q" />
            <QuestionDetail activeSubscriptionBool={activeSubscription} question={question} comments={comments} />
        </>

    );
}
