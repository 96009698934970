import React, {useEffect, useState, useRef, useContext} from "react";
import ButtonManager from "./ButtonManager";
import AttachmentModal from "./AttachmentModal";
import {sendMessageToSocket} from "../../Socket";
import {AccountStateContext} from "../../Context";
import {generateRandomName} from "../../generateRandomName";
import {set} from "react-ga";

export default function QuestionDetail({activeSubscriptionBool, question, comments}) {

    const determineDateNeededBy = (dateNeededBy) => {
        if (!!dateNeededBy){
            let neededDateObject = new Date(dateNeededBy);
            let neededDate = `${neededDateObject.getMonth()}/${neededDateObject.getDate()}/${neededDateObject.getFullYear()}`;
            return (
                <>
                    <p className="mb-0 fs15 ml-24">Date needed by: {neededDate}</p>
                </>
            );
        }else {
            return null;
        }
    }

    const [show, setShow] = useState(false);
    const [modalURL, setModalURL] = useState(false);

    return (
        <div className="container mx-auto class-detail py-30">
            <div className="d-xl-flex">
                <div className="w-70">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="fs20 mb-0">
                                {/*Cost: <span className="f-w-b">${question.offeringPrice}</span>*/}
                            </p>
                            {determineDateNeededBy(question.dateNeededBy)}
                        </div>
                        <div className="d-flex align-items-center mt-res2">

                            {/*<ButtonManager counterofferDetails={counterofferDetails} setRelationshipWQuestion={setRelationshipWQuestion} setToggle={setToggle} relationship={relationship} setCounteroffer={setCounteroffer} question={question} counteroffer={counteroffer}/>*/}
                        </div>
                    </div>
                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">
                        <p className="mb-0 fs25 f-w-b">{question.questionTitle}</p>
                        <p className="mb-0 fs12">
                            {question.questionDescription}
                            {/*{displayCounterDetails(counterofferDetails, question?.state)}*/}
                        </p>
                    </div>
                    {/*<QuestionBadges badges={question.courseTags}  />*/}
                    {IndividualQuestionBadges(question.files, setModalURL, setShow)}
                    {/*<h5>Messages</h5>*/}
                    {/*{chatSection(relationship, conversation, messagesEndRef, socket, question, setTypedMessage, typedMessage, counterofferDetails)}*/}

                    {/*<Comments conversation={["super","super","super","super","super","super","super"]}/>*/}
                    <Comments question={question} activeSubscriptionBool={activeSubscriptionBool} conversation={comments} randomName={generateRandomName()}/>

                </div>
                <div className="w-30 pl-22 mt-res">
                    {fileAttachments(question.files, show, modalURL, setShow, setModalURL)}
                    {/*TODO: Make a "more questions like this" feature*/}
                    {/*<div className="mt-35 h-390px overflow-y pr-16">*/}
                    {/*    <div className="d-flex justify-content-between w-full">*/}
                    {/*        <p className="fs24 mb-0">Attachments</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mt-27 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-10 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}



const IndividualQuestionBadges = (attachments, setModalURL, setShow) => {

    const handleShow = (URL) => {
        setModalURL(URL)
        setShow(true);
    }
    const badgesMapping = attachments?.map((attachment, i) => {

        return(
            <>
                <div onClick={() => {handleShow(attachment.picURL)}} className="bg-orange rounded-full px-12 py-8 shadow-4 fs13 m-10"> Open attachment #{i + 1}</div>
            </>
        );
    });


    return (
        <>
            <div className="d-flex flex-wrap align-items-center mt-10">
                {badgesMapping}
            </div>
        </>
    );
}


const FileCards = ({attachments, modalURL, setModalURL, show, setShow}) => {

    const handleClose = () => setShow(false);
    const handleShow = (URL) => {
        setModalURL(URL)
        setShow(true);
    }
    const attachmentRendering = attachments.map((attachment, i) => {

        return(
            <div className="mt-27 bg-white br-24 py-14 px-10">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-dark2">
                        <p className="fs20 mb-0 f-w-b">Attachment {i + 1}</p>
                        {/*<p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    </div>
                    <button onClick={() => handleShow(attachment.picURL)}
                        className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right"
                             width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1={17} y1={7} x2={7} y2={17}/>
                            <polyline points="8 7 17 7 17 16"/>
                        </svg>
                    </button>
                </div>
                <img onClick={() => handleShow(attachment.picURL)} className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src={attachment.picURL}/>

            </div>
        );
    });

    return (
        <>
            {attachmentRendering}
            <AttachmentModal handleClose={handleClose} show={show} imageURL={modalURL}  />

        </>
    );
}

const fileAttachments = (attachments,show, modalURL, setShow, setModalURL) => {
    if (attachments.length > 0){

        return(
            <div className="h-390px overflow-y pr-16">
                <div className="d-flex justify-content-between w-full">
                    <p className="fs24 mb-0">Attachments</p>
                    <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx={5} cy={12} r={1} />
                            <circle cx={12} cy={12} r={1} />
                            <circle cx={19} cy={12} r={1} />
                        </svg>
                    </button>
                </div>
                <div>
                    <FileCards attachments={attachments} show={show} modalURL={modalURL} setModalURL={setModalURL} setShow={setShow}/>
                </div>
            </div>
        );
    }
}

const Comments = ({question, conversation, activeSubscriptionBool, randomName}) => {

    console.log(conversation, 'doomed')

    const {accountState} = useContext(AccountStateContext);
    const profileURL_julio = "/Images/julio2383224170.jpg"
    const profileURL_jianxing = "/Images/jianxing123.jpg"

    const section = () => {

        return (
            <div className="mt-20 ">
                <div className="d-flex align-items-center">
                    <div className="h-7px b-purple2 w-full br-12 pr-3 pl-3 pt-3 d-flex align-items-center overflow-y">
                        {/*<div className="position-relative">*/}
                        {/*    <input type="file" className="opacity-0 w-40px h-40px rounded-full z-20 position-relative" />*/}
                        {/*    /!*<button className="position-absolute w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">*!/*/}
                        {/*    /!*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-paperclip text-white" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*!/*/}
                        {/*    /!*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*!/*/}
                        {/*    /!*        <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />*!/*/}
                        {/*    /!*    </svg>*!/*/}
                        {/*    /!*</button>*!/*/}
                        {/*</div>*/}
                        {/*<textarea placeholder="Type your message here..." className="overflow-y bg-transparent ml-6 h-40px pl-3 border-0 p-g w-full  resize-none"></textarea>*/}
                        {/*<button className="w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-telegram" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                        {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                        {/*        <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="h-390px pr-16 overflow-y mt-20">
                    {comments()}
                    {/*<div className="bg-white br-24 px-30 py-14 shadow-2 w-full mt-20">*/}
                    {/*    <div className="d-flex align-items-start justify-content-between">*/}
                    {/*        <div className="d-flex align-items-start">*/}
                    {/*            <img className="w-40px h-40px rounded-full object-cover" src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" alt="" />*/}
                    {/*            <div className="ml-12">*/}
                    {/*                <p className="fs18 f-w-b mb-0">Aunat Meil</p>*/}
                    {/*                <p className="text-dark2 mb-0 mt-4 fs14">*/}
                    {/*                    1-May 2021 <span className="ml-6">(12:00 pm)</span>*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="d-flex align-items-center">*/}
                    {/*            <div className="d-flex align-items-center">*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />*/}
                    {/*                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />*/}
                    {/*                </svg>*/}
                    {/*                <p className="fs14 text-dark2 mb-0 ml-6">3</p>*/}
                    {/*            </div>*/}
                    {/*            <div className="ml-20">*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-share" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                    <circle cx={6} cy={12} r={3} />*/}
                    {/*                    <circle cx={18} cy={6} r={3} />*/}
                    {/*                    <circle cx={18} cy={18} r={3} />*/}
                    {/*                    <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />*/}
                    {/*                    <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />*/}
                    {/*                </svg>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <p className="mt-20 text-dark2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also.</p>*/}
                    {/*</div>*/}
                </div>
            </div>
        );

    };


    const comments = () => {

        if (!accountState.isSignedIn){

            return (
                <>
                    <p>‼️🚫‼Sign up to see this answer🚫‼️</p>
                </>
            );
        }

        const messages = conversation?.map(comment => {


            const name = () => {
                if (comment?.sentBy?.role.toString() === "answerer"){
                    const isCSQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "COM SCI" || tag.courseSubjectAreaAbbrev === "COMPTNG")
                    const isSpanishQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "SPAN")

                    if (isCSQuestion){
                        return "Jianxing Liu"
                    }else if (isSpanishQuestion){
                        return "Julio Ramirez"
                    }

                }else if (comment?.sentBy?.role.toString() === "asker"){
                    return randomName;
                }
            };
            const profileP = () => {
                if (comment?.sentBy?.role.toString() === "answerer"){
                    const isCSQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "COM SCI" || tag.courseSubjectAreaAbbrev === "COMPTNG")
                    const isSpanishQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "SPAN")
                    if (isCSQuestion){
                        return profileURL_jianxing;
                    }else if (isSpanishQuestion){
                        return profileURL_julio;
                    }
                }else if (comment?.sentBy?.role.toString() === "asker"){
                    return "/DefaultProfilePictures/profile2.jpg"
                }
            };
            const desc = () => {
                if (comment?.sentBy?.role.toString() === "answerer"){
                    const isCSQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "COM SCI" || tag.courseSubjectAreaAbbrev === "COMPTNG")
                    const isSpanishQuestion = question?.courseTags.some(tag => tag.courseSubjectAreaAbbrev === "SPAN")
                    if (isCSQuestion){
                        return "Certified UCLA CS Tutor"
                    }else if (isSpanishQuestion){
                        return "Fluent in Spanish"
                    }

                }
            };

            return (
                <>
                    <div className=" bg-white br-24 px-30 py-14 shadow-2 w-full">
                        <div className="d-flex align-items-start justify-content-between">
                            <div className="d-flex align-items-start">
                                <img className="w-40px h-40px rounded-full object-cover" src={profileP()} alt="" />
                                <div className="ml-12">
                                    <p className="fs18 f-w-b mb-0">{name()}</p>
                                    <p className="text-dark2 mb-0 mt-4 fs14">
                                        {desc()}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                {/*<div className="d-flex align-items-center">*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                                {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                                {/*        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />*/}
                                {/*        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />*/}
                                {/*    </svg>*/}
                                {/*    <p className="fs14 text-dark2 mb-0 ml-6">3</p>*/}
                                {/*</div>*/}
                                <div className="ml-20">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-share" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx={6} cy={12} r={3} />
                                        <circle cx={18} cy={6} r={3} />
                                        <circle cx={18} cy={18} r={3} />
                                        <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
                                        <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="mt-20 text-dark2">
                            {comment.text}
                        </p>
                    </div>
                </>
            );
        });

        if (conversation?.length > 0){
            return (
                <>
                    {messages}
                </>
            );
        }else if(conversation?.length === 0 && activeSubscriptionBool) {
            return (
                <>
                <p>Looks like this hasn't received any responses yet! :/</p>
                </>
            );
        }else if (conversation?.length === 0 && !activeSubscriptionBool){
            return (
                <>
                    <h5>Answer:</h5>
                    <p>Activate your subscription to see this answer!</p>
                </>
            );
        }
    };

    return (
        <>

            {section()}

        </>
    );


};