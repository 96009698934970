import React from "react";
import { Link } from "react-router-dom";

const ExternalFooter = () => {

    return (
        <>
            {/* footer */}
            <div className="mt-131">
                <div className="d-flex align-items-center justify-content-end w-75">
                    <img alt="" src="/Assets/Ellipse112.svg" class="mml-60 z-20"></img>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <button onClick={() => window.scroll(0, 0)} className="rounded-full purple-bg h-61px w-61px border-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-up text-white" width={20} height={16} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 15 12 9 18 15" />
                    </svg>
                </button>
            </div>
            <div className="px-10 mt-34 pb-70 bb-purple">
                <div className="px-40 d-md-flex flex-column flex-md-row align-items-start justify-content-between w-full">
                    <div className="mt-10 w-35">
                        <img src="/Assets/Group539.svg" alt="" />
                    </div>
                    <div className="mt-img d-flex flex-column  w-60">


                        <div className="d-flex w-full justify-content-end">
                            <div className=" d-flex  align-items-center w-auto">
                                <Link to="/"><div className="text-dark f-w-s fs23">Home</div></Link>
                                <Link to="/FAQ"><div className="text-dark f-w-s fs23 ml-20">boost FAQ</div></Link>
                                <Link to="/terms"><div className="text-dark f-w-s fs23 ml-20">Terms</div></Link>
                                <Link to="/privacy"><div className="text-dark f-w-s fs23 ml-20">Privacy Policy</div></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center w-full fs18 text-dark py-24">2021 All Rights Reserved</div>

        </>
    );
};
export default ExternalFooter;
