import {Link} from "react-router-dom";

const Card = ({ cardData, disabled, handleCheckout }) => {

    const manageQuestionURL = `/manage/my/question/${cardData.QID}`

    const color = (questionState) => {
        if (questionState === "accepted"){
            return "#0dd55d"; //.bg-green
        }else{
            return `#bf5dab`;
        }
    }

    const determinePricetag = (question) => {
        if (question.state === "accepted" ||
            question.state === "paidFor" ||
            question.state === "answered" ||
            question.state === "finalized"){
            return question?.finalAgreedUponPrice
        }else{
            return question?.offeringPrice;
        }
    };

    const imageURL = (data) => {
        let defaultImage = "https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80";
        return !!data.questionMainPic ? data.questionMainPic : defaultImage;
    };

    return (
        <div className="card-with-pic position-relative">
            {/*{disabled && <div className="b-overlay position-absolute inset-0" />}*/}
            <Link to={manageQuestionURL}><img className="w-full img" alt="" src={imageURL(cardData)} /></Link>
            <div className="px-15px">
                <Link to={manageQuestionURL}><h2 className="mb-0 n-props clr-grey fs12 f-w-s mt-20">{cardData.questionTitle}</h2></Link>
                <div className="d-flex justify-content-between align-items-center mt-30">
                    <div className="d-flex align-items-center">
                        {/*<img className="mr-10 user" src="/Assets/user.png" alt="" />*/}
                        {/*<p className="mb-0  n-props clr-grey fs12 font-weight-semibold">{cardData.authorName}</p>*/}
                    </div>
                    <p className="n-props fs-20 f-w-b mb-0" style={{ color: color(cardData.state) }}>
                        {/*${determinePricetag(cardData)}*/}
                    </p>
                </div>
                {disabled && (
                    <div className="mt-10 d-flex align-items-center justify-content-between">
                        <p className="fs12 text-gray2 mb-0">awaiting Paytment...</p>
                        <button onClick={() => {handleCheckout(cardData.QID)}} className="border-0 br-7 bg-green-light text-white py-8 px-12 fs12 f-w-s">Pay Now</button>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Card;
