import React, {Component} from "react";
import Meta from "../components/Meta";
import ExternalNavbar from "../components/common/external-navbar";
import ResetPWComponent from "../components/ResetPW/ResetPWComponent";
import ExternalFooter from "../components/common/external-footer";
import {Redirect, useLocation} from 'react-router-dom'
import queryString from "query-string";

const ResetPW = () => {
    const searchQ = useLocation().search;
    const {token} = queryString.parse(searchQ);
    if (!token){
        return <Redirect to="/"/>
    }else{
        return (
            <>
                <Meta title={"Here we go.."}/>
                <ExternalNavbar/>
                <ResetPWComponent token={token}/>
                <ExternalFooter/>
            </>
        );
    }
};

export default ResetPW
