import React, {useRef, useEffect, useState} from "react";
import {sendMessageToSocket} from "../../Socket";


export default function AnswerOfQuestionAnswererPerspectiveComponent({answer, socket, conversation,  setToggle, question}) {

    const [typedMessage, setTypedMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const scrollToBottomOfMessages = () => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

        };
        scrollToBottomOfMessages();
    }, [conversation]);

    return (
        <div className="container mx-auto class-detail py-30">
            <div className="d-xl-flex">
                <div className="w-70">
                    <h5>Question</h5>
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="fs20 mb-0">
                                Cost: <span className="f-w-b">${question?.offeringPrice}</span>
                            </p>
                            <p className="mb-0 fs15 ml-24">Date:fff 12/04/2021</p>
                        </div>
                        <div className="d-flex align-items-center mt-res2">

                            {/*<ButtonManager counterofferDetails={counterofferDetails} setRelationshipWQuestion={setRelationshipWQuestion} setToggle={setToggle} relationship={relationship} setCounteroffer={setCounteroffer} question={question} counteroffer={counteroffer}/>*/}
                        </div>
                    </div>


                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">
                        <p className="mb-0 fs25 f-w-b">{question?.questionTitle}</p>
                        <p className="mb-0 fs12">
                            {question?.questionDescription}

                        </p>
                    </div>

                    <hr/>
                    <h5>Answer</h5>
                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">

                        <p className="mb-0 fs12">
                            {answer?.textAnswer}
                        </p>
                    </div>
                    <hr/>
                    <br/>
                    <h5>Messages</h5>
                    {chatSection(conversation, messagesEndRef, socket, answer, setTypedMessage, typedMessage)}

                    {/*{AnswerForm({question})}*/}
                    {/*insert chat here*/}

                </div>
                <div className="w-30 pl-22 mt-res">
                    {fileAttachments(question?.files, 'Question')}
                    {fileAttachments(answer?.files, 'Answer')}

                    {/*TODO: Make a "more questions like this" feature*/}
                    {/*<div className="mt-35 h-390px overflow-y pr-16">*/}
                    {/*    <div className="d-flex justify-content-between w-full">*/}
                    {/*        <p className="fs24 mb-0">Answer Attachments</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mt-27 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-10 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}


const sendMessage = (socket, answer, setMessage, message) => {

    let roomID = answer.AID.toString();
    let referenceID = answer.AID.toString();
    sendMessageToSocket(roomID, message, referenceID, 'answer')
    setMessage('');
}


const fileCards = (attachments) => {
    return attachments.map(attachment => {
        return(
            <div className="mt-27 bg-white br-24 py-14 px-10">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-dark2">
                        <p className="fs20 mb-0 f-w-b">Attachment</p>
                        {/*<p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    </div>
                    <button
                        className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right"
                             width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1={17} y1={7} x2={7} y2={17}/>
                            <polyline points="8 7 17 7 17 16"/>
                        </svg>
                    </button>
                </div>
                <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src={attachment.picURL}/>
            </div>
        );
    });
}

const fileAttachments = (attachments, attachmentSectionTitle) => {

    if (attachmentSectionTitle !== "Answer" && attachmentSectionTitle !== "Question"){
        return null;
    }

    if (!attachments){
        return(null);
    }

    if (attachments.length > 0){
        return(
            <div className="h-390px overflow-y pr-16">
                <div className="d-flex justify-content-between w-full">
                    <p className="fs24 mb-0">{attachmentSectionTitle}</p>
                    <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx={5} cy={12} r={1} />
                            <circle cx={12} cy={12} r={1} />
                            <circle cx={19} cy={12} r={1} />
                        </svg>
                    </button>
                </div>
                <div>
                    {fileCards(attachments)}
                </div>
            </div>
        );
    }
}

const chatSection = (conversation, messagesEndRef, socket, answer, setTypedMessage, typedMessage) => {

        const messages = conversation?.map(message => {
            return (
                <div className="bg-white br-24 px-30 py-14 shadow-2 w-full mt-20">
                    <hr/>
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-start">
                            {/*<img className="w-40px h-40px rounded-full object-cover" src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" alt="" />*/}
                            <div className="ml-12">
                                <p className="fs18 f-w-b mb-0">{!!message.sentBy ? "You" : "Them"}</p>
                                <p className="text-dark2 mb-0 mt-4 fs14">
                                    {/*TODO Add dates*/}
                                    {/*1-May 2021 <span className="ml-6">(12:00 pm)</span>*/}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {/*<div className="d-flex align-items-center">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                            {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                            {/*        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />*/}
                            {/*        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />*/}
                            {/*    </svg>*/}
                            {/*    <p className="fs14 text-dark2 mb-0 ml-6">3</p>*/}
                            {/*</div>*/}
                            {/*<div className="ml-20">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-share" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                            {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                            {/*        <circle cx={6} cy={12} r={3} />*/}
                            {/*        <circle cx={18} cy={6} r={3} />*/}
                            {/*        <circle cx={18} cy={18} r={3} />*/}
                            {/*        <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />*/}
                            {/*        <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <p className="mt-20 text-dark2"> {message.message.text}</p>
                </div>
            );
        });

        const determineMessageRendering = () => {
            if (conversation?.length > 0){
                return messages;
            }else{
                return (<h5>Looks like no one has sent a message yet! 🙈 Don't be shy!</h5>);
            }
        }

        const handleSendMessage = () => {
            sendMessage(socket, answer, setTypedMessage, typedMessage)
        }

        const handleChangeTypedMessage = (event) => {
            setTypedMessage(event.target.value);
        }

        const keyDownSendMessage = (event) => {
            if (event.key === 'Enter') {
                sendMessage(socket, answer, setTypedMessage, typedMessage)
            }
        }

        return(
            <div className="mt-20 ">

                <div className="h-390px pr-16 overflow-y mt-20">

                    {determineMessageRendering()}
                    <div ref={messagesEndRef} />

                </div>

                <div className="d-flex align-items-center">
                    <div className="h-49px b-purple2 w-full br-12 pr-3 pl-3 pt-3 d-flex align-items-center overflow-y">
                        {/*<div className="position-relative">*/}
                        {/*    <input type="file" className="opacity-0 w-40px h-40px rounded-full z-20 position-relative" />*/}
                        {/*    <button className="position-absolute w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-paperclip text-white" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                        {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                        {/*            <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />*/}
                        {/*        </svg>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <textarea placeholder="Type your message here..." className="overflow-y bg-transparent ml-6 h-40px pl-3 border-0 p-g w-full  resize-none" value={typedMessage} onChange={handleChangeTypedMessage} onKeyPress={keyDownSendMessage}></textarea>
                        <button onClick={handleSendMessage} className="w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-telegram" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        );
}



