import React, {Component, useEffect, useState} from "react";
import axios from "axios";


const emailStates = {
    EMAILVERIFIED: "email verified",
    EMAILPROCESSING: "email processing",
    EMAILNOTVERIFIED: "email not verified"
};

const VerifyEmailComponent = ({token, UID}) => {

    const [verifiedOrNot, setVerifiedOrNot] = useState(emailStates.EMAILPROCESSING);

    useEffect(() => {
        let params = {
            token: token,
            UID: UID
        }
        axios.post('/api/verifyemail', params).then(response => {
            setVerifiedOrNot(emailStates.EMAILVERIFIED);
        }).catch(err => {
            setVerifiedOrNot(emailStates.EMAILNOTVERIFIED)
        });
    }, [token, UID]);


    if (verifiedOrNot === emailStates.EMAILPROCESSING){
        return (
            <>
                <div className={"mx-auto container"} style={{marginTop: "50px"}}>
                <h5>Verifying email, hold tight</h5>
                </div>
            </>
        );
    }else if(verifiedOrNot === emailStates.EMAILNOTVERIFIED) {
        return (
            <>
                <div className={"mx-auto container"} style={{marginTop: "50px"}}>
                <h5>For some reason, we couldn't verify your email</h5>
                </div>
            </>
        );
    }else if(verifiedOrNot === emailStates.EMAILVERIFIED){
        return(
            <>
                <div className={"mx-auto container"} style={{marginTop: "50px"}}>
                    <h5>Email verified, you're good to go!</h5>
                </div>

            </>
        );
    }
};

export default VerifyEmailComponent
