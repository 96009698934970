import React from "react";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import Cards from "./data.json";
import AskedFinalizedQuestionCard from "../common/asked-finalized-questioncard-with-pic";

const PrivateQuestions = ({questions}) => {

    const enableButton = (questionState) => {
        if (!questionState){
            return false;
        }
        return questionState === "paidFor" || questionState === "answered" || questionState === "finalized";
    };


    const decideComponents = (arr) => {
        if (!!arr && arr.length > 0){
            return (
                <>
                    <div className="row position-relative desktop-carousel">
                        <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={Cards.length} isIntrinsicHeight={true}>
                            <Slider>
                                {carouselSegments(convertArrIntoSegmentsOf4(arr), enableButton)}
                            </Slider>
                            <div className="position-absolute inset-0 m-auto arrow-left-btn d-flex align-items-center justify-content-center">
                                <ButtonBack>
                                    <img src="/Assets/arrow-left.png" alt="" />
                                </ButtonBack>
                            </div>
                            <div className="position-absolute inset-0 m-auto arrow-right-btn d-flex align-items-center justify-content-center">
                                <ButtonNext>
                                    <img src="/Assets/arrow-right.png" alt="" />
                                </ButtonNext>
                            </div>
                        </CarouselProvider>
                    </div>
                    <div className="row position-relative mobile-carousel">
                        <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={arr.length} isIntrinsicHeight={true}>
                            <Slider>
                                {/*{carouselSegments(convertArrIntoSegmentsOf4(arr), enableButton)}*/}
                                {carouselSlides(arr, enableButton)}
                            </Slider>
                            <div className="position-absolute inset-0 m-auto arrow-left-btn d-flex align-items-center justify-content-center">
                                <ButtonBack>
                                    <img src="/Assets/arrow-left.png" alt="" />
                                </ButtonBack>
                            </div>
                            <div className="position-absolute inset-0 m-auto arrow-right-btn d-flex align-items-center justify-content-center">
                                <ButtonNext>
                                    <img src="/Assets/arrow-right.png" alt="" />
                                </ButtonNext>
                            </div>
                        </CarouselProvider>
                    </div>
                </>
            );
        }else{
            return(
                <h5>Looks like you haven't asked any private questions 😵‍💫😵‍💫</h5>
            );
        }
    }

    return (
        <>
            <div className="mb-50">
                <h1 className="fs-25 f-w-b mb-25 n-props">Private Q's</h1>
                {decideComponents(questions)}

            </div>

        </>
    );
};




const carouselSlides = (questionList, enableButton) => {
    return questionList.map((question, i) => {
        let slideIndex = Math.floor(i / 4);
        let individualDivIndex = i % 4;

        return (
            <Slide index={slideIndex}>
                <div className="d-flex align-items-center justify-content-between">
                    <div key={individualDivIndex} className="col-lg-3 p-25">
                        <AskedFinalizedQuestionCard disabled={enableButton(question.state)} cardData={question}/>
                    </div>
                </div>

            </Slide>
        );
    });
};

const carouselSegments = (elements, enableButton) => {

    return elements.map((elementrow, i) => {
        return (
            <Slide index={i}>
                <div className="d-flex align-items-center">
                    {elementrow.map((element, ind) => {
                        return (
                            <div key={ind} className="col-lg-3 p-25">
                                <AskedFinalizedQuestionCard disabled={enableButton(element.state)} cardData={element}/>
                            </div>
                        );
                    })}
                </div>
            </Slide>
        );
    });
};


const convertArrIntoSegmentsOf4 = (arr) => {
    // Because of how the react cards were implemented, we have to split the data into 2d array of 4-element arrays
    let segmentOf4 = 4;
    let arrOfArr = [];
    let currArr = [];

    for (let i = 0 ; i < arr.length ; i++){
        let index = i % segmentOf4;
        currArr.push(arr[i]);

        if (index === 3){
            arrOfArr.push(currArr);
            currArr = [];
        }
    }
    if (currArr.length > 0){
        arrOfArr.push(currArr);
    }
    return arrOfArr;
}

export default PrivateQuestions;
