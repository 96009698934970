import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import GeneralModal from "../common/GeneralModal";



const CounterofferModal = ({ setRelationshipWQuestion, QID, counteroffer, setCounteroffer, showCounterofferModal, handleCloseCounterofferModal, setToggle}) => {

    const {accountState} = useContext(AccountStateContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        // alert("Submitted")
        const state = "countered";
        submitCounter(state, QID, accountState.userID)

    }
    const handleChangeCounter = (event) => {
        setCounteroffer(event.target.value);
    }


    const submitCounteroffer = (event) => {
        event.preventDefault();
        let state = "countered"
        submitCounter(state, QID, accountState.userID);
    };

    const submitCounter = (state, QID, UID) => {

        let counterofferData = {
            counteroffer: counteroffer,
            QID: QID,
            UID: UID,
            state: state
        }

        axios.post('/api/counterofferquestion', counterofferData ).then(response => {
            // we do this in order to give the backend time to create the data structure that will hold the counter ID
            setTimeout(function(){
                setToggle(prevState => !prevState);
                handleCloseCounterofferModal();
                setRelationshipWQuestion("countered");
                window.location.reload(false);
            }, 3000);

        }).catch(err => {
            if (!!err?.response?.data && err?.response.data === "It looks like you can't yet receive payments from boost, try checking your stripe dashboard."){
                setErrorMessage(err.response.data);
                handleCloseCounterofferModal()
                handleShowErrModal();
            }
        });
    }

    // For error modal
    const [showErrModal, setShowErrModal] = useState(false);
    const handleCloseErrModal = () => setShowErrModal(false);
    const handleShowErrModal = () => setShowErrModal(true);
    const [errorMessage, setErrorMessage] = useState("");


    return(
        <>
            <GeneralModal
                functionToExecuteUponSubmission={handleCloseErrModal}
                showModal={showErrModal}
                handleCloseModal={handleCloseErrModal}
                titleMessage={"Stripe Error"}
                bodyMessage={errorMessage}
                denyButtonMessage={"Close"}
                acceptButtonMessage={"Accept"}
            />
            <Modal
                show={showCounterofferModal}
                onHide={handleCloseCounterofferModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Counteroffer: ${counteroffer}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} id={"submit-counteroffer-form"} >
                        <Form.Label>How much will you answer this for? In USD</Form.Label>
                        <Form.Row >
                            <Form.Group controlId="signinformID">
                                {/*<Form.Label>How much are you offerring? remember that BookStack only takes a percentage to process credit/debit information. Maybe we can include a part that says how much they would get on the other side</Form.Label>*/}
                                <Form.Control autoComplete={"off"} required type="number" name="counteroffer" placeholder="Counteroffer" step={0.01} precision={2} value={counteroffer} onChange={handleChangeCounter}/>

                            </Form.Group>

                        </Form.Row>

                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="I agree to terms of service"  name="counterAgreetoTermsofService" required />
                        </Form.Group>

                        <Form.Text className="text-muted">
                            Remember, you'll be able to send a message after this
                        </Form.Text>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCounterofferModal}>
                        Close
                    </Button>
                    <button className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14" type={"submit"} form={"submit-counteroffer-form"} >Submit</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CounterofferModal;