import {Link} from "react-router-dom";
import queryString from "query-string";

const QuestionCard = ({ cardData, disabled }) => {

    const queryObject = {
        QID: cardData.QID
    }
    const query = queryString.stringify(queryObject);
    const manageQuestionURL = `/view/question?${query}`
    const questionImageURL = cardData.questionMainPic ? cardData.questionMainPic : "https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80"

    return (
        <div className="card-with-pic position-relative">
            {/*{disabled && <div className="b-overlay position-absolute inset-0" />}*/}
            <Link to={manageQuestionURL}><img className="w-full img" alt="" src={questionImageURL} /></Link>
            <div className="px-15px">
                <Link to={manageQuestionURL}><h2 className="mb-0 n-props clr-grey fs12 f-w-s mt-20">{cardData.questionTitle}</h2></Link>
                <div className="d-flex justify-content-between align-items-center mt-30">
                    <div className="d-flex align-items-center">
                        {/*<img className="mr-10 user" src="/Assets/user.png" alt="" />*/}
                        {/*<p className="mb-0  n-props clr-grey fs12 font-weight-semibold">{cardData.authorName}</p>*/}
                    </div>
                    <p className="n-props fs-20 f-w-b mb-0" style={{ color: `#bf5dab` }}>
                        {/*${cardData.offeringPrice}*/}
                    </p>
                </div>
                {disabled && (
                    <div className="mt-10 d-flex align-items-center justify-content-between">
                        <p className="fs12 text-gray2 mb-0">awaiting Payment...</p>
                        <button onClick={() => {alert("Paid")}} className="border-0 br-7 bg-green-light text-white py-8 px-12 fs12 f-w-s">Pay Now</button>
                    </div>
                )}
            </div>
        </div>
    );
};
export default QuestionCard;
