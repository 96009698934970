import React, { useState } from "react";
import "./scss/main.css";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {AccountStateProvider} from "./Context";



import Education from "./pages/education";
import Profile from "./pages/my-profile";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Students from "./pages/students";
import Classes from "./pages/classes";
import SearchQuestions from "./pages/search-questions";
import Questions from "./pages/questions";
import ClassDetailalt from "./pages/class-detail-alt";
import ClassDetail from "./pages/class-detail";
import ManageQuestionDetail from "./pages/manage-question-detail";
import ManageCounterDetailsPage from "./pages/manage-counter-details";
import QuestionDetail from "./pages/question-detail";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import NotMyProfile from "./pages/not-my-profile";
import navigation from "./pages/navigation";
import Success from "./pages/navigation";
import Canceled from "./pages/education";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import AnswerQuestionPage from "./pages/AnswerQuestionPage";
import AnswerPage from "./pages/AnswerofQuestionPage";
import AnswerToMyQuestionPage from "./pages/AnswerofMyQuestionPage";
import BrowseQuestions from "./pages/BrowseQuestions";
import Settings from "./pages/Settings";
import HowItWorks from "./pages/HowItWorks";
import ErrorPage from "./pages/ErrorPage";
import RequestPWReset from "./pages/RequestPWReset";
import ResetPW from "./pages/ResetPW";
import VerifyEmail from "./pages/VerifyEmail";
import PickSubPage from "./pages/PickSubPage";
import AdminPage from "./pages/AdminPage";
import AdminListPublicQs from "./pages/AdminListPublicQs";
import AnswerPublicQ from "./pages/AnswerPublicQ";
import AdminListPrivateQs from "./pages/AdminListPrivateQs";
import AnswerPrivateQ from "./pages/AnswerPrivateQ";
import SignedInHome from "./pages/SignedInHome";
import FAQ from "./pages/FAQ";
import AboutUs from "./pages/AboutUs";

import GAnalyticsRouterChange from "./GAnalyticsRouterChange";

export default function App() {
    const [sidebar, setSidebar] = useState(false);

    return (
        <AccountStateProvider>
        <Router>
            <GAnalyticsRouterChange />
            <Switch>
                <Route path="/" component={Education} exact />
                <Route path="/signup" component={Signup} exact />
                <Route path="/signin" component={Signin} exact />
                <Route path="/howitworks" component={HowItWorks} exact />
                <Route path = "/terms" component={Terms} />
                <Route path = "/privacy" component={Privacy} />
                <Route path = "/requestreset" component={RequestPWReset} />
                <Route path = "/reset" component={ResetPW} />
                <Route path = "/verifyemail" component={VerifyEmail} />
                <Route path = "/FAQ" component={FAQ} />
                <Route path = "/aboutus" component={AboutUs} />

                <div className="wrapper d-flex flex-nowrap align-items-start position-relative">
                    <div className="flex-shrink-0">
                        <Sidebar sidebar={sidebar} />
                    </div>
                    <main className="position-relative z-20">
                        <Header sidebar={sidebar} setSidebar={setSidebar} />
                        <div className="custom-container">
                            <Route path="/navigation" component={navigation} />
                            <Route path="/profile" component={Profile} exact />
                            <Route path="/students" component={Students} exact />
                            <Route path="/classes" component={Classes} exact />
                            {/*<Route path="/questions-alt" component={QuestionsAlt} exact />*/}
                            <Route path="/questions" component={Questions} exact />
                            <Route path="/class" component={ClassDetailalt} exact />
                            <Route path="/class-detail" component={ClassDetail} exact />

                            <Route path="/manage/my/question/:QID" component={ManageQuestionDetail} exact />
                            <Route path="/manage/my/question/:QID/counter/:COID" component={ManageCounterDetailsPage} exact />
                            <Route path="/view/question" component={QuestionDetail} exact />
                            <Route path="/search" component={SearchQuestions} exact/>
                            <Route path="/user" component={NotMyProfile} exact/>
                            <Route path = "/success" component={Success} exact />

                            <Route path="/answerquestion" component={AnswerQuestionPage} exact/>
                            <Route path="/view/answer" component={AnswerPage} exact/>
                            <Route path="/my/privatequestion" component={AnswerToMyQuestionPage} exact/>
                            <Route path={"/browse/questions"} component={BrowseQuestions} exact/>
                            <Route path={"/settings"} component={Settings}  exact/>
                            <Route path={"/picksubscription"} component={PickSubPage}  exact/>
                            <Route path={"/admin6"} component={AdminPage}  exact/>
                            <Route path={"/admin6/answer/public"} component={AdminListPublicQs}  exact/>
                            <Route path={"/admin6/answer/public/answerquestion"}  component={AnswerPublicQ}   exact/>
                            <Route path={"/admin6/answer/private"} component={AdminListPrivateQs}  exact/>
                            <Route path={"/admin6/answer/private/answerquestion"}  component={AnswerPrivateQ}   exact/>
                            <Route path={"/imhome"}  component={SignedInHome}  exact/>






                            {/*<Redirect to="/navigation" />*/}
                        </div>
                    </main>
                </div>

                <Route component={ErrorPage}/>
            </Switch>
        </Router>
        </AccountStateProvider>
    );
}
