import React from "react";
import Modal from "react-bootstrap/Modal";


const AttachmentModal = ({show, handleClose, imageURL}) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className="img-fluid" src={imageURL} alt=""/>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className="btn-hero text-white purple-bg border-0 fs17">Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default AttachmentModal
