import React, {useContext, useEffect, useState} from "react";
import ManageCounterDetailsComponent from "../components/manage-counter-details/manage-counter-details";
import axios from "axios";
import {AccountStateContext} from "../Context";
import {useParams} from 'react-router';
import {Redirect} from "react-router-dom";
import {initiateSocket, sendMessageToSocket, subscribeToChat, disconnectSocket} from "../Socket";

export default function ManageCounterDetailsPage() {

    const {accountState} = useContext(AccountStateContext);
    const {QID} = useParams();
    const {COID} = useParams();

    //The question that is going to be managed
    const [question, setQuestion] = useState({
        QID: '',
        state: '',
        files:[{}],
        questionTitle: '',
        questionDescription: '',
        dateNeededBy: '',
        remainAnonymousBool: true,
        offeringPrice: 0,
        wantQuestionPublicBool: false,
        courseID: '',
        counterPrices: []
    });
    const [counterofferManaging, setCounterofferManaging] = useState({
        state: '',
        COID: ''

    });
    const [conversation, setConversation] = useState([]);


    useEffect(() => {
        let params = {
            UID: accountState.userID,
            COID: COID,
            QID: QID
        }

        axios.get('/api/determine/myquestion/counterstate', {params: params}).then(response => {

        }).catch(err => {
           console.error(err.message);
        });
    },[accountState.userID, COID, QID]);


    useEffect(() => {
        const params = {
            QID: QID,
            UID: accountState.userID,
            COID: COID
        }
        axios.get('/api/dashboard/counteroffer/tomyquestion', {params: params}).then(response => {
            setQuestion(response.data.question);

            setCounterofferManaging(response.data.counterofferDetails);
            // socket.emit('joinSocketRoom', {
            //     type: 'question',
            //     referenceID: response.data.question.QID,
            //     roomID: response.data.question.QID.toString() + COID.toString()
            // });
        }).catch(err => {
            console.error(err.message)
        });
    }, [COID, QID, accountState.userID]);


    useEffect(() => {

        if (!!QID && !!COID && !!counterofferManaging){
            const roomID = QID.toString() + COID.toString();
            initiateSocket(roomID, 'question', QID);
        }

        subscribeToChat((err, response) => {
            if(err) return;
            let responseCopy = response;
            if (!!response?.messageObj?.sentBy && response.messageObj.sentBy.toString() !== accountState.userID.toString()){
                responseCopy.messageObj.sentBy = undefined;
            }
            setConversation(currConversation => [...currConversation, responseCopy?.messageObj])
        });

        return () => {
            disconnectSocket();
        }
    }, [QID, COID, counterofferManaging]);

    useEffect(() => {

        if (!!question && !!counterofferManaging){
            const params = {
                UID: accountState.userID,
                type: 'question',
                referenceID: question.QID,
                roomID: question.QID.toString() + counterofferManaging.COID.toString()
            }
            axios.get('/api/fetchmessages', {params: params}).then(response => {
                setConversation(response.data.messages);
            }).catch(err => {
               console.error(err.message);
            });
        }
    },[accountState.userID, question, counterofferManaging]);


    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }


    return <ManageCounterDetailsComponent
                conversation={conversation}
                counterofferToMyQuestion={counterofferManaging}
                question={question}
            />;
}
