import React, {useContext} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {useParams} from 'react-router';
import {AccountStateContext} from "../../Context";



const AcceptCounterofferMyQuestionModal = ({showAcceptModal, handleCloseAcceptModal, counterofferDetails}) => {

    const {QID} = useParams();
    const {accountState} = useContext(AccountStateContext);

    const handleAcceptCounteroffer = (event) => {
        event.preventDefault();
        submitAcceptCounteroffer(QID, accountState.userID, counterofferDetails, handleCloseAcceptModal)

    };

    return(
        <Modal
            show={showAcceptModal}
            onHide={handleCloseAcceptModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Accept counteroffer: ${counterofferDetails.counterPrice}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAcceptCounteroffer} id={"submit-accept-counter-form"} >
                    <Form.Label>Ready to make some moves?🔌 🤑 ⚡️</Form.Label>

                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I agree to terms of service"  name="counterAgreetoTermsofService" required />
                    </Form.Group>

                    <Form.Text className="text-muted">
                        FYI this is final and irreversible :/
                    </Form.Text>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAcceptModal}>
                    Close
                </Button>
                <Button type={"submit"} form={"submit-accept-counter-form"} variant="primary">Submit</Button>
            </Modal.Footer>
        </Modal>
    );

};


const submitAcceptCounteroffer = (QID, UID, counterofferDetails, handleCloseAcceptModal) => {

    const postInfo = {
        QID: QID,
        UID: UID,
        iAcceptThisUID: counterofferDetails.countererID.toString()
    }
    axios.post('/api/acceptcounterofferformyquestion', postInfo).then(response => {
        handleCloseAcceptModal();
        alert("Successfully accepted offer");
    }).catch(err => {
        console.error(err.message);
    });
};


export default AcceptCounterofferMyQuestionModal
