import React, {useContext, useRef, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import {Redirect, useHistory} from "react-router-dom";
import queryString from "query-string";


export default function AnswerQuestionComponent({counterofferDetails, socket, conversation,  setToggle, relationship, setRelationshipWQuestion, question, counteroffer, setCounteroffer}) {

    const [typedMessage, setTypedMessage] = useState('');
    const messagesEndRef = useRef(null);


    const scrollToBottomOfMessages = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottomOfMessages();
    }, [conversation]);


    return (
        <div className="container mx-auto class-detail py-30">
            <div className="d-xl-flex">
                <div className="w-70">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="fs20 mb-0">
                                Cost: <span className="f-w-b">${question?.offeringPrice}</span>
                            </p>
                            <p className="mb-0 fs15 ml-24">Date: 12/04/2021</p>
                        </div>
                        <div className="d-flex align-items-center mt-res2">

                            {/*<ButtonManager counterofferDetails={counterofferDetails} setRelationshipWQuestion={setRelationshipWQuestion} setToggle={setToggle} relationship={relationship} setCounteroffer={setCounteroffer} question={question} counteroffer={counteroffer}/>*/}
                        </div>
                    </div>
                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">
                        <p className="mb-0 fs25 f-w-b">{question?.questionTitle}</p>
                        <p className="mb-0 fs12">
                            {question?.questionDescription}
                            {displayCounterDetails(counterofferDetails, question?.state)}
                        </p>
                    </div>

                    {chatSection(relationship, conversation, messagesEndRef, socket, question, setTypedMessage, typedMessage, counterofferDetails)}

                    {AnswerForm({question})}
                    {/*insert chat here*/}

                </div>
                <div className="w-30 pl-22 mt-res">
                    {fileAttachments(question?.files)}
                    {/*TODO: Make a "more questions like this" feature*/}
                    {/*<div className="mt-35 h-390px overflow-y pr-16">*/}
                    {/*    <div className="d-flex justify-content-between w-full">*/}
                    {/*        <p className="fs24 mb-0">Answer Attachments</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mt-27 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-10 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}



const displayCounterDetails = (counterofferIveReceivedBack, questionState) => {
    if (!!counterofferIveReceivedBack && !!questionState){
        const state = determineStateMessage(counterofferIveReceivedBack.state, questionState);
        return (
            <>
                <hr/>
                <br/>
                State: {state}
                <br/>
                Your latest counteroffer: ${counterofferIveReceivedBack.counterPrice}
                <br/>
                Their latest counteroffer: ${counterofferIveReceivedBack.counterPriceFromAsker}
                <br/>
            </>
        );
    }else{
        return null;
    }
};

const determineStateMessage = (counterState, questionState) => {

    if (counterState.toString() === "countered"){
        return "Awaiting their response";
    }else if (counterState.toString() === "counteredByAsker"){
        return "Awaiting your response";
    }else if(counterState.toString() === "accepted" && questionState.toString() === "countered") {
        return "You accepted their last counteroffer, awaiting on their response";
    }else {
        return "Processing ..."
    }
}

const sendMessage = (socket, question, currentCounterofferDetails, setMessage, message) => {

    socket.emit('clientToServerMessage', {
        type: 'question',
        referenceID: question.QID,
        message: message,
        roomID: question.QID.toString() + currentCounterofferDetails.COID.toString()
    });
    setMessage('');

}


const fileCards = (attachments) => {
    return attachments.map(attachment => {
        return(
            <div className="mt-27 bg-white br-24 py-14 px-10">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-dark2">
                        <p className="fs20 mb-0 f-w-b">Attachment</p>
                        {/*<p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    </div>
                    <button
                        className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right"
                             width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1={17} y1={7} x2={7} y2={17}/>
                            <polyline points="8 7 17 7 17 16"/>
                        </svg>
                    </button>
                </div>
                <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src={attachment.picURL}/>
            </div>
        );
    });
}

const fileAttachments = (attachments) => {

    if (!attachments){
        return(null);
    }

    if (attachments.length > 0){
        return(
            <div className="h-390px overflow-y pr-16">
                <div className="d-flex justify-content-between w-full">
                    <p className="fs24 mb-0">Attachments</p>
                    <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx={5} cy={12} r={1} />
                            <circle cx={12} cy={12} r={1} />
                            <circle cx={19} cy={12} r={1} />
                        </svg>
                    </button>
                </div>
                <div>
                    {fileCards(attachments)}
                </div>
            </div>
        );
    }
}

const chatSection = (relationshipStatus, conversation, messagesEndRef, socket, question, setTypedMessage, typedMessage, counterofferDetails) => {
    if (relationshipStatus === "countered" || relationshipStatus === "counteredByAsker" || relationshipStatus === "accepted"){



        const messages = conversation.map(message => {
            return (
                <div className="bg-white br-24 px-30 py-14 shadow-2 w-full mt-20">
                    <hr/>
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-start">
                            {/*<img className="w-40px h-40px rounded-full object-cover" src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" alt="" />*/}
                            <div className="ml-12">
                                <p className="fs18 f-w-b mb-0">{!!message.sentBy ? "You" : "Them"}</p>
                                <p className="text-dark2 mb-0 mt-4 fs14">
                                    {/*TODO add dates to message*/}
                                    {/*1-May 2021 <span className="ml-6">(12:00 pm)</span>*/}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            {/*<div className="d-flex align-items-center">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                            {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                            {/*        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />*/}
                            {/*        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />*/}
                            {/*    </svg>*/}
                            {/*    <p className="fs14 text-dark2 mb-0 ml-6">3</p>*/}
                            {/*</div>*/}
                            {/*<div className="ml-20">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-share" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                            {/*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                            {/*        <circle cx={6} cy={12} r={3} />*/}
                            {/*        <circle cx={18} cy={6} r={3} />*/}
                            {/*        <circle cx={18} cy={18} r={3} />*/}
                            {/*        <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />*/}
                            {/*        <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />*/}
                            {/*    </svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <p className="mt-20 text-dark2"> {message.message.text}</p>
                </div>
            );
        });

        const determineMessageRendering = () => {
            if (conversation.length > 0){
                return messages;
            }else{
                return (<h5>Looks like no one has sent a message yet! 🙈 Don't be shy!</h5>);
            }
        }

        const handleSendMessage = () => {
            sendMessage(socket, question, counterofferDetails, setTypedMessage, typedMessage)
        }

        const handleChangeTypedMessage = (event) => {
            setTypedMessage(event.target.value);
        }

        const keyDownSendMessage = (event) => {
            if (event.key === 'Enter') {
                sendMessage(socket, question, counterofferDetails, setTypedMessage, typedMessage)
            }
        }

        return(
            <div className="mt-20 ">

                <div className="h-390px pr-16 overflow-y mt-20">

                    {determineMessageRendering()}
                    <div ref={messagesEndRef} />

                </div>

                <div className="d-flex align-items-center">
                    <div className="h-49px b-purple2 w-full br-12 pr-3 pl-3 pt-3 d-flex align-items-center overflow-y">
                        {/*<div className="position-relative">*/}
                        {/*    <input type="file" className="opacity-0 w-40px h-40px rounded-full z-20 position-relative" />*/}
                        {/*    <button className="position-absolute w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-paperclip text-white" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                        {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                        {/*            <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />*/}
                        {/*        </svg>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <textarea placeholder="Type your message here..." className="overflow-y bg-transparent ml-6 h-40px pl-3 border-0 p-g w-full  resize-none" value={typedMessage} onChange={handleChangeTypedMessage} onKeyPress={keyDownSendMessage}></textarea>
                        <button onClick={handleSendMessage} className="w-40px h-40px inset-0 br-12 purple-bg d-flex align-items-center justify-content-center text-white border-0 z-0 b-purple ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-telegram" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}



const AnswerForm = ({question}) => {

    const {accountState} = useContext(AccountStateContext);
    const [textAnswer, setTextAnswer] = useState();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const history = useHistory();
    const handleChangeTextAnswer = (event) => {
        setTextAnswer(event.target.value);
    };

    const handleSubmitAnswer = (event) => {
        event.preventDefault();
        answerQuestion(filesToUpload, textAnswer, question, accountState.userID, history)
    };

    const handleFileChange = (event) => {
        setFilesToUpload(event.target.files);
    };


    return(
        <>
            <Form onSubmit={handleSubmitAnswer}>
                <hr/>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Answer</Form.Label>
                    <Form.Control onChange={handleChangeTextAnswer} value={textAnswer} as={"textarea"}  placeholder="Enter text answer here. You'll still be able to send more messages after submitting answer" />

                </Form.Group>
                <br/>
                <Form.Group>
                    <Form.Label>Additional attachments</Form.Label>
                    <Form.File name={"files"} multiple onChange={handleFileChange}  label="" />
                    <Form.Text className="text-muted">
                        We currently only accept images
                    </Form.Text>
                </Form.Group>

                <br/>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check required type="checkbox" label="I have answered this question to the best of my ability and have fulfilled the conditions to receive my payment.  " />
                </Form.Group>
                <br/>


                <button className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14" type="submit">
                    Answer
                </button>
            </Form>
        </>
    );

};



const answerQuestion = (filesToUpload, textAnswer, question, UID, history) => {
    const formData = new FormData();

    // Add files to form data
    for (let i = 0 ; i < filesToUpload.length ; i++){
        formData.append(`files`, filesToUpload[i]);
    }

    formData.append('textAnswer', textAnswer);
    formData.append('QID', question.QID)
    formData.append('UID', UID)


    axios.post('/api/answerthisquestion', formData, {headers: {"Content-type": "multipart/form-data"}}).then(response => {

        let params = {
            QID: question.QID
        }
        let answerQuery = queryString.stringify(params);
        let answerURL = `/view/answer?${answerQuery}`

        history.push(answerURL)
    }).catch(err => {
        console.log(err);
    });
};


