import React, {useContext, useEffect, useState} from "react";
import ManageQuestionDetailComponent from "../components/manage-question-detail/manage-question-details";
import axios from "axios";
import {AccountStateContext} from "../Context";
import {useParams} from 'react-router';
import {Redirect} from "react-router-dom";
import Meta from "../components/Meta";

export default function ManageQuestionDetailPage() {

    const {accountState} = useContext(AccountStateContext);
    const {QID} = useParams();

    //The question that is going to be managed
    const [question, setQuestion] = useState({
        QID: '',
        state: '',
        files:[{}],
        questionTitle: '',
        questionDescription: '',
        dateNeededBy: '',
        remainAnonymousBool: true,
        offeringPrice: 0,
        wantQuestionPublicBool: false,
        courseID: '',
        counterPrices: []
    });
    const [comments, setComments] = useState([]);


    useEffect(() => {

        const params = {
            QID: QID,
            UID: accountState.userID
        }
        axios.get('/api/my/question', {params: params}).then(response => {
            setQuestion(response.data.question);
        }).catch(err => {
            console.error(err.message)
        });

        const params2 = {
            QID: QID,
            UID: accountState.userID
        };

        axios.get('/api/question/comments', {params: params2}).then(response => {
            setComments(response.data.comments)
        }).catch(err => {
           console.error(err.message);
        });


    }, [QID, accountState.userID]);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }


    return (
        <>
            <Meta title={`My Public question: ${question?.questionTitle}`}/>
            <ManageQuestionDetailComponent question={question} comments={comments} />;
        </>
    );
}
