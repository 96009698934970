import React, {Component, useEffect} from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import {Link} from "react-router-dom";

const AdminPage = () => {
    useEffect(() => {

    },[]);


    const adminOptions = [
        {
            key: 'public',
            url: '/admin6/answer/public'
        },
        {
            key: 'private',
            url: '/admin6/answer/private'
        }
        ];


    return (
        <>
            <Row xs={1} md={2} className="g-4">
                {adminOptions.map((val, idx) => (
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px160" />*/}
                            <Card.Body>
                                <Card.Title>{val.key}</Card.Title>
                                <Card.Text>
                                    This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.
                                </Card.Text>
                                <Link to={val.url}><Button>Button </Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};






export default AdminPage
