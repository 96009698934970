import React, {useContext, useEffect, useState} from "react";
import AnswerQuestionComponent from "../components/AnswerQuestionPage/AnswerQuestionComponent";
import axios from "axios";
import queryString from "query-string";
import {Redirect, useLocation} from 'react-router-dom'
import {AccountStateContext} from "../Context";


const AnswerQuestionPage = () => {

    const searchQ = useLocation().search;
    const {QID} = queryString.parse(searchQ);
    const [question, setQuestion] = useState();
    const {accountState} = useContext(AccountStateContext);

    useEffect(() => {
        let params = {
            QID: QID
        };
        if (accountState.isSignedIn){
            params["UID"] = accountState.userID
        }
        axios.get('/api/question', {params: params}).then(response => {
            setQuestion(response.data.question);
        }).catch(err => {
            console.error(err.message)
        });
    }, [accountState.isSignedIn, QID, accountState.userID])


    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return(
        <>
            <AnswerQuestionComponent question={question}/>
        </>
    );

};

export default AnswerQuestionPage
