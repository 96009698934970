import React, {useContext, useEffect, useState} from "react";
import AnswerOfQuestionAnswererPerspectiveComponent from "../components/AnswerofQuestionAnswererPerspective/AnswerOfQuestionAnswererPerspectiveComponent";
import axios from "axios";
import queryString from "query-string";
import {Redirect, useLocation} from 'react-router-dom'
import {AccountStateContext} from "../Context";
import {initiateSocket, subscribeToChat, disconnectSocket} from "../Socket";


const AnswerPage = () => {

    const searchQ = useLocation().search;
    const {QID} = queryString.parse(searchQ);
    const [question, setQuestion] = useState();
    const {accountState} = useContext(AccountStateContext);
    const [conversation, setConversation] = useState([]);
    const [answer, setAnswer] = useState();
    // const

    useEffect(() => {
        let params = {
            QID: QID
        };
        if (accountState.isSignedIn){
            params["UID"] = accountState.userID
        }
        axios.get('/api/question', {params: params}).then(response => {
            setQuestion(response.data.question);
        }).catch(err => {
            console.error(err.message)
        });
    }, [accountState.isSignedIn, QID, accountState.userID])



    useEffect(() => {
        if (answer?.AID){
            axios.get('/api/fetchmessages', {params: {UID: accountState.userID, type: 'answer', referenceID: answer.AID}}).then(response => {
                response.data.messages.forEach(message => {
                    setConversation(currConversation => [...currConversation, message]);
                })

            }).catch(err => {
                console.error(err.message);
            });
        }

    },[answer, accountState.userID]);





    useEffect(() => {

        axios.get('/api/my/finalanswer', {
            params:{
                UID: accountState.userID,
                QID: QID,
                method: "answeredBy"
            }
        }).then(response => {

            setAnswer(response.data.answer);
            let roomID = response.data.answer.AID.toString();
            let referenceID = response.data.answer.AID.toString();
            initiateSocket(roomID, 'answer', referenceID);

            subscribeToChat((err, response) => {
                if(err) return;
                let responseCopy = response;
                if (!!response?.messageObj?.sentBy && response.messageObj.sentBy.toString() !== accountState.userID.toString()){
                    responseCopy.messageObj.sentBy = undefined;
                }
                setConversation(currConversation => [...currConversation, responseCopy?.messageObj])
            });

            return () => {
                disconnectSocket();
            }


        }).catch(err => {
            console.error(err.response.data, "NOOOOOO");
        });

    }, [accountState.userID, QID]);


    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }


    return(
        <>
            <AnswerOfQuestionAnswererPerspectiveComponent conversation={conversation} answer={answer} question={question}/>
        </>
    );

};

export default AnswerPage;
