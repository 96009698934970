import Meta from "../components/Meta";
import ExternalNavbar from "../components/common/external-navbar";
import ExternalFooter from "../components/common/external-footer";
import React from "react";


const ErrorPage = () => {


    return (
        <>
            <Meta title={"Error"}/>
            <ExternalNavbar/>
            <h1>Error :/</h1>

            <ExternalFooter/>
            <ExternalFooter/>
        </>
    );
};

export default ErrorPage
