import React, {Component} from "react";
import ExternalFooter from "../components/common/external-footer";
import ExternalNavbar from "../components/common/external-navbar";
import styled from "styled-components";
import Meta from "../components/Meta";

const WrapperDiv = styled.div`
   margin-left: 15%;
   margin-right: 15%;
   margin-top: 5%;
   
`

const Terms = () => {


    return (
        <>
            <Meta title={"Terms"}/>
            <ExternalNavbar/>
            <WrapperDiv>
                <header className="App-header">
                    <h1 className="App-title">Terms of Service</h1>

                    <h6>Welcome to boost!</h6>
                    <p><strong>IMPORTANT! PLEASE CAREFULLY READ THESE TERMS OF USE, AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS.</strong>
                        These boost Terms of Use ("Terms of Use") apply to our platform that posts a link to these Terms of Use. boost, Inc. and our subsidiaries are referred to collectively in these Terms of Use as "boost," "boost Websites," "we" or "our." "You," "your" and "user" refer to any person or entity using the Services.
                        These Terms of Use govern your use of the Services, regardless of how you access them, whether by computer, mobile device, or otherwise; and whether directly through our Services, or through any third-party website that links to them ("Linked Services"), and regardless of whether you are a registered user or a guest. By using the Services, you agree to the Terms of Use. If you do not agree to the Terms of Use, you are not authorized to use the Services and you must cease all such use immediately.

                    </p>
                    <h2>1. The Boost Platform</h2>
                    <h6>
                        The boost platform provides a platform in which, among other things, persons seek help in their academic courses at UCLA. The person asking a question is referred to as "Asker", and the person who is matched up with them to provide an answer is known as the "Answerer".
                        boost aims to be a more customized experience to learning at UCLA for UCLA students.
                        boost is in no way officially affiliated with the University of California, Los Angeles (UCLA);
                    </h6>

                    <h2>2. Modification To This Agreement</h2>
                    <h6>
                        We, boost, may update this agreement from time to time as the boost Platform changes and the law requirements change as well. If we update it, we will post the revision here, online, and we will let you know through the boost Platform or another means of communication on the behalf of boost, such as email. Upon using boost, you are agreeing and consenting to the most recent terms of this agreement.

                    </h6>

                    <h2>3. Eligibility</h2>
                    <h6>
                        The boost Platform may only be used by those eligible and able to satisfy the terms, conditions, and obligations.
                        You also certify that no one may use your account on your behalf, ensuring that you are the sole navigator and servicer to your account.
                        boost has the right to terminate your account for any reason at our sole discretion without notice to you.
                        In addition, users agree to not use our platform outside of California, United States. Users must never communicate outside of the platform. Any communication or indication of communication may result in penalties.
                        Any question deemed not relevant or proper for our platform will be deleted. We reserve the right to delete questions if needed.
                    </h6>

                    <h2>4. Proprietary Rights</h2>
                    <h6> Everything posted on boost are owned and operated by boost. This includes everything in the past, present, and future, including the domain names, images, logos, content, source code, text, design, visual elements. boost content is protected from unauthorized use, copying and dissemination by copyright and other laws, rules, and regulations. Any unauthorized use of boost and the content on the platform is prohibited. Unauthorized use will result in criminal and/or civil consequences. Unauthorized use includes downloading any material and/or content off the platform.</h6>


                    <h2>5. Boost Communications</h2>
                    <h6>
                        By signing up with Boost, you agree to receive important information such as password resets, message notifications, information regarding your account, marketing emails, etc. sent to you by boost.
                    </h6>

                    <h2>6. Your information</h2>
                    <h6>
                        You agree to allow us to hold information such as a user profile for you, including inside of it things like your email, name, etc.
                        When you upload, submit, display, communicate, email something to boost platform or boost representatives, you allow boost to gain a non-exclusive, perpetual, unlimited, irrevocable, and royalty-free, fully sublicensable and transferable license to be able to copyright the information.

                    </h6>

                    <h2>7. Refunds</h2>
                    <h6>
                        No refunds sorry we're broke :/
                    </h6>

                    <h2>8. Disclaimer</h2>
                    <h6>
                        boost is not affiliated with UCLA in any way shape or form.
                    </h6>

                    <h2>9. Moral Policy and Personal Conduct</h2>
                    <h6>
                        boost has NO TOLERANCE FOR CHEATING the education system. We are a platform to encourage collaborative learning through peers that see topics from your perspective. However, we reserve the right to ban accounts and remove questions found to be violating moral and personal conduct policies set by the university in question.
                        We do not allow the posting of the following examples, but not limited to: exams, quizzes, asking for direct homework answers (help on arriving at the answer are allowed), etc.
                        We trust that you, the user, will act responsibly.

                        In addition, we do not tolerate abusive behavior towards anyone on the platform. Any discrimination or disrespectful behavior aimed at the platform, our users, professors, etc. may result in an inelligibility to use the platform.
                    </h6>

                    <p></p>
                </header>
            </WrapperDiv>

            <ExternalFooter/>
        </>
    );
};

export default Terms
