import React, {Component} from "react";
import styled from "styled-components";
import ExternalFooter from "../common/external-footer";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import FAQ from "../../pages/FAQ";
const WrapperDiv = styled.div`
   margin-left: 15%;
   margin-right: 15%;
   margin-top: 5%;
   
`

const FAQComponent = ({boostCourses}) => {

    const eligibleBoostCourses = boostCourses.map(course => {
        return `${course.courseSubjectAreaAbbrev} ${course.courseNumber}`;
    });

    const FAQboostCourses = eligibleBoostCourses.join(', ');


    return(
        <>
            <div style={{marginTop: "100px"}} className="mx-auto container">
                <h3>Our most frequently asked questions</h3>
                <Accordion defaultActiveKey="0">
                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`0`}>
                            What is a public question? (Click on me!!)
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`0`}>
                            <Card.Body>
                                A public question will appear to anyone who visits boostucla.com. This means that they don't have to sign up to see the question. However, without signing up, the answer will not be visible.
                                <br/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`1`}>
                            What is a private question?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`1`}>
                            <Card.Body>
                                A private question will not appear to anyone other than yourself and the tutor answering your question. This tutor will assist you further by offerring a more detailed explanation in order to enhance your understanding of the subject.
                                <br/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`2`}>
                            What's the difference between the basic and premium plan?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`2`}>
                            <Card.Body>
                                The difference between a basic and premium plan is that a premium plan will grant you access to further detailed explanations from our tutors and private questions for removing anxiety of asking questions publicly
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`3`}>
                            If you have a premium account, can you still access basic account features?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`3`}>
                            <Card.Body>
                                Yes, if you have a premium account, you will still be able to ask public questions and see all the things that a basic account will see.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`4`}>
                            Is boost affiliated with UCLA?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`4`}>
                            <Card.Body>
                                No, boost is in no way affiliated with UCLA
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`5`}>
                            What ucla classes are supported on boost?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`5`}>
                            <Card.Body>
                                Currently, we only offer support for UCLA courses: <br/>
                                {FAQboostCourses}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`6`}>
                            Is boost only for UCLA?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`6`}>
                            <Card.Body>
                                Yes, for now, only UCLA students can use boost. But we plan to change that in the near future!
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`7`}>
                            Who answers my questions?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`7`}>
                            <Card.Body>
                                We have a team of tutors with experience explaining topics ready to answer any relevant question. We only offer subjects in which we have credible tutors ready to help.
                                For Spanish classes, we maintain a team of tutors and native speakers to answer questions
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`8`}>
                            Are the boost tutors credible?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`8`}>
                            <Card.Body>
                                Yes, our the only tutors on the boost platform are extremely familiar with the classes and topics that they are helping with
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card style={{border: "none", paddingTop: "5px", paddingBottom: "0px"}} className={"w-100"}>
                        <Accordion.Toggle as={Card.Header} eventKey={`9`}>
                            Do I have to pay to sign up?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`9`}>
                            <Card.Body>
                                No! You don't have to pay anything to sign up with boost. With our <a href="/howitworks">free plan</a>, you can sign up and see what questions have been posted publicly on boost. However, to ask public or private questions, a subscription is required.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                {/* section 3 */}
                <img alt="" src="/Assets/Path130.svg" className="w-img position-absolute end-0 filter50 mmr-180" />


                {/*/!* section 5 *!/*/}
                {/*<div className="mt-328">*/}
                {/*    <div className=" position-relative">*/}
                {/*        <img alt="" src="/Assets/Group285.svg" className="w-img3 position-absolute z-0 top-0" />*/}
                {/*        <div className="px-40 d-md-flex flex-column flex-md-row align-items-center justify-content-between ">*/}
                {/*            <div className="w-50 position-relative">*/}
                {/*                <div className="card2 b-s br-5 w-full bg-white position-relative z-40">*/}
                {/*                    <p className="fs49 l45  f-w-b mb-0">*/}
                {/*                        5. Make <span class="purple-text "> real money</span> answering other students' questions*/}
                {/*                    </p>*/}
                {/*                    /!*<p class="mt-30 fs24 text-black mb-0">We know you're shy, we are too.</p>*!/*/}
                {/*                </div>*/}
                {/*                <img alt="" src="/Assets/Path398.svg" className="w-img3 position-absolute end-0 bottom-0 mmb-50 mmr-40 z-0" />*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*                <img className="w-img2 mt-img ml-res" alt="" src="/Images/MakeMoney.png" />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* section 6 */}
                {/*<div className="mt-189 w-full">*/}
                {/*    <div className="d-flex align-items-center justify-content-center w-full">*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="mml-60 z-20"></img>*/}
                {/*    </div>*/}
                {/*    <div className="d-flex align-items-center justify-content-center mt-88">*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="z-20"></img>*/}
                {/*        <div className="fs50 text-dark f-w-b ml-64">Post your own questions to any  <span className="purple-text">UCLA subject area or class </span> you want</div>*/}
                {/*        <img alt="" src="/Assets/Ellipse112.svg" class="ml-64 z-20  mmt-30"></img>*/}
                {/*    </div>*/}
                {/*    <div className="mt-30 d-flex align-items-center justify-content-center">*/}
                {/*        <div className="fs27  t-s l13">*/}
                {/*            /!*See why millions of people across 195 <br /> countries use Boost.*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="d-md-flex flex-column flex-md-row align-items-center justify-content-center mt-70">*/}

                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default FAQComponent
