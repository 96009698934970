import React, {useContext, useEffect, useState} from "react";
import AnswerOfMyPrivateQuestion from "../components/AnswerofMyQuestionPage/AnswerOfQuestionAskerPerspectiveComponent";
import axios from "axios";
import queryString from "query-string";
import {Redirect, useLocation} from 'react-router-dom'
import {AccountStateContext} from "../Context";
import {initiateSocket, sendMessageToSocket, disconnectSocket, subscribeToChat} from "../Socket";

const AnswerToMyPrivateQuestionPage = () => {

    const searchQ = useLocation().search;
    const {QID} = queryString.parse(searchQ);
    const [question, setQuestion] = useState();
    const {accountState} = useContext(AccountStateContext);

    const [conversation, setConversation] = useState([]);
    const [answer, setAnswer] = useState();

    useEffect(() => {
        let params = {
            QID: QID
        };
        if (accountState.isSignedIn){
            params["UID"] = accountState.userID
        }
        axios.get('/api/private/question', {params: params}).then(response => {
            setQuestion(response.data.question);
        }).catch(err => {
            console.error(err.message)
        });
    }, [accountState.isSignedIn, QID, accountState.userID])


    useEffect(() => {
        const params2 = {
            QID: QID,
            UID: accountState.userID
        };
        axios.get('/api/client/get/question/comments', {params: params2}).then(response => {
            console.log(response, 'tiny');
            setConversation(response.data.comments)
        }).catch(err => {
            console.log(err);
        });
    },[QID, accountState]);




    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return(
        <>
            <AnswerOfMyPrivateQuestion conversation={conversation} answer={answer}  question={question}/>
        </>
    );

};

export default AnswerToMyPrivateQuestionPage;
