import React, {Component} from "react";
import ExternalFooter from "../components/common/external-footer";
import ExternalNavbar from "../components/common/external-navbar";
import styled from "styled-components";
import Meta from "../components/Meta";
const WrapperDiv = styled.div`
   margin-left: 15%;
   margin-right: 15%;
   margin-top: 5%;
   
`


const Privacy = () => {



    return (
        <>
            <Meta title={"Privacy"}/>
            <ExternalNavbar/>
            <WrapperDiv>
                <header className="App-header">
                    <h1 className="App-title">Privacy Policy</h1>


                    <h6>

                        When you use and interact with our websites or services, communicate with or otherwise contact us or visit our offices or attend our events, we may collect, use, share and process information relating to you ("Personal Data"). These Privacy Statement Highlights summarize our Personal Data processing practices and your related rights.

                    </h6>

                    <h2>Responsible Usage</h2>
                    <h6>
                        boost is the controller of your Personal Data and is responsible for its processing, unless expressly specified otherwise in our full Privacy Statement. For the avoidance of doubt, our Privacy Statement does not apply to the extent we process Personal Data in the role of a processor on behalf of our customers. A reference to “boost,” “we,” “us” or the “Company” is a reference to the boost platform.
                    </h6>



                    <h2>Purposes for which we Collect Personal Data</h2>
                    <h6>
                        We collect and process Personal Data for a variety of purposes, including:

                        The processing of your name to provide you with information about our platform, provide you with customer support or otherwise communicate with you;

                        The processing of  usage data to develop, improve, manage and customize our websites and services, to assess and improve the related user experience, to identify customer opportunities and to guarantee our websites’ and services' security and continued proper functioning;

                        To provide you with the necessary functionalities required during your use of our websites and services, and to review capacity requirements and compliance with the applicable usage terms;

                        To display personalized advertisements to you and to send to you marketing communications about us, our products and our services; and

                        We only collect and process your Personal Data to the extent it is necessary for fulfilling these purposes and where we can rely on a legal basis for such processing as set out in our full Privacy Statement. Where required, we will ask you for your prior consent to processing.

                    </h6>

                    <h2>Who do we share data with?</h2>
                    <h6>
                        We do not share data with anyone, and periodically remove data from our servers to serve a more efficient experience
                    </h6>

                    <h2>Contact Us</h2>
                    <h6>

                        To contact us, dm us on ig @boostucla
                    </h6>

                    <p></p>
                </header>
            </WrapperDiv>

            <ExternalFooter/>
        </>
    );
};

export default Privacy
