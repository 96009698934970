import React, {Component} from "react";
import VerifyEmailComponent from "../components/VerifyEmail/VerifyEmailComponent";
import ExternalFooter from "../components/common/external-footer";
import ExternalNavbar from "../components/common/external-navbar";
import {Redirect, useLocation} from "react-router-dom";
import queryString from "query-string";

const VerifyEmail = () => {

    const searchQ = useLocation().search;
    const queryObject = queryString.parse(searchQ);
    const {token} = queryObject;
    const {UID} = queryObject;

    if (!token || !UID){
        return <Redirect to="/"/>
    }else{
        return (
            <>
                <ExternalNavbar/>
                <VerifyEmailComponent token={token} UID={UID}/>
                <ExternalFooter/>
            </>
        );
    }
};

export default VerifyEmail
