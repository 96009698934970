import React, {Component, useState} from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import GeneralModal from "../common/GeneralModal";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
const SignInContainer = styled.div`
   width:60%;
   padding-top: 10vh;
   margin-top: 0em;
   margin-left: 20vw;
   margin-right: 20vw;
   margin-bottom: 20vh;
`
const ResetPWComponent = ({token}) => {

    const history = useHistory();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        let params = {
            token: token,
            password: password,
            confirmPassword: confirmPassword
        };
        axios.post('/api/reset', params).then(response => {
            history.push('/signin');
        }).catch(err => {
            setModalMessage(err?.response?.data);
            if (!!err?.response?.data){
                handleOpenShowModal();
            }
            console.log(err?.response?.data)
        });

    }

    const changePassword = (e) => {
        console.log(e.target)
        if (!!e.target?.name){
            if (e.target.name === "password"){
                setPassword(e.target.value);
            }else if (e.target.name === "confirmPassword"){
                setConfirmPassword(e.target.value);
            }
        }
    };

    const [showModal, setShowModal] = useState(false);
    const handleCloseShowModal = () => setShowModal(false);
    const handleOpenShowModal = () => setShowModal(true);
    const [modalMessage, setModalMessage] = useState('');



    return (
        <SignInContainer>
            <h1>Reset</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control name={"password"} value={password} type="password" placeholder="Pass"  onChange={changePassword} />
                </Form.Group>
                <br/>
                <Form.Group >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control name={"confirmPassword"} value={confirmPassword} type="password" placeholder="Confirm Pass"  onChange={changePassword} />
                </Form.Group>
                <br/>
                <button className="btn-hero text-white purple-bg border-0 fs17" type="submit">
                    Reset
                </button>
            </Form>

            <GeneralModal functionToExecuteUponSubmission={handleCloseShowModal} handleCloseModal={handleCloseShowModal} titleMessage={"Notice"} showModal={showModal} acceptButtonMessage={"Ok"} denyButtonMessage={"Bet"} bodyMessage={modalMessage} />
        </SignInContainer>
    );
};

export default ResetPWComponent
