import React, {Component, useEffect, useState} from "react";
import axios from "axios";
import AdminListPublicQsComponent from "../components/AdminListPublicQs/AdminListPublicQsComponent";


const AdminListPublicQs = () => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        axios.get('/api/admin/questions/public').then(response => {
        setQuestions(response.data.questions);
        console.log("whole workds gonna know", response)
        }).catch(err => {
            console.error(err.response.data)
        });
    },[]);

    return (
        <>
            <AdminListPublicQsComponent questions={questions}/>
        </>
    );
};

export default AdminListPublicQs
