import React, {Component, useEffect, useState} from "react";
import FAQComponent from "../components/FAQ/FAQComponent";
import ExternalNavbar from "../components/common/external-navbar";
import ExternalFooter from "../components/common/external-footer";
import Meta from "../components/Meta";
import axios from "axios";


const FAQ = () => {

    const [boostCourses, setBoostCourses] = useState([]);

    useEffect(() => {

        axios.get('/api/speedy/fetchboostcourses').then(response => {
            console.log(response, 'random people on the internet');
            setBoostCourses(response.data.boostClasses)
        }).catch(err => {
            console.log(err.response.data);
        });
    }, []);


    return(
        <>
            <Meta title={"Boost FAQ's"}/>
            <ExternalNavbar/>
            <FAQComponent boostCourses={boostCourses}/>
            <ExternalFooter/>
        </>
    );
};

export default FAQ
