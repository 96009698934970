import React from "react";
import Modal from "react-bootstrap/Modal";


const ErrorModal = ({showModal, handleCloseModal, errMessage}) => {


    return(
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            backdrop="static"
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Error </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h3>{errMessage}</h3>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleCloseModal} className={"btn-hero text-white bg-gray border-0 fs17"} variant="secondary" >
                    Close
                </button>
                <button onClick={handleCloseModal} className={"btn-hero text-white purple-bg border-0 fs17"} type="submit" variant="primary">Understood</button>


                {/*<ConfirmAction showConfirmAction={showConfirmAction} handleCloseConfirmAction={handleCloseConfirmAction}/>*/}
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal
