import React, {useContext} from "react";
import SignupComponent from "../components/signup/signup";
import ExternalNavbar from "../components/common/external-navbar";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";
import Meta from "../components/Meta";
import ExternalFooter from "../components/common/external-footer";


export default function Signup(props) {

    const {accountState} = useContext(AccountStateContext);
    if (accountState.isSignedIn){
        return <Redirect to="/"/>
    }else{
        return (
            <div>
                <Meta title="Sign up" />
                <ExternalNavbar/>
                <SignupComponent/>
                <ExternalFooter/>
            </div>
        )
    }
}


