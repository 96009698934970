import {Link} from "react-router-dom";
import queryString from "query-string";

const AskedFinalizedQuestionCard = ({ cardData, disabled, handleButton }) => {

    const queryObject = {
        QID: cardData.QID
    };
    const query = queryString.stringify(queryObject);
    const qURL = `/my/privatequestion?${query}`



    const color = (questionState) => {
        if (questionState === "accepted"){
            return "#0dd55d"; //.bg-green
        }else{
            return `#bf5dab`;
        }
    }

    const determinePricetag = (question) => {
        if (question.state === "accepted" ||
            question.state === "paidFor" ||
            question.state === "answered" ||
            question.state === "finalized"){
            return question?.finalAgreedUponPrice
        }else{
            return question?.offeringPrice;
        }
    };

    const defaultPic = "https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80";
    const mainPic = !!cardData && !!cardData.questionMainPic ? cardData.questionMainPic : defaultPic;
    return (
        <div className="card-with-pic position-relative">
            {/*{disabled && <div className="b-overlay position-absolute inset-0" />}*/}
            <Link to={qURL} ><img className="w-full img" alt="" src={mainPic} /></Link>
            <div className="px-15px">
                <Link ><h2 className="mb-0 n-props clr-grey fs12 f-w-s mt-20">{cardData.questionTitle}</h2></Link>
                <div className="d-flex justify-content-between align-items-center mt-30">
                    <div className="d-flex align-items-center">
                        {/*<img className="mr-10 user" src="/Assets/user.png" alt="" />*/}
                        {/*<p className="mb-0  n-props clr-grey fs12 font-weight-semibold">{cardData.authorName}</p>*/}
                    </div>
                    <p className="n-props fs-20 f-w-b mb-0" style={{ color: color(cardData.state) }}>
                        {/*${determinePricetag(cardData)}*/}
                    </p>
                </div>
                {disabled && (cardData.state === "answered" || cardData.state === "finalized") && (
                    <div className="mt-10 d-flex align-items-center justify-content-between">
                        <p className="fs12 text-gray2 mb-0">Your answer is here❗️❗️❗️❗️</p>
                        <Link to={qURL}><button  className="border-0 br-7 bg-green-light text-white py-8 px-12 fs12 f-w-s">Go to Answer</button></Link>
                    </div>
                )}

                {disabled && cardData.state === "paidFor" && (
                    <div className="mt-10 d-flex align-items-center justify-content-between">
                        <p className="fs12 text-gray2 mb-0">Waiting for them to answer. Hold tight...</p>
                    </div>
                )}

                {disabled && cardData.state === "accepted" && (
                    <div className="mt-10 d-flex align-items-center justify-content-between">
                        <p className="fs12 text-gray2 mb-0">Waiting for them to pay, hold on...</p>
                    </div>
                )}
            </div>
        </div>
    );
};
export default AskedFinalizedQuestionCard;
