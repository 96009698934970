import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";

export default function ClassNavigation() {

    const [eligibleQuarters, setEligibleQuarters] = useState([]);
    const history = useHistory();

    const searchQ = useLocation().search;
    const searchQObject = queryString.parse(searchQ);
    const queryQuarterYear = searchQObject.quarteryear;
    const querySearchSubArea = searchQObject.subarea;
    const querySearchCourseNum = searchQObject.coursenum;
    const [localSearchSubjectAreaAbbrev, setLocalSearchSubjectAreaAbbrev] = useState(!!querySearchSubArea ? querySearchSubArea : '');
    const [localSearchCourseNum, setLocalSearchCourseNum] = useState(!!querySearchCourseNum ? querySearchCourseNum : '');

    const changeSearchPeriodOption = (event) => {

        let options = {
            quarteryear: event.target.value
        }
        if (!!querySearchSubArea){
            options["subarea"] = querySearchSubArea;
            if (!!querySearchCourseNum){
                options["coursenum"] = querySearchCourseNum;
            }
        }
        let q = queryString.stringify(options);
        history.push(`/classes?${q}`)
    };

    const changeSearchSubjectAreaAbbrev = (event) => setLocalSearchSubjectAreaAbbrev(event.target.value);
    const changeSearchCourseNum = (event) => setLocalSearchCourseNum(event.target.value);

    const keyDownSearchSearchCourse = (event) => {
        if (event.key === 'Enter') {
            searchCourseFunc();
        }

    }
    const searchCourseFunc = () => {
        // let options = {
        //     searchterm: localSearchSubjectAreaAbbrev
        // }
        let options = {
            subarea: localSearchSubjectAreaAbbrev
        };
        if (!!localSearchCourseNum && !!localSearchSubjectAreaAbbrev){
            options["coursenum"] = localSearchCourseNum;
        }

        if (!!queryQuarterYear){
            options["quarteryear"] = queryQuarterYear;
        }
        let q = queryString.stringify(options);
        history.push(`/classes?${q}`)
    };



    useEffect(() => {
        axios.get("/api/fetchcourses/eligiblequarters").then(response => {
            setEligibleQuarters(response.data.quarters);
        }).catch(err => {
           console.log(err?.response?.data);
        });
    },[]);



    const firstSelectElementName = () => {
        // If query had a quarter selected, then return it, otherwise, return default
        return !!queryQuarterYear ? queryQuarterYear : "Quarter";
    };

    const returnCourseNumberInput = () => {
        if (!!localSearchSubjectAreaAbbrev){
            return(
                <>
                    <div className=" w-156px h-49px br-12 ml-10 d-flex align-items-center b-input pl-6 flex-shrink-0">
                        <input onKeyPress={keyDownSearchSearchCourse} value={localSearchCourseNum} onChange={changeSearchCourseNum} placeholder="Course Number" className="fs14 h-full border-0 p-g pl-5 bg-transparent" />
                    </div>
                </>
            );
        }else{
            return null;
        }

    }


    return (
        <div className="d-xl-flex  align-items-center justify-content-between w-full mt-43 mb-43">
            <div className="d-flex justify-content-center justify-content-between align-items-center">
                <div className="h-49px br-12 bg-white  shadow-1 px-18 d-flex flex-column justify-content-center">
                    <select onChange={changeSearchPeriodOption} defaultValue={0} className="border-0 focus-outline-none bg-white fs14 text-dark2">
                        <option value={0} disabled>
                            {firstSelectElementName()}
                        </option>
                        {quarterOptions(eligibleQuarters)}
                    </select>
                </div>

                <div className=" w-250px h-49px br-12 ml-10 d-flex align-items-center b-input pl-6 flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#455a64" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx={10} cy={10} r={7} />
                        <line x1={21} y1={21} x2={15} y2={15} />
                    </svg>
                    <input value={localSearchSubjectAreaAbbrev} onChange={changeSearchSubjectAreaAbbrev} placeholder="Subject Area" className="fs14 h-full border-0 p-g pl-5 bg-transparent" />
                </div>
                {returnCourseNumberInput()}
                <button onClick={searchCourseFunc} className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14" >
                    Search
                </button>
            </div>
            <div className="mt-res h-49px br-12 bg-white  shadow-1 px-6 d-flex flex-column justify-content-center ml-10 fs14 text-dark2">
                <div className="d-flex align-items-center justify-content-between">
                    <button className="p-10 bg-white shadow-1 br-12 text-dark2 border-0">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={24} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                    </button>
                    <div className="d-flex align-items-center text-dark2 ml-20  mr-20">
                        <div className="fs14 cursor-pointer ">01</div>
                        <div className="fs14  ml-6">.</div>
                        <div className="fs14  ml-6">.</div>
                        <div className="fs14  ml-6">.</div>
                        <button className="p-12 purple-light shadow-1 br-12 text-dark2 border-0 fs14  text-white ml-6">24</button>
                        <div className="fs14  ml-6">.</div>
                        <div className="fs14  ml-6">.</div>
                        <div className="fs14  ml-6">.</div>
                        <div className="fs14 cursor-pointer ml-6">48</div>
                    </div>
                    <button className="p-10 bg-white shadow-1 br-12 text-dark2 border-0">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={24} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}


const quarterOptions = (eligibleQuarters) => {
    return eligibleQuarters.map(quarter => {
        const val = `${quarter.session} ${quarter.year}`
        return (
            <option value={val}>{val}</option>
        );
    });
};