import React, {useState, useContext} from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form"
import {Link} from 'react-router-dom';
import axios from "axios";
import {AccountStateContext} from "../../Context";
import ErrorModal from "../common/ErrorModal";

/** Styled Components Declaration */
const SignInContainer = styled.div`
   width:60%;
   padding-top: 10vh;
   margin-top: 0em;
   margin-left: 20vw;
   margin-right: 20vw;
   margin-bottom: 20vh;
`


export default function SigninComponent() {

    const {setAccountState} = useContext(AccountStateContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const stateForRequest = {
            email: email,
            password: password
        }

        axios.post('/api/signin', stateForRequest)
            .then(response => {

                /*
                // we need this format in order to update the context/state.
                // in order for a rerender, we need a new object, but if we reassign just certain values/fields within object,
                // then it would not detect a new object, and wont rerender
                // with this, it will detect it
                */
                localStorage.setItem('accountState', JSON.stringify(response.data.userObject));
                setAccountState(prevAccountState => ({
                    ...prevAccountState,
                    isSignedIn : true,
                    userID : response.data.userObject.UID,
                    firstname : response.data.userObject.firstname,
                    lastname : response.data.userObject.lastname
                }));
            })
            .catch(err => {
                // alert(err.response.data)
                setErrMessage(err.response.data);
                handleOpenShowModal();
                setPassword("");
            });
    }


    // Handles form value for email
    const changeEmail = (e) => {
        const value = e.target.value;
        setEmail(prevState => value)
    }

    // Handles form value for password
    const changePassword = (e) => {
        const value = e.target.value;
        setPassword(prevState => value);
    }


    const [showModal, setShowModal] = useState(false);
    const handleCloseShowModal = () => setShowModal(false);
    const handleOpenShowModal = () => setShowModal(true);
    const [errMessage, setErrMessage] = useState('');



    return (
        <SignInContainer>
            <h1> Sign In </h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="signinformID">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="joebruin@ucla.edu" value={email} onChange={changeEmail} />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control  type="password" placeholder="Password" value={password} onChange={changePassword}/>
                </Form.Group>
                <div>Don't have an account? <Link to='/signup' >Sign Up!</Link></div>
                <div>Forgot your password? <Link to={'/requestreset'}>Reset password</Link></div>
                <button className="btn-hero text-white purple-bg border-0 fs17" type="submit">
                    Sign In!
                </button>
            </Form>

            <ErrorModal handleCloseModal={handleCloseShowModal} showModal={showModal} errMessage={errMessage}/>
        </SignInContainer>
    );
}