import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const useGA = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            console.log(window.location.href)
            ReactGA.initialize(process.env.REACT_APP_GA_TID);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default useGA;