export const generateRandomName = () => {

    const adjective = pickRandomAdjective();
    const noun = pickRandomNoun();

    return `${adjective} ${noun}`;
}


const pickRandomAdjective = () => {
    const randomIndex = getRandomInt(positiveAdjectiveArray.length);
    return positiveAdjectiveArray[randomIndex];
};


const pickRandomNoun = () => {
    const randomIndex = getRandomInt(nouns.length);
    return nouns[randomIndex];
};

const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};

const positiveAdjectiveArray = [
    "Affectionate",
    "Agreeable",
    "Bright",
    "Charming",
    "Creative",
    "Determined",
    "Diligent",
    "Diplomatic",
    "Dynamic",
    "Energetic",
    "Friendly",
    "Funny",
    "Generous",
    "Giving",
    "Hardworking",
    "Helpful",
    "Imaginative",
    "Kind",
    "Likable",
    "Loyal",
    "Patient",
    "Polite",
    "Sincere",
    "Brave",
    "Capable",
    "Considerate",
    "Courageous",
    "Faithful",
    "Fearless",
    "Humorous",
    "Knowledgeable",
    "Loving",
    "Marvelous",
    "Nice",
    "Optimistic",
    "Passionate",
    "Persistent",
    "Romantic",
    "Self-Confident",
    "Thoughtful",
    "Warmhearted",
    "Willing",
    "Zestful",
    "Amazing",
    "Awesome",
    "Excellent",
    "Fabulous",
    "Fortuitous",
    "Gorgeous",
    "Incredible",
    "Outstanding",
    "Perfect",
    "Philosophical",
    "Remarkable",
    "Spectacular",
    "Splendid",
    "Stellar",
    "Stupendous",
    "Super",
    "Upbeat",
    "Stunning",
    "Alluring",
    "Brilliant",
    "Breathtaking",
    "Dazzling",
    "Elegant",
    "Enchanting",
    "Glowing",
    "Lovely",
    "Magnificent",
    "Shining",
    "Sleek",
    "Sparkling",
    "Vivacious",
    "Ambitious",
    "Competitive",
    "Confident",
    "Devoted",
    "Educated",
    "Efficient",
    "Fast-Paced",
    "Flexible",
    "Focused",
    "Honest",
    "Independent",
    "Inquisitive",
    "Insightful",
    "Open-Minded",
    "Organized",
    "Personable",
    "Productive",
    "Qualified",
    "Relaxed",
    "Resourceful",
    "Responsible",
    "Captivating",
    "Fantastic",
    "Heart-Warming",
    "Hilarious",
    "Imaginative",
    "Powerful",
];


const nouns = [
    "Bruin",
    "Gene Block",
    "Powell Cat",
    "Royce",
    "Musician",
    "Ralph",
    "Powell",
    "Kerckhoff",
    "Zaddy",
    "Boomer",
    "Box",
];