import React, {useContext, useEffect, useState} from 'react'
import MyProfile from "../components/profile-page/my-profile"
import axios from "axios";
import {AccountStateContext} from "../Context";
import Meta from "../components/Meta";
import {Redirect} from "react-router-dom";


export default function MyProfilePage() {

    const {accountState} = useContext(AccountStateContext);
    const [friendRequests, setFriendRequests] = useState([]);
    const [friends, setFriends] = useState([]);
    const [badges, setBadges] = useState([]);
    const [major, setMajor] = useState('');
    const [profilePictureURL, setProfilePictureURL] = useState('');
    const [coverPhoto, setCoverPhoto] = useState('');

    useEffect(() => {
        axios.get('/api/get/my/friendrequests', {params: {UID: accountState.userID}}).then(response => {
            setFriendRequests(response.data.friendRequests);
        }).catch(err => {
            console.error(err.message);
        });

        axios.get('/api/get/my/friends', {params: {UID: accountState.userID}}).then(response => {
            setFriends(response.data.friends)
        }).catch(err => {
           console.error(err.message);
        });

        axios.get("/api/profile/badges", {params: {UID: accountState.userID, searchedBadgesUID: accountState.userID}}).then(response => {

            setBadges(response.data.badges);
        }).catch(err => {
            console.error(err.message);
        });

        axios.get('/api/profile/major', {params: {UID: accountState?.userID}}).then(response => {
            setMajor(response.data.major);
        }).catch(err => {
            console.error(err.message)
        });

        axios.get('/api/profile/profileImages', {params: {UID: accountState?.userID, searchedUID:accountState?.userID}}).then(response => {
            setProfilePictureURL(response.data.profilePictureURL);
            setCoverPhoto(response.data.coverPhotoURL);

        }).catch(err => {
            console.error(err.message)
        });


    },[accountState.userID]);



    if (!accountState.isSignedIn){
        return <Redirect to="/signup"/>
    }

    return (
        <>
            <Meta title={"Profile"}/>
            <MyProfile coverPhotoURL={coverPhoto} profilePictureURL={profilePictureURL} major={major} badges={badges} setFriendRequests={setFriendRequests} friendRequests={friendRequests} friends={friends}/>
        </>
    )
}
