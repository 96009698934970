import React, {Component} from "react";
import AboutUsComponent from "../components/AboutUs/AboutUsComponent";
import ExternalNavbar from "../components/common/external-navbar";
import ExternalFooter from "../components/common/external-footer";
import Meta from "../components/Meta";

const AboutUs = () => {


    return (
        <>
            <Meta title={"Boost | About us"}/>
            <ExternalNavbar/>
            <AboutUsComponent/>
            <ExternalFooter/>
        </>
    );
};

export default AboutUs
