import React, {useContext, useEffect, useState} from "react";
import QuestionCard from "../common/questioncard-with-pic";
import Navigation from "../common/navigation";
import axios from "axios";
import {useLocation} from 'react-router-dom'
import {AccountStateContext} from "../../Context";
import queryString from "query-string"
import Meta from "../Meta";

export default function SearchQuestions({setQuestionsFoundBool}) {

    const {accountState} = useContext(AccountStateContext);

    const searchQ = useLocation().search;
    const searchedTerm = queryString.parse(searchQ).searchTerm;

    const [questions, setQuestions] = useState([]);


    useEffect(() => {
        let questionParams;
        if (accountState.isSignedIn){
            questionParams = {
                UID: accountState.userID,
                searchTerm: searchedTerm
            }
        }else{
            questionParams = {
                searchTerm: searchedTerm
            }
        }
        console.log(questionParams)
        axios.get("/api/search/questions", {params: questionParams}).then(response => {
            setQuestions(response.data.questions);
        }).catch(err => {
            console.error(err.message);
        });

    }, [accountState.isSignedIn, searchedTerm, accountState.userID]);



    const questionResults = questions.map((question, i) => {

        return (
            <div key={i} className="col-lg-3 p-25">
                <QuestionCard cardData={question} />
            </div>
        );

    });


    if (questionResults.length > 0 && !!questionResults){
        return (
            <div className="container question-alt">
                <Meta title={"Search results baby"}/>
                <Navigation/>
                <div className="row scroller">
                    {questionResults}
                </div>
            </div>
        );
    }else{
        return <h6>No questions found dang it! 🤕</h6>;
    }
}
