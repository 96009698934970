import React, {useContext, Suspense} from "react";
import {AccountStateContext} from "../../Context";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import Cards from "./data.json";
import QuestionCardWithPicture from "../common/myquestioncard-with-pic";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
const AskedPublicQuestions = ({questions}) => {

    const {accountState} = useContext(AccountStateContext);


    const convertArrIntoSegmentsOf4 = (arr) => {
        // Because of how the react cards were implemented, we have to split the data into 2d array of 4-element arrays
        let segmentOf4 = 4;
        let arrOfArr = [];
        let currArr = [];

        for (let i = 0 ; i < arr.length ; i++){
            let index = i % segmentOf4;
            currArr.push(arr[i]);

            if (index === 3){
                arrOfArr.push(currArr);
                currArr = [];
            }
        }
        if (currArr.length > 0){
            arrOfArr.push(currArr);
        }
        return arrOfArr;
    }

    const enableButton = (questionState) => {
        if (!questionState){
            return false;
        }

        return (questionState === "accepted");
    };

    const handleCheckout = async(QID) => {

        const stripe = await stripePromise;

        axios.post('/api/create-checkout-session', {QID: QID, UID: accountState.userID}).then(response => {
            return response.data;
        }).then(session => {
            return stripe.redirectToCheckout({sessionId: session.id})
        }).catch(err => {
            console.log("Error: ", err);
        });
    };


    const decideComponents = (arr) => {
        if (!!arr && arr.length > 0){
            return (
                <>
                    <Suspense fallback={<h1>Loading</h1>}>
                        <div className="row position-relative desktop-carousel">
                            <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={Cards.length} isIntrinsicHeight={true}>

                                <Slider>
                                    {carouselSegments(convertArrIntoSegmentsOf4(arr), handleCheckout, enableButton)}
                                </Slider>

                                <div className="position-absolute inset-0 m-auto arrow-left-btn d-flex align-items-center justify-content-center">
                                    <ButtonBack>
                                        <img src="/Assets/arrow-left.png" alt="" />
                                    </ButtonBack>
                                </div>
                                <div className="position-absolute inset-0 m-auto arrow-right-btn d-flex align-items-center justify-content-center">
                                    <ButtonNext>
                                        <img src="/Assets/arrow-right.png" alt="" />
                                    </ButtonNext>
                                </div>
                            </CarouselProvider>
                        </div>
                    </Suspense>
                    <div className="row position-relative mobile-carousel">
                        <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={arr.length} isIntrinsicHeight={true}>
                            <Slider>
                                {carouselSlides(arr, handleCheckout, enableButton)}
                            </Slider>
                            <div className="position-absolute inset-0 m-auto arrow-left-btn d-flex align-items-center justify-content-center">
                                <ButtonBack>
                                    <img src="/Assets/arrow-left.png" alt="" />
                                </ButtonBack>
                            </div>
                            <div className="position-absolute inset-0 m-auto arrow-right-btn d-flex align-items-center justify-content-center">
                                <ButtonNext>
                                    <img src="/Assets/arrow-right.png" alt="" />
                                </ButtonNext>
                            </div>
                        </CarouselProvider>
                    </div>
                </>
            );
        }else{
            return(
                <h5>Looks like you haven't asked any public questions yet, smarty pants 🤓🤕</h5>
            );
        }
    }


    return (
        <>
            <div className="mb-50">
                <h1 className="fs-25 f-w-b mb-25 n-props">Public Q's</h1>
                {decideComponents(questions)}

            </div>

        </>
    );
};

const carouselSlides = (questionList, handleCheckout, enableButton) => {
    return questionList.map((question, i) => {
        let slideIndex = Math.floor(i / 4);
        let individualDivIndex = i % 4;


        return (
            <Slide index={slideIndex}>
                <div className="d-flex align-items-center justify-content-between">
                    <div key={individualDivIndex} className="col-lg-3 p-25">
                        <QuestionCardWithPicture handleCheckout={handleCheckout} disabled={enableButton(question.state)}  cardData={question}/>
                    </div>
                </div>
            </Slide>
        );
    });
};
const carouselSegments = (elements, handleCheckout, enableButton) => {

    return elements.map((elementrow, i) => {
        return (
            <Slide index={i}>
                <div className="d-flex align-items-center">
                    {elementrow.map((element, ind) => {
                        return (
                            <div key={ind} className="col-lg-3 p-25">
                                <QuestionCardWithPicture handleCheckout={handleCheckout} disabled={enableButton(element.state)} cardData={element}/>
                            </div>
                        );
                    })}
                </div>
            </Slide>
        );
    });
};

export default AskedPublicQuestions;
