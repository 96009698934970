import React, {useContext, useState} from "react";
import Navigation from "../common/navigation";
import QuestionCard from "../common/questioncard-with-pic";
import AskForm from "../common/ask-question-form";
import {useHistory} from "react-router-dom";
import {AccountStateContext} from "../../Context";

const BrowseQuestionsComponent = ({questions}) => {

    const [showAskForm, setShowAskForm] = useState(false);
    const handleShowAskForm = () => setShowAskForm(true);
    const handleCloseAskForm = () => setShowAskForm(false);
    const history = useHistory();
    const {accountState} = useContext(AccountStateContext);

    const handleAskQuestionButton = () => {
        if (accountState.isSignedIn){
            handleShowAskForm();
        }else{
            history.push('/signup')
        }
    };
    return (
        <>

            <div className="container question-alt" style={{marginBottom: "400px"}}>
                {/*<Navigation/>*/}
                <div className="lg-col-3 d-flex justify-content-center justify-content-lg-end align-items-center">
                    <button onClick={handleAskQuestionButton} className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14">Ask New Question</button>
                    <AskForm showAskForm={showAskForm} handleCloseAskForm={handleCloseAskForm}/>
                </div>
                <h3>Fall 2021 Question results</h3>
                <div className="row scroller">
                    {questionResults(questions)}

                    {/*{Cards.map((card, i) => {*/}
                    {/*    return (*/}
                    {/*        <div key={i} className="col-lg-3 p-25">*/}
                    {/*            <CardWithPicture cardData={card} />*/}
                    {/*        </div>*/}
                    {/*    );*/}
                    {/*})}*/}
                </div>
            </div>
        </>
    );

};


const questionResults = (questions) => {
    return questions.map((question, i) => {
        return (
            <div key={i} className="col-lg-3 p-25">
                <QuestionCard cardData={question} />
            </div>
        );
    });
}


export default BrowseQuestionsComponent
