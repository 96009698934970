import React, {useContext, useState} from "react";
import SearchQuestions from "../components/search-questions/search-questions";
import SearchStudents from "../components/students/students";
import SearchCoursesComponent from "../components/SearchQuestions/SearchCoursesComponent";
import {Redirect, useLocation} from 'react-router-dom'
import queryString from "query-string";
import {AccountStateContext} from "../Context";
import Meta from "../components/Meta";


export default function SearchPage() {
    // return <SearchQuestions />;
    const searchQ = useLocation().search;
    const searchedTerm = queryString.parse(searchQ).searchTerm
    const {accountState} = useContext(AccountStateContext);
    const [questionsFoundBool, setQuestionsFoundBool] = useState(false);
    const [studentsFoundBool, setStudentsFoundBool] = useState(false);
    const [coursesFoundBool, setCoursesFoundBool] = useState(false);

    // if (!accountState.isSignedIn){
    //     return <Redirect to="/"/>
    // }


    return (
        <>
            <Meta title={`Search Results: ${searchedTerm}`}/>
            {renderSearchResults(searchedTerm)}
        </>
    );
}


const renderSearchResults = (searchTerm) => {


        return (
            <>
                <br/>
                <h1>Results for "{searchTerm}" </h1>
                {/*<Navigation/>*/}
                <SearchQuestions/>
                {/*<hr/>*/}
                {/*<SearchStudents />*/}
                {/*<hr/>*/}
                {/*<SearchCoursesComponent />*/}

            </>
        );
}
