import React, {useContext} from "react";
import Students from "../components/students/students";
import {Redirect} from "react-router-dom";
import {AccountStateContext} from "../Context";
export default function Student() {

    const {accountState} = useContext(AccountStateContext);


    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }
    return <Students />;
}
