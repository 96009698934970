import React, {useContext, useState} from "react";
import AskForm from "./ask-question-form";
import {AccountStateContext} from "../../Context";
import {useHistory} from "react-router-dom";

const NavTab = ({viewingThisTab, setViewingThisTab}) => {

    const [showAskForm, setShowAskForm] = useState(false);
    const handleCloseAskForm = () => setShowAskForm(false);
    const handleShowAskForm = () => setShowAskForm(true);
    const {accountState} = useContext(AccountStateContext);
    const history = useHistory();

    const handleAskQuestionButton = () => {
        if (accountState.isSignedIn){
            handleShowAskForm();
        }else{
            //Not signed in
            history.push('/signup');
        }
    };
    return (
        <div className="d-lg-flex align-items-center justify-content-between w-full mt-43 mb-43 nav-tab">
            <div className="lg-col-3"></div>

            <div className="lg-col-3 d-flex justify-content-center justify-content-lg-end align-items-center">
                <button onClick={handleAskQuestionButton} className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14">Ask New Question</button>

                <AskForm showAskForm={showAskForm} handleCloseAskForm={handleCloseAskForm}/>
            </div>
        </div>

    );
};
export default NavTab;
