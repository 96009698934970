import React, {useContext, useState} from "react";
import {AccountStateContext} from "../../Context";
import {Link} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import Form from "react-bootstrap/Form";

export default function MyProfile({coverPhotoURL, profilePictureURL, major, badges, setFriendRequests, friendRequests, friends}) {

    const {accountState} = useContext(AccountStateContext);
    const [sidebar, setSidebar] = useState(false);



    const acceptFriendRequest = (acceptThisUID) => {
        const params = {
            action: 'accept',
            UID: accountState.userID,
            theirUID: acceptThisUID
        }
        axios.post('/api/handlefriendrequest', params).then(response => {
            setFriendRequests(response.data.friendRequests);
        }).catch(err => {
            console.error(err.message);
        })
    };

    const friendRequestList = (FRs) => {
        return FRs.map(friendRequest => {
            let queryObject = {
                UID: friendRequest.UID
            }
            let query = queryString.stringify(queryObject);
            let linkToTheirProfile = `/user?${query}`;

            return(
                <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                    <Link to={linkToTheirProfile}>
                        <div className="d-flex align-items-center">
                            <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src={friendRequest.profilePictureURL} />
                            <div className="text-dark2 fs14 f-w-s ml-24">{friendRequest.firstname} {friendRequest.lastname}</div>
                        </div>
                    </Link>
                    <button onClick={() => {acceptFriendRequest(friendRequest.UID)}} className={"h-50 border-0 br-12 purple-bg f-w-b text-white px-30 py-14"} >
                        Accept
                    </button>
                </div>
            );
        });
    }

    const friendRequestsSection = (FRs) => {
        if (FRs.length > 0){
            return (
                <>
                <div className="d-flex align-items-center justify-content-between mb-30">
                    <h2 className="fs24 n-props">Friend Requests</h2>
                    <img src="/Assets/more.png" alt="" />
                </div>
                <div className="v-scroll">
                    {friendRequestList(friendRequests)}
                </div>
                <hr/>
                </>
            );
        }
    };

    const friendsList = (FL) => {
        if (FL.length > 0){
            return FL.map(friend => {
                let queryObject = {
                    UID: friend.UID
                }
                let query = queryString.stringify(queryObject);
                let linkToTheirProfile = `/user?${query}`;

                return(
                    <Link to={linkToTheirProfile}>
                        <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src={friend.profilePictureURL} />
                                <div className="text-dark2 fs14 f-w-s ml-24">{friend.firstname} {friend.lastname}</div>
                            </div>
                            {/*<img src="/Assets/icons-2.png" alt="" />*/}
                        </div>
                    </Link>
                );
            });
        }else{
            return(
                <>
                    <h6>Looks like you don't have any friends yet, I feel that on a spiritual level😢</h6>
                </>
            );
        }
    }


    const profilePictureRendering = (profilePictureURL) => {
        return profilePictureURL ? profilePictureURL : "/DefaultProfilePictures/profile0.jpg"
    }

    const coverPhotoRendering = (coverPhotoURL) => {

        return coverPhotoURL ? coverPhotoURL : "/DefaultCoverPhotos/LoadingCoverPhoto.jpg"
    }



    return (
        <div className="d-xl-flex justify-content-between mt-46 mx-auto container">
            <div className={`${sidebar ? "w-60" : "w-full"} `}>
                <img alt="" className="w-full h-330px object-cen object-cov  br-24" src={coverPhotoRendering(coverPhotoURL)} />
                <div className="pl-74 d-flex justify-content-between align-items-end">
                    <div className="d-lg-flex align-items-end mmt-40">
                        <img alt="" className=" w-129px h-129px rounded-full shadow-2 object-cover rounded-full b-white" src={profilePictureRendering(profilePictureURL)} />
                        <div className="ml-20">
                            {nameAndMajor(accountState.firstname, accountState.lastname, major)}
                        </div>
                    </div>
                    <div className="d-flex flex-column  align-items-end ml-res">
                        {/*TODO: Implement social media handles on profile page*/}
                        {/*<div className="mt-16 d-flex align-items-center">*/}
                        {/*    <img src="/Assets/instagram(1).png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/snapchat.png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/linkedin.png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/facebook(1).png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/twitter.png" alt="" />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="mt-53">
                    {badgesRendering(badges)}
                </div>

            </div>

            <div className={`mt-res d-flex align-items-start ${false ? "w-30" : "w-10"}`}>
                {/*<button className="d-none d-xl-block flex-shrink-0 rounded-full border-0 d-flex align-items-center justify-content-center h-40px w-40px purple-bg text-white">*/}
                {/*    /!*{sidebar || true ? (*!/*/}
                {/*    /!*    <svg onClick={() => setSidebar(false)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*!/*/}
                {/*    /!*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*!/*/}
                {/*    /!*        <polyline points="9 6 15 12 9 18" />*!/*/}
                {/*    /!*    </svg>*!/*/}
                {/*    /!*) : (*!/*/}
                {/*    /!*    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setSidebar(true)} className="icon icon-tabler icon-tabler-chevron-left" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*!/*/}
                {/*    /!*        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*!/*/}
                {/*    /!*        <polyline points="15 6 9 12 15 18" />*!/*/}
                {/*    /!*    </svg>*!/*/}
                {/*    /!*)}*!/*/}
                {/*</button>*/}
                {sidebar && false && (
                    <div className="ml-10 w-full">

                        {friendRequestsSection(friendRequests)}

                        <div className="d-flex align-items-center justify-content-between mb-30">
                            <h2 className="fs24 n-props">Friends</h2>
                            <img src="/Assets/more.png" alt="" />
                        </div>
                        <div className="v-scroll2">
                            {friendsList(friends)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}



const nameAndMajor = (firstname, lastname, major) => {

    return (
        <>
            <p className="fs35 mb-0">{firstname} {lastname}</p>
            <p className="fs12">{major}</p>
        </>
    )
}



const badgesRendering = (badges) => {
    const badgesMapping = badges?.map(badge => {
       return(
           <>
               <div className="bg-orange rounded-full px-12 py-8 shadow-4 fs13 m-10"> {badge.courseSubjectArea} {badge.courseNumber}</div>
           </>
       );
    });


    const determineRendering = (badges) => {
        if (!!badges && badges.length > 0){
            return badgesMapping;
        }else{
            return <p>You don't have any badges yet!</p>
        }
    }

    return null;
    // return (
    //     <>
    //         <p className="fs24">Class Badges Earned</p>
    //         <div className="d-flex flex-wrap align-items-center mt-25">
    //             {determineRendering(badges)}
    //         </div>
    //     </>
    //
    // );
};