import React, {Component, useContext, useEffect, useState} from "react";
import SignedInHomeComponent from "../components/SignedInHome/SignedInHomeComponent";
import axios from "axios";
import {AccountStateContext} from "../Context";
import Meta from "../components/Meta";
import {Redirect} from "react-router-dom";

const SignedInHome = () => {

    const {accountState} = useContext(AccountStateContext);
    const [accountSubState, setAccountSubState] = useState('');
    const [boostCourses, setBoostCourses] = useState([]);

    useEffect(() => {

        const params = {UID: accountState.userID};
        axios.get('/api/accountstate', {params: params}).then(response => {
            setAccountSubState(response.data.state);
        }).catch(err => {
            console.log(err.response.data);
        });
    }, [accountState.userID]);


    useEffect(() => {

        axios.get('/api/speedy/fetchboostcourses').then(response => {
            setBoostCourses(response.data.boostClasses)
        }).catch(err => {
            console.log(err.response.data);
        });
    }, []);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return (
        <>
            <Meta title={"Boost | Home"}/>
            <SignedInHomeComponent boostSupportedCoursesList={boostCourses} subscriptionMessage={accountSubState}/>
        </>
    );
};

export default SignedInHome
