import { Link } from "react-router-dom";
import {useContext} from "react";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";
export default function Navigation() {

    const {accountState} = useContext(AccountStateContext);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>

    }else{
        return <Redirect to="/questions"/>
    }
}
