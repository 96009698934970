import React, {useContext, useState} from "react";
import axios from "axios";
import {AccountStateContext} from "../../Context";
import {Link} from "react-router-dom";
import queryString from "query-string";


export default function Index({setToggle, setCourse, isEnrolled, course, friendsList, roster}) {

    const [sidebar, setSidebar] = useState(true);
    const {accountState} = useContext(AccountStateContext);

    const determineEnrollUnenrollButton = (isEnrolled) => {
        if (!isEnrolled){
            return(
                <button onClick={handleEnroll} className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14">Enroll</button>
            );
        }else{
            return(
                <button onClick={handleUnenroll} className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14">Unenroll</button>
            );
        }
    }

    const handleEnroll = (event) => {
        enrollInCourse(course, accountState.userID, setCourse);
        setToggle(prev => {
            return !prev
        });
    };
    const handleUnenroll = (event) => {
        unenrollInCourse(course, accountState.userID, setCourse);
        setToggle(prev => {
            return !prev
        });
    };

    const sectionRendering = (courseSection) => {
        if (!!courseSection){
            return (
                <>
                    <p className="mb-0 fs15 ">Section: {courseSection}</p>
                </>
            );
        }else{
            return null;
        }
    };


    return (
        <div className="py-30 mx-auto container">
            <div className="d-flex align-items-start w-full justify-content-between">
                <div className={`w-f-i ${sidebar ? "w-65" : "w-full"} `}>
                    <div className="d-flex items-start w-full">
                        <img alt="" className="w-full h-293px object-cover br-24" src="/Assets/classalt.png" />
                        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={5} cy={12} r={1} />
                                <circle cx={12} cy={12} r={1} />
                                <circle cx={19} cy={12} r={1} />
                            </svg>
                        </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-full mt-20">
                        <p className="mb-0 fs35 text-black"><span>{course?.courseSubjectAreaAbbrev} {course?.courseNumber}</span></p>
                        <button className="border-0 h-36px br-10 bg-white shadow-2  px-10 d-flex flex-column justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={5} cy={12} r={1} />
                                <circle cx={12} cy={12} r={1} />
                                <circle cx={19} cy={12} r={1} />
                            </svg>
                        </button>
                    </div>
                    <p className="mb-0 fs15 ">{course?.courseName}</p>
                    {sectionRendering(course?.section)}
                    <div className="d-lg-flex align-items-center justify-content-between mt-10">
                        <div className="m-mb-15 d-flex align-items-center">
                            <div className="bg-white py-10 px-20 bg-white shadow-2 w-156px br-10">
                                <span><p className="mb-0 fs20 f-w-s">Professor</p> <p className="mb-0 fs20 purple-text f-w-s">{course?.professor}</p></span>
                                <p className="fs15 mb-0">{course?.quarterOffered.session} {course?.quarterOffered.year}</p>
                            </div>

                        </div>
                        {determineEnrollUnenrollButton(isEnrolled)}
                    </div>
                    {/*TODO add "similar classes section"*/}
                    {/*<div className="mt-53">*/}
                    {/*    <div className="d-flex align-items-center justify-content-between">*/}
                    {/*        <p className="fs24 mb-0">Similar Classes</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2  px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="mt-18 align-items-center justify-content-between d-desktop">*/}
                    {/*        {data.map((item, i) => {*/}
                    {/*            return (*/}
                    {/*                <div key={i} className="bg-white shadow-2 pt-8 pb-16 px-6 d-flex flex-column justify-content-center br-24 w-129px">*/}
                    {/*                    <img className="br-24 object-cover h-72px " alt="" src={item.img} />*/}
                    {/*                    <p className="mb-0 mt-13 fs15 text-center">{item.text}</p>*/}
                    {/*                </div>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*    <div className="mt-18 align-items-center justify-content-between d-mobile">*/}
                    {/*        {data.map((item, i) => {*/}
                    {/*            return (*/}
                    {/*                i < 3 && (*/}
                    {/*                    <div key={i} className="bg-white shadow-2 pt-8 pb-16 px-6 d-flex flex-column justify-content-center br-24 w-129px">*/}
                    {/*                        <img className="br-24 object-cover h-72px " alt="" src={item.img} />*/}
                    {/*                        <p className="mb-0 mt-13 fs15 text-center">{item.text}</p>*/}
                    {/*                    </div>*/}
                    {/*                )*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className={`mobile-sidebar-shrink d-flex align-items-start ml-20 ${sidebar ? "w-30" : "w-10"}`}>
                    <button className="flex-shrink-0 rounded-full border-0 d-flex align-items-center justify-content-center h-40px w-40px purple-bg text-white">
                        {sidebar ? (
                            <svg onClick={() => setSidebar(false)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <polyline points="9 6 15 12 9 18" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setSidebar(true)} className="icon icon-tabler icon-tabler-chevron-left" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <polyline points="15 6 9 12 15 18" />
                            </svg>
                        )}
                    </button>
                    {sidebar && (
                        <>
                            <div className="ml-10 w-full">
                                {friendsListRendering(friendsList)}
                                {rosterRendering(roster)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}




const enrollInCourse = (course, UID, setCourse) => {

    const params = {
        UID: UID,
        CID: course.CID
    }

    axios.post('/api/enroll', params).then(response => {

    }).catch(err => {
        console.error(err.message);
    })
}


const unenrollInCourse = (course, UID, setCourse) => {

    const params = {
        UID: UID,
        CID: course.CID
    }

    axios.post('/api/unenroll', params).then(response => {

    }).catch(err => {
        console.error(err.message);
    })
}

const rosterRendering = (roster) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-30">
                <h2 className="fs24 n-props">Roster</h2>
                <img src="/Assets/more.png" alt="" />
            </div>
            <div className="v-scroll2">

                {rosterComponents(roster)}
            </div>
        </>
    );
};

const rosterComponents = (roster) => {

    if (!!roster && roster.length > 0){

        return roster.map(student => {
            const queryObject = {
                UID: student.UID
            };

            const query = queryString.stringify(queryObject);
            const userURL = `/user?${query}`

            return (
                <>
                    <Link to={userURL}>
                        <div className="bg-white shadow-2 w-full br-24 py-22 pr-24">
                            <div className="d-flex align-items-center justify-content-between mb-15">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" />
                                    <div className="text-dark2 fs14 f-w-s ml-24">{student.firstName} {student?.lastName}</div>
                                </div>
                                <img src="/Assets/Group11.png" alt="" />
                            </div>
                        </div>
                    </Link>
                </>
            );
        });

    }else{
        return (
            <h6>Can't find anyone currently enrolled in this class, you down to be the first?🤩🤯</h6>
        );
    }
};

const friendsListRendering = (friendsList) => {

    if (!!friendsList && friendsList.length > 0){

        return friendsList.map(friend => {
            return (
                <>
                    <div className="d-flex align-items-center justify-content-between mb-30">
                        <h2 className="fs24 n-props">Friends</h2>
                        <img src="/Assets/more.png" alt="" />
                    </div>
                    <div className="v-scroll">
                        {friendComponents(friendsList)}
                    </div>
                </>
            );
        });
    }
};

const friendComponents = (friendsList) => {

    if (!!friendsList && friendsList.length > 0)


        return(

            <div className="bg-white shadow-2 w-full br-24 py-22 pr-24">
                <div className="d-flex align-items-center justify-content-between mb-15">
                    <div className="d-flex align-items-center">
                        <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" />
                        <div className="text-dark2 fs14 f-w-s ml-24">Maheen Inam</div>
                    </div>
                    <img src="/Assets/Group11.png" alt="" />
                </div>

            </div>
        );
};
