import React, {Component} from "react";
import io from "socket.io-client";
let socket;

export const initiateSocket = (room, type, referenceID) => {
    const SERVER = process.env.REACT_APP_SOCKET_DOMAIN;
    socket = io(SERVER, {
        withCredentials: true
    });
    console.log("Connecting to socket...");
    if (socket && room){
        socket.emit('joinSocketRoom', {
            type: type,
            referenceID: referenceID,
            roomID: room
        });
    }
}



export const disconnectSocket = () => {
    console.log("Disconnecting socket...");
    if (socket) socket.disconnect();
};


export const subscribeToChat = (cb) => {
    if (!socket) return(true);
    socket.on('serverToClientMessage', response => {
        return cb(null, response);
    });
}


export const sendMessageToSocket = (room, message, referenceID, type) => {
    if (socket) socket.emit('clientToServerMessage', {
        type: type,
        referenceID: referenceID,
        message: message,
        roomID: room
    });
}
