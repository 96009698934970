import React, {Component, useContext, useState} from "react";
import axios from "axios";
import {AccountStateContext} from "../../Context";
import {loadStripe} from "@stripe/stripe-js";
import GeneralModal from "../common/GeneralModal";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const SignedInHomeComponent = ({subscriptionMessage, boostSupportedCoursesList}) => {

    const {accountState} = useContext(AccountStateContext);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const handleShowVEmail = () => setShowVerifyEmailModal(true);
    const handleCloseVEmail = () => setShowVerifyEmailModal(false);


    const checkoutBasic = () => {
        if (subscriptionMessage === "email unverified"){
            handleShowVEmail();
        }else {
            checkoutPlan(accountState.userID, "basic").then(r => {
                console.log(r, "R MATEY")
            });
        }

    };
    const checkoutPremium = () => {
        if (subscriptionMessage === "email unverified"){
            handleShowVEmail();
        }else{
            checkoutPlan(accountState.userID, "premium").then(r => {
                console.log(r, "R MATEY")
            });
        }
    };

    const hasSubscription = (subMsg) => {


        if (subMsg === "email unverified" || subMsg === "never subscribed" || subMsg === "not subscribed now"){
            return (
                <>
                    <GeneralModal functionToExecuteUponSubmission={handleCloseVEmail} acceptButtonMessage={"Close"} denyButtonMessage={"Close"} bodyMessage={"Please verify your email before picking a subscription!"} handleCloseModal={handleCloseVEmail} titleMessage={"Verify your email first and foremost!"} showModal={showVerifyEmailModal} />
                    {/*// <!-- Call to Action-->*/}
                    <div class="card text-white bg-secondary my-5 py-4 text-center">
                        <div class="card-body"><p class="text-white m-0">Verify your email, then pick a subscription</p></div>
                    </div>
                    {/*// <!-- Content Row-->*/}
                    <div class="row gx-4 gx-lg-5">
                        <div class="col-md-6 mb-5">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h2 class="card-title">Basic</h2>
                                    <p class="card-text">Ask public questions to UCLA tutors & see answers to other public questions</p>
                                </div>
                                <div class="card-footer"><a onClick={checkoutBasic} style={{backgroundColor: "#bf5dab"}} class=" btn-primary btn-sm" href="#">Subscribe</a></div>
                            </div>
                        </div>
                        {/*<div class="col-md-4 mb-5">*/}
                        {/*<div class="card h-100">*/}
                        {/*    <div class="card-body">*/}
                        {/*        <h2 class="card-title">Card Two</h2>*/}
                        {/*        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod tenetur ex natus at dolorem enim! Nesciunt pariatur voluptatem sunt quam eaque, vel, non in id dolore voluptates quos eligendi labore.</p>*/}
                        {/*    </div>*/}
                        {/*    <div class="card-footer"><a class="btn btn-primary btn-sm" href="#!">More Info</a></div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div class="col-md-6 mb-5">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h2 class="card-title">Premium</h2>
                                    <p class="card-text">Ask both public and private questions, and receive a more detailed response to further help your mastery of the subject. See answers to all public questions</p>
                                </div>
                                <div class="card-footer"><a onClick={checkoutPremium} style={{backgroundColor: "#bf5dab"}} class="btn-primary btn-sm" href="#">Subscribe</a></div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };



    const eligibleBoostCourses = boostSupportedCoursesList?.map(course => {
        return `${course.courseSubjectAreaAbbrev} ${course.courseNumber}`;
    });

    const stringBoostCoursesList = eligibleBoostCourses?.join(', ');

    const decideCoursesListAppearing = (boostCourses) => {
        if (Array.isArray(boostCourses), boostCourses.length > 0){
            return (
                <>
                    <p>
                        Here is a list of the classes we currently have tutors for:
                    </p>
                    {stringBoostCoursesList}
                </>
            );
        }
    };



    return (
        <>
            {/*// <!-- Page Content-->*/}
            <div class="container px-4 px-lg-5">
                {/*// <!-- Heading Row-->*/}
                <div class="row gx-4 gx-lg-5 align-items-center my-5">
                    <div class="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="/Images/Email/UCLA_Pride.jpg" alt="..." /></div>
                    <div class="col-lg-5">
                        <h1 class="font-weight-light">Welcome to boost</h1>
                        <p>We're happy to see you here! Allow us to introduce ourselves, we're the boost team @ ucla</p>
                        <p>We're a team of students aiming to make your life easier</p>
                        <p>Consider us the Chegg of UCLA classes.</p>
                        {decideCoursesListAppearing(boostSupportedCoursesList)}
                        {/*<a class="btn btn-primary" href="#!">Call to Action!</a>*/}
                    </div>
                </div>
                {hasSubscription(subscriptionMessage)}
            </div>


        {/*// <!-- big imagge section -->*/}
        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff" class="bg_color">

            <tr>
                <td align="center">
                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">

                        <tr>
                            <td height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                        </tr>
                        <tr>
                            <td align="center" style={{fontSize: "24px", lineHeight: "35px", color: "#343434", fontFamily: "Quicksand, Calibri, sans-serif", fontWeight: "700", letterSpacing: "3px"}}
                                class="main-header">


                                <div style={{lineHeight: "35px"}}>

                                    Need help on something? <span style={{color: "#bf5dab"}} >we got you</span>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td height="10" style={{fontSize: "10px", lineHeight: "10px"}} >&nbsp;</td>
                        </tr>

                        <tr>
                            <td align="center">
                                <table border="0" width="40" align="center" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                    <tr>
                                        <td height="2" style={{fontSize: "2px", lineHeight: "2px"}}>&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                        </tr>

                        <tr>
                            <td align="center">
                                <table border="0" width="400" align="center" cellpadding="0" cellspacing="0" class="container590">
                                    <tr>
                                        <td align="center"style={{color: "#888888", lineHeight: "24px", fontSize: "16px", fontFamily: "'Work Sans', Calibri, sans-serif"}} >


                                            <div style={{lineHeight: "24px"}}>
                                                With a basic plan, you can ask questions that will be shown to everyone who visits the site
                                            </div>
                                            <div style={{lineHeight: "24px"}}>
                                                With a premium plan, you can ask a private question that will include a more detailed explanation aimed at deepening your understanding of the subject
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td height="25" style={{fontSize: "25px", lineHeight: "25px"}} >&nbsp;</td>
                        </tr>

                        <tr>
                            <td align="center">
                                <table border="0" align="center" width="160" cellpadding="0" cellspacing="0" bgcolor="bf5dab" >

                                    <tr>
                                        <td height="10" style={{fontSize: "10px", lineHeight: "10px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="center" style={{ color: "#FFFFFF", fontSize: "14px", fontFamily:"'Work Sans', Calibri, sans-serif", lineHeight:"26px"}}>


                                            <div style={{lineHeight: "26px"}}>
                                                <a href="/questions" style={{color: "#FFFFFF", textDecoration: "none"}}>Ask a question</a>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="10" style={{fontSize: "10px", lineHeight: "10px"}}>&nbsp;</td>
                                    </tr>

                                </table>
                            </td>
                        </tr>

                    </table>

                </td>
            </tr>

            <tr class="hide">
                <td height="25" style={{fontSize: "25px", lineHeight: "25px"}}>&nbsp;</td>
            </tr>
            <tr>
                <td height="40" style={{fontSize: "40px", lineHeight: "40px"}}>&nbsp;</td>
            </tr>

        </table>
        {/*// <!-- end section -->*/}

        {/*// <!-- big imagge section -->*/}
        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff" class="bg_color">

            <tr>
                <td align="center">
                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">
                        <tr>

                            <td align="center" class="section-img">
                                <a href="" style={{borderStyle: "none", display: "block", border: 0}} ><img src="/Images/daddyGene.jpg" style={{display: "block", width: "690px"}} width="690" border="0" alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                        </tr>
                        <tr>
                            <td align="center" style={{fontSize: "24px", lineHeight: "35px", color: "#343434", fontFamily: "Quicksand, Calibri, sans-serif", fontWeight: "700", letterSpacing: "3px"}}
                                class="main-header">


                                <div style={{lineHeight: "35px"}}>

                                    Bruins are <span style={{color: "#bf5dab"}} >finally back on campus!</span>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td height="10" style={{fontSize: "10px", lineHeight: "10px"}} >&nbsp;</td>
                        </tr>

                        <tr>
                            <td align="center">
                                <table border="0" width="40" align="center" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                    <tr>
                                        <td height="2" style={{fontSize: "2px", lineHeight: "2px"}}>&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                        </tr>

                        <tr>
                            <td align="center">
                                <table border="0" width="400" align="center" cellpadding="0" cellspacing="0" class="container590">
                                    <tr>
                                        <td align="center"style={{color: "#888888", lineHeight: "24px", fontSize: "16px", fontFamily: "'Work Sans', Calibri, sans-serif"}} >


                                            <div style={{lineHeight: "24px"}}>
                                                It's been a long time coming, so glad to see the ocean of students back on campus
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td height="25" style={{fontSize: "25px", lineHeight: "25px"}} >&nbsp;</td>
                        </tr>

                        {/*<tr>*/}
                        {/*    <td align="center">*/}
                        {/*        <table border="0" align="center" width="160" cellpadding="0" cellspacing="0" bgcolor="bf5dab" >*/}
                        
                        {/*            <tr>*/}
                        {/*                <td height="10" style={{fontSize: "10px", lineHeight: "10px"}} >&nbsp;</td>*/}
                        {/*            </tr>*/}
                        
                        {/*            <tr>*/}
                        {/*                <td align="center" style={{ color: "#FFFFFF", fontSize: "14px", fontFamily:"'Work Sans', Calibri, sans-serif", lineHeight:"26px"}}>*/}
                        
                        
                        {/*                    <div style={{lineHeight: "26px"}}>*/}
                        {/*                        <a href="/questions" style={{color: "#FFFFFF", textDecoration: "none"}}>Ask a question</a>*/}
                        {/*                    </div>*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        
                        {/*            <tr>*/}
                        {/*                <td height="10" style={{fontSize: "10px", lineHeight: "10px"}}>&nbsp;</td>*/}
                        {/*            </tr>*/}
                        
                        {/*        </table>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}

                    </table>

                </td>
            </tr>

            <tr class="hide">
                <td height="25" style={{fontSize: "25px", lineHeight: "25px"}}>&nbsp;</td>
            </tr>
            <tr>
                <td height="40" style={{fontSize: "40px", lineHeight: "40px"}}>&nbsp;</td>
            </tr>

        </table>
        {/*// <!-- end section -->*/}

        {/*// <!--  50% image -->*/}
        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">
            <tr>
                <td align="center">
                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">

                        <tr>
                            <td>
                                <table border="0" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">


                                    <tr>
                                        <td align="center">
                                            <a href="" style={{borderStyle:"none", border: 0 }}><img src="/Images/Email/classroomquestions.jpg" style={{display: "block", width: "200px"}} width="280" border="0" alt="" /></a>
                                        </td>
                                    </tr>
                                </table>

                                <table border="0" width="5" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">
                                    <tr>
                                        <td width="5" height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                                    </tr>
                                </table>

                                <table border="0" width="260" align="right" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">
                                    <tr>
                                        <td align="left" style={{fontSize: "22px", lineHeight: "35px", color:"#3d3d3d", fontFamily: "Quicksand, Calibri, sans-serif", fontWeight: "700", letterSpacing: "3px" }}
                                            class="align-center main-header">


                                            <div style={{lineHeight: "35px"}} >

                                                boost FAQ's

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left">
                                            <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                <tr>
                                                    <td align="center">
                                                        <table align="center" width="40" border="0" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                                            <tr>
                                                                <td height="2" style={{fontSize: "2px", lineHeight: "2px"}} ></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left" style={{fontSize: "16px", lineHeight: "24px", color: "#88888", fontFamily: "'Work Sans', Calibri, sans-serif"}}
                                            class="align-center">


                                            <div style={{lineHeight: "24px"}}>

                                                Here are some commonly asked questions about the boost platform and the team, look through these!

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="25" style={{fontSize: "25px", lineHeight: "25px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left">
                                            <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                <tr>
                                                    <td align="center">
                                                        <table border="0" align="center" width="120" cellpadding="0" cellspacing="0" style={{ backgroundColor: "#bf5dab", border: "1px solid #eeeeee"}}>

                                                            <tr>
                                                                <td height="5" style={{fontSize: "5px", lineHeight: "5px"}}>&nbsp;</td>
                                                            </tr>

                                                            <tr>
                                                                <td align="center" style={{ color: "#bf5dab", fontSize: "14px", fontFamily: "'Work Sans', Calibri, sans-serif", lineHeight:"20px"}}>

                                                                    <div style={{lineHeight: "20px"}}>
                                                                        <a href="/FAQ" style={{color: "#ffffff", textDecoration: "none"}}>READ MORE</a>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td height="8" style={{fontSize: "8px", lineHeight: "8px"}} >&nbsp;</td>
                                                            </tr>

                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                </table>

                            </td>
                        </tr>

                    </table>
                </td>
            </tr>

            <tr>
                <td height="40" style={{fontSize: "40px", lineHeight: "40px"}} >&nbsp;</td>
            </tr>

        </table>
        {/*// <!-- end section -->*/}

        {/*// <!--  50% image -->*/}
        <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">
            <tr>
                <td align="center">
                    <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">

                        <tr>
                            <td>
                                <table border="0" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">


                                    <tr>
                                        <td align="center">
                                            <a href="" style={{borderStyle:"none", border: 0 }}><img src="/Images/Email/gears.jpg" style={{display: "block", width: "200px"}} width="280" border="0" alt="" /></a>
                                        </td>
                                    </tr>
                                </table>

                                <table border="0" width="5" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">
                                    <tr>
                                        <td width="5" height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                                    </tr>
                                </table>

                                <table border="0" width="260" align="right" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                       class="container590">
                                    <tr>
                                        <td align="left" style={{fontSize: "22px", lineHeight: "35px", color:"#3d3d3d", fontFamily: "Quicksand, Calibri, sans-serif", fontWeight: "700", letterSpacing: "3px" }}
                                            class="align-center main-header">


                                            <div style={{lineHeight: "35px"}} >

                                                How it works

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left">
                                            <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                <tr>
                                                    <td align="center">
                                                        <table align="center" width="40" border="0" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                                            <tr>
                                                                <td height="2" style={{fontSize: "2px", lineHeight: "2px"}} ></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left" style={{fontSize: "16px", lineHeight: "24px", color: "#88888", fontFamily: "'Work Sans', Calibri, sans-serif"}}
                                            class="align-center">


                                            <div style={{lineHeight: "24px"}}>

                                                The rundown on how it works around here

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="25" style={{fontSize: "25px", lineHeight: "25px"}} >&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td align="left">
                                            <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                <tr>
                                                    <td align="center">
                                                        <table border="0" align="center" width="120" cellpadding="0" cellspacing="0" style={{ backgroundColor: "#bf5dab", border: "1px solid #eeeeee"}}>

                                                            <tr>
                                                                <td height="5" style={{fontSize: "5px", lineHeight: "5px"}}>&nbsp;</td>
                                                            </tr>

                                                            <tr>
                                                                <td align="center" style={{ color: "#bf5dab", fontSize: "14px", fontFamily: "'Work Sans', Calibri, sans-serif", lineHeight:"20px"}}>


                                                                    <div style={{lineHeight: "20px"}}>
                                                                        <a href="/howitworks"style={{color: "#ffffff", textDecoration: "none"}}>READ MORE</a>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td height="8" style={{fontSize: "8px", lineHeight: "8px"}} >&nbsp;</td>
                                                            </tr>

                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                </table>

                            </td>
                        </tr>

                    </table>
                </td>
            </tr>

            <tr>
                <td height="40" style={{fontSize: "40px", lineHeight: "40px"}} >&nbsp;</td>
            </tr>

        </table>
        {/*// <!-- end section -->*/}

            {/*// <!--  50% image -->*/}
            <table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">
                <tr>
                    <td align="center">
                        <table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">

                            <tr>
                                <td>
                                    <table border="0" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                           class="container590">


                                        <tr>
                                            <td align="center">
                                                <a href="" style={{borderStyle:"none", border: 0 }}><img src="/Images/Email/teamwork.jpg" style={{display: "block", width: "200px"}} width="280" border="0" alt="" /></a>
                                            </td>
                                        </tr>
                                    </table>

                                    <table border="0" width="5" align="left" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                           class="container590">
                                        <tr>
                                            <td width="5" height="20" style={{fontSize: "20px", lineHeight: "20px"}} >&nbsp;</td>
                                        </tr>
                                    </table>

                                    <table border="0" width="260" align="right" cellpadding="0" cellspacing="0" style={{borderCollapse: "collapse", msoTableLspace: "0pt", msoTableRspace:"0pt"}}
                                           class="container590">
                                        <tr>
                                            <td align="left" style={{fontSize: "22px", lineHeight: "35px", color:"#3d3d3d", fontFamily: "Quicksand, Calibri, sans-serif", fontWeight: "700", letterSpacing: "3px" }}
                                                class="align-center main-header">


                                                <div style={{lineHeight: "35px"}} >

                                                    About us

                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td align="left">
                                                <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                    <tr>
                                                        <td align="center">
                                                            <table align="center" width="40" border="0" cellpadding="0" cellspacing="0" bgcolor="eeeeee">
                                                                <tr>
                                                                    <td height="2" style={{fontSize: "2px", lineHeight: "2px"}} ></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td height="15" style={{fontSize: "12px", lineHeight: "12px"}} >&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td align="left" style={{fontSize: "16px", lineHeight: "24px", color: "#88888", fontFamily: "'Work Sans', Calibri, sans-serif"}}
                                                class="align-center">


                                                <div style={{lineHeight: "24px"}}>

                                                    A bit about us, the boost team!

                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td height="25" style={{fontSize: "25px", lineHeight: "25px"}} >&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td align="left">
                                                <table border="0" align="left" cellpadding="0" cellspacing="0" class="container590">
                                                    <tr>
                                                        <td align="center">
                                                            <table border="0" align="center" width="120" cellpadding="0" cellspacing="0" style={{ backgroundColor: "#bf5dab", border: "1px solid #eeeeee"}}>

                                                                <tr>
                                                                    <td height="5" style={{fontSize: "5px", lineHeight: "5px"}}>&nbsp;</td>
                                                                </tr>

                                                                <tr>
                                                                    <td align="center" style={{ color: "#bf5dab", fontSize: "14px", fontFamily: "'Work Sans', Calibri, sans-serif", lineHeight:"20px"}}>


                                                                        <div style={{lineHeight: "20px"}}>
                                                                            <a href="/aboutus"style={{color: "#ffffff", textDecoration: "none"}}>READ MORE</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td height="8" style={{fontSize: "8px", lineHeight: "8px"}} >&nbsp;</td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                    </table>

                                </td>
                            </tr>

                        </table>
                    </td>
                </tr>

                <tr>
                    <td height="40" style={{fontSize: "40px", lineHeight: "40px"}} >&nbsp;</td>
                </tr>

            </table>
            {/*// <!-- end section -->*/}
        </>
    );
};

const checkoutPlan = async(UID, subType) => {
    const stripe = await stripePromise;
    const params = {
        UID: UID,
        subscriptionType: subType
    };
    axios.post("/api/create-checkout-session-subscription", params).then(response => {
        console.log(response)
        stripe.redirectToCheckout({sessionId: response.data.id})
    }).catch(err => {
        console.log(err)
    });

};

export default SignedInHomeComponent
