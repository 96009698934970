import React, {useContext} from "react";
import { Link } from "react-router-dom";
import {AccountStateContext} from "../../Context";

const ExternalNavbar = () => {

    const {accountState} = useContext(AccountStateContext);

    const signinAndOutComponent = () => {
        if (!accountState.isSignedIn){
            return(
                <>
                    <Link to="/browse/questions">
                        <li className=" text-gray1 f-w-b ml-20 ">Questions</li>
                    </Link>
                    <Link to="/howitworks">
                        <li className=" text-gray1 f-w-b ml-20 ">How it works</li>
                    </Link>
                    <Link to="/signin">
                        <li className=" text-gray1 f-w-b ml-20 ">Sign In</li>
                    </Link>
                    <Link to="/signup">
                        <li className=" text-gray1 f-w-b ml-20 ">Sign Up</li>
                    </Link>
                </>
            )
        }else{
            return(<div></div>)
        }
    }

    const navigateSignedIn = () => {
        if (accountState.isSignedIn){
            return (
                <>
                    <Link to="/questions">
                        <li className="text-gray1 f-w-b ">Questions</li>
                    </Link>
                </>
            );
        }
    };

    return (
        <>
            {/* Navbar */}
            <div className="h-full ">
                <div className="overflow-x position-relative">
                    <div className="container mx-auto pt-49 d-flex justify-content-between position-relative align-items-center">
                        <Link to="/"><img alt="" src="/Assets/Group539.svg" /></Link>
                        <ul className="d-flex list-unstyled mb-0">
                            {navigateSignedIn()}
                            {signinAndOutComponent()}
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
};
export default ExternalNavbar;
