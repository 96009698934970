import React, {Component, useContext, useState} from "react";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Link} from "react-router-dom";
import ErrorModal from "../common/ErrorModal";
import GeneralModal from "../common/GeneralModal";
import styled from "styled-components";
const SignInContainer = styled.div`
   width:60%;
   padding-top: 10vh;
   margin-top: 0em;
   margin-left: 20vw;
   margin-right: 20vw;
   margin-bottom: 20vh;
`

const RequestPWResetComponent = () => {

    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        let params = {
            email: email
        };
        axios.post('/api/forgot', params).then(response => {
            setModalMessage(response.data.message);
            handleOpenShowModal();
        }).catch(err => {
            setModalMessage(err?.response?.data);
            if (!!err?.response?.data){
                handleOpenShowModal();
            }
            console.log(err?.response?.data)
        });

    }


    // Handles form value for email
    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseShowModal = () => setShowModal(false);
    const handleOpenShowModal = () => setShowModal(true);
    const [modalMessage, setModalMessage] = useState('');



    return (
        <SignInContainer>
            <h1>Send Reset Email</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="joebruin@ucla.edu" value={email} onChange={changeEmail} />
                </Form.Group>
                <br/>
                <button className="btn-hero text-white purple-bg border-0 fs17" type="submit">
                    Send reset email
                </button>
            </Form>

            <GeneralModal functionToExecuteUponSubmission={handleCloseShowModal} handleCloseModal={handleCloseShowModal} titleMessage={"Notice"} showModal={showModal} acceptButtonMessage={"Ok"} denyButtonMessage={"Bet"} bodyMessage={modalMessage} />
        </SignInContainer>
    );
};

export default RequestPWResetComponent
