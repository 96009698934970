import React from "react";
export default function QuestionAlt() {
    return (
        <div className="container class-detail">
            <div className="row">
                <div className="col-lg-8"></div>
                <div className="col-lg-4">
                    <div className="d-flex align-items-center justify-content-between mb-30">
                        <h2 className="fs24 n-props">Noah</h2>
                        <img src="/Assets/more.png" alt="" />
                    </div>
                    <div className="v-scroll">
                        <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1584999734482-0361aecad844?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" />
                                <div className="text-dark2 fs14 f-w-s ml-24">Ageras Norjean</div>
                            </div>
                            <img src="./Assets/icons.png" alt="" />
                        </div>
                        <div className="mt-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1534&q=80" />
                                <div className="text-dark2 fs14 f-w-s ml-24">Koines segjesko</div>
                            </div>
                            <img src="./Assets/icons.png" alt="" />
                        </div>
                        <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1584999734482-0361aecad844?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" />
                                <div className="text-dark2 fs14 f-w-s ml-24">Ageras Norjean</div>
                            </div>
                            <img src="./Assets/icons.png" alt="" />
                        </div>
                        <div className="mt-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1584999734482-0361aecad844?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" />
                                <div className="text-dark2 fs14 f-w-s ml-24">Koines segjesko</div>
                            </div>
                            <img src="./Assets/icons.png" alt="" />
                        </div>
                    </div>
                    <div className="mt-46">
                        <div className="d-flex align-items-center justify-content-between mb-30">
                            <h2 className="fs24 n-props">Roster</h2>
                            <img src="./Assets/more.png" alt="" />
                        </div>
                        <div className="v-scroll2">
                        <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1486302913014-862923f5fd48?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" />
                                    <div className="text-dark2 fs14 f-w-s ml-24">Candy crush</div>
                                </div>
                                <img src="./Assets/icons-2.png" alt="" />
                            </div>
                            <div className="mt-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1534&q=80" />
                                    <div className="text-dark2 fs14 f-w-s ml-24">Jorgeo Lady</div>
                                </div>
                                <img src="./Assets/icons-1.png" alt="" />
                            </div>
                            <div className="bg-white shadow-2 w-full br-24 py-22 pr-24">
                                <div className="d-flex align-items-center justify-content-between mb-15">
                                <div className="d-flex align-items-center">
                                        <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" />
                                        <div className="text-dark2 fs14 f-w-s ml-24">Maheen Inam</div>
                                    </div>
                                    <img src="./Assets/Group11.png" alt="" />
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-15">
                                    <div className="text-dark2 fs14 f-w-s ml-40">English<br/> Literature</div>
                                    <img src="./Assets/Group13.png" alt="" />
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-40">
                                       <button className="border-0 tag-btn fs12 n-props">See Tag</button>
                                       <p className="fs10 clr-grey n-props">You have a class in 32 min</p>
                                    </div>
                                    <img src="./Assets/icons-2.png" alt="" />
                                </div>
                            </div>
                            <div className="bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1486302913014-862923f5fd48?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" />
                                    <div className="text-dark2 fs14 f-w-s ml-24">Candy crush</div>
                                </div>
                                <img src="./Assets/icons-2.png" alt="" />
                            </div>
                            <div className="mt-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1534&q=80" />
                                    <div className="text-dark2 fs14 f-w-s ml-24">Jorgeo Lady</div>
                                </div>
                                <img src="./Assets/icons-1.png" alt="" />
                            </div>
                            <div className="bg-white shadow-2 w-full br-24 py-22 pr-24">
                                <div className="d-flex align-items-center justify-content-between mb-15">
                                <div className="d-flex align-items-center">
                                        <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" />
                                        <div className="text-dark2 fs14 f-w-s ml-24">Maheen Inam</div>
                                    </div>
                                    <img src="./Assets/Group11.png" alt="" />
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-15">
                                    <div className="text-dark2 fs14 f-w-s ml-40">English<br/> Literature</div>
                                    <img src="./Assets/Group13.png" alt="" />
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-40">
                                       <button className="border-0 tag-btn fs12 n-props">See Tag</button>
                                       <p className="fs10 clr-grey n-props">You have a class in 32 min</p>
                                    </div>
                                    <img src="./Assets/icons-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
