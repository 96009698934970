import React, {Component} from "react";
import ListGroup from "react-bootstrap/ListGroup"
import ListGroupItem from "react-bootstrap/ListGroupItem"
import {Link} from "react-router-dom"
import queryString from "query-string";

const AdminListPublicQsComponent = ({questions}) => {


    const questionMap = questions?.map((question, i) => {

        console.log('takingme', question, i);
        const queryObject = {
            QID: question.QID
        };
        const query = queryString.stringify(queryObject);
        const URL = `/admin6/answer/public/answerquestion?${query}`;
        return (
            <>
                <ListGroup.Item>
                    <Link to={URL}>
                        <h6>{question?.questionTitle}{i}</h6>
                        <p>{question?.state}</p>
                        <p>{question?.dateAsked}</p>
                    </Link>
                </ListGroup.Item>
            </>
        );
    })



    return (
        <>
            {/*{questionMap}*/}
            <br/>
            <h2> Public Questions</h2>
            <hr/>
            <ListGroup>
                {questionMap}
            </ListGroup>
        </>
    );

};

export default AdminListPublicQsComponent
