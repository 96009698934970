import React, {useContext, useEffect, useState} from "react";
import ClassesNavigation from "../components/common/ClassesNavigation";
import Classes from "../components/classes/index";
import axios from "axios";
import {Redirect} from "react-router-dom";
import {AccountStateContext} from "../Context";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import Meta from "../components/Meta";

const formSearchOptions = (searchSubAreaAbbrev, searchCourseNum, searchQuarterYear) => {

    const quarterYear = searchQuarterYear?.split(' ');
    const trimSearchSub = searchSubAreaAbbrev?.trim();
    const trimCourseNum = searchCourseNum?.trim();

    const searchOptions = {};
    if (trimSearchSub !== '' && !!trimSearchSub){
        searchOptions["subjectAreaAbbrev"] = trimSearchSub;
        // We only want to deal with course number if subject area is passed in
        if (trimCourseNum !== '' && !!trimCourseNum){
            searchOptions["courseNumber"] = trimCourseNum;
        }
    }

    if (!!quarterYear){
        searchOptions["session"] = quarterYear[0];
        searchOptions["year"] = quarterYear[1];
    }

    return searchOptions;
};


export default function User() {


    const searchQObject = queryString.parse(useLocation().search);
    const [courses, setCourses] = useState([]);
    const {accountState} = useContext(AccountStateContext);
    const searchSubAreaAbbrev = searchQObject.subarea;
    const searchCourseNum = searchQObject.coursenum;
    const searchQuarterYear = searchQObject.quarteryear;

    useEffect(() => {


        const searchOptions = formSearchOptions(searchSubAreaAbbrev, searchCourseNum, searchQuarterYear);
        axios.get("/api/fetchcourses/search", {params: searchOptions}).then(response => {
            setCourses(response.data.courses);
        }).catch(err => {
            console.error(err.message);
        });
    }, [searchSubAreaAbbrev, searchCourseNum, searchQuarterYear]);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return (
        <>
            <Meta title={"Classes baby"}/>
            <div className="w-full px-67  position-relative z-50">
                <ClassesNavigation />
                <div className="mt-34 d-flex justify-content-center w-full">
                    <div className="student-main mx-auto container px-40">
                        <Classes  courses={courses}/>
                    </div>
                </div>
            </div>
        </>
    );
}
