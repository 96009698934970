import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import ExternalNavbar from "../common/external-navbar";
import ExternalFooter from "../common/external-footer";
import {AccountStateContext} from "../../Context";
import {Redirect} from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
const HomeComponent = ({boostCourses}) => {
    const [flip, setFlip] = useState(false);

    const {accountState} = useContext(AccountStateContext);

    if (accountState.isSignedIn){
        return <Redirect to="/imhome"/>
    }

    const eligibleBoostCourses = boostCourses.map(course => {
        return `${course.courseSubjectAreaAbbrev} ${course.courseNumber}`;
    });

    const FAQboostCourses = eligibleBoostCourses.join(', ');
    const SupportedCoursesComponent = (boostCourses) => {

        if (Array.isArray(boostCourses) && boostCourses.length > 0){

            return (

                <>
                    Ask private and public questions for the following UCLA classes:
                    <br/>
                    {FAQboostCourses}
                </>

            );
        }
    }

    const signupButton1 = () => {
        if (!accountState.isSignedIn){
            return (
                <Link to="/signup"><button className="btn-hero text-white purple-bg border-0 fs17">Sign up</button></Link>
            );
        }
    }
    const signupButton2 = () => {
        if (!accountState.isSignedIn){
            return (
                <Link to="/signup"><button className="btn-hero text-white purple-bg border-0 fs17 mt-50 position-relative">Try Boost</button></Link>
            );
        }
    }
    const signupButton3 = () => {
        if (!accountState.isSignedIn){
            return (
                <Link to="/signup"><button className="mt-10r px-56 purple-bg h-72px rounded-full border-0 fs23 text-white f-w-s ml-36">Sign up free</button></Link>
            );
        }
    }

    return (
        <>
            <ExternalNavbar/>
            <div className="mx-auto container">
                {/* section1 */}
                <div className="mt-131">
                    <div className=" d-md-flex flex-column flex-md-row justify-content-between align-items-center">
                        <img alt=" " src="/Assets/Ellipse186.svg" className="position-absolute start-0 w-eclipse minus-margin2 filter10" />
                        <div className="w-md-50 position-relative">
                            <p className="d-none d-md-block fs70 leading1">
                                Chegg for <br /> <span className="f-w-b">UCLA</span>
                            </p>
                            <p className="d-md-hidden  fs22 leading1">
                                Chegg for <span className="f-w-b">UCLA</span>
                            </p>
                            <p className="fs20 leading2 text-gray2">UCLA students only😤🚫🧢</p>
                            {/*<p className="fs20 leading2 text-gray2">UCLA students only</p>*/}
                            <div className="d-flex align-items-center mt-53">
                                {signupButton1()}
                                {/*<div className="ml-24 f-w-b cp">*/}
                                {/*    <u>See Damo</u>*/}
                                {/*</div>*/}
                            </div>
                            <div className="d-flex justify-content-center ">
                                <img alt="" src="Assets/Ellipse139.svg" className="position-absolute  w-25 filter10" />
                            </div>
                        </div>

                        <img className="w-autores mt-img" alt="" src="Assets/Group558.svg" />
                       {/*  <img alt="" src="./Assets/Group 543.svg" className="d-none d-xl-block position-absolute end-0 " /> */}
                    </div>
                </div>
                {/* section2 */}
                <div className="mt-300">
                    <div className=" d-md-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div className="w-50">
                            <p className="fs60 f-w-b">
                                Ask <span className="purple-text">Any Question </span>
                            </p>

                        </div>
                        <div className="w-50 position-relative  ">
                            <div className="w-section2 position-relative">
                                <img alt="" src="Assets/Ellipse185.svg" className="position-absolute start-0 z-0 w-50 translate-middle-x mt-53 filter50" />
                                <img alt="" src="Assets/Path400.svg" className="position-absolute  z-10 mmt-51 mml-47 start-0 d-none d-md-block" />
                                <img alt="" src="Assets/Path400.svg" className="position-absolute  z-0 mmb-29 mmr-30 end-0 bottom-0 d-none d-md-block" />
                                <div className="card b-s br-5 bg-white d-flex justify-content-center fs24 mml-20">I need help on my spanish project 😓😭</div>
                                <div onMouseEnter={() => setFlip(true)} onMouseLeave={() => setFlip(false)} class={`flipper ${flip && "flip"}`}>
                                    <div class="front-card">
                                        <div className="card b-s br-5 bg-white d-flex justify-content-center fs24 mt-13 mml-60">Conjugations are so confusing plz help😓</div>
                                    </div>
                                    <div class="back-card">
                                        <div className="card b-s br-5 bg-white d-flex justify-content-center fs24 mt-13 mml-60">Help me proofread my spanish paper?</div>
                                    </div>
                                </div>
                                <div className="card b-s br-5 bg-white d-flex justify-content-center fs24 mt-13 mml-20">I NEED help on my CS31 project💯</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full d-flex flex-column align-items-end mt-97 ">
                        <img alt="" className="mr-50" src="/Assets/Ellipse139.svg" />
                    </div>
                </div>
                {/* section3 */}
                <div className="mt-88 position-relative">
                    <div className="d-md-flex flex-column flex-md-row align-items-center position-relative">
                        <img alt="" src="/Assets/Ellipse139.svg" className="position-absolute  bottom-0 w-eclipse mml-178" />
                        <img className="w-img" alt="" src="/Assets/Group421.svg" />

                        <div className="w-50 ml-222">
                            <p className="d-none d-md-block  fs60 l72 position-relative">
                                Need Help
                                <br />
                                <span className="purple-text f-w-b">Getting carried?</span>
                            </p>
                            <p className="mt-img d-md-none  fs60  position-relative">
                                Need Help
                                <span className="purple-text f-w-b"> Getting carried?</span>
                            </p>
                            <img alt="" src="/Assets/Ellipse186.svg" className="  position-absolute z-0 mml-47 filter50" />
                            <p className="mt-20 fs24 text-gray2 ls72 leading167 w-75 position-relative">
                                {SupportedCoursesComponent(boostCourses)}
                            </p>
                            {signupButton2()}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end w-full">
                        <img alt="" src="/Assets/Ellipse41.svg" className="mr-50" />
                    </div>
                </div>

                {/* section 4 */}
                <img alt="" src="/Assets/Path130.svg" className="w-img position-absolute end-0 filter50 mmr-180" />
                <div className="position-relative">
                    <div className="pt-215 ">
                        <div className="w-50 position-relative">
                            <div className="h-260 d-flex flex-column align-items-center justify-content-center">
                                {/*<img alt="" className="w-img2 position-absolute  start-0 ml-20 z-20" src="/Assets/RectangleCopy2.png" />*/}
                                {/*<p className="text-white z-30 fs15 w-75 ml-res">*/}
                                {/*    Want to make money? School keep you busy?*/}
                                {/*    <br />*/}

                                {/*</p>*/}
                                {/*<h3 className="text-white z-30 fs15 w-75 mt-10 ml-res">Don't want to run around for Doordash & duffl?</h3>*/}
                                {/*<img alt="" className="end-0 position-absolute z-10 mmt-30 top-0 mmr-30" src="/Assets/Group378.svg" />*/}
                            </div>
                        </div>
                        <div className="d-md-flex flex-column flex-md-row  align-items-start">
                            <div>
                                <img className="w-img2" alt="" src="/Assets/Group462.svg" />
                            </div>
                            <div className="w-50 ml-64 mt-img">
                                <p className="fs60 l72 position-relative mb-0">
                                    Certified <span className="purple-text f-w-b">UCLA Tutors</span>
                                </p>
                                <p className="mt-30 fs24 mb-0">Get help from someone who already took your class</p>
                                {/*<p className="mt-20 fs24 text-gray2 ls72 leading167  position-relative mb-0">Answer other students' questions about classes you already took, and get paid EZ money!</p>*/}
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 5 */}
                <div className="mt-328">
                    <div className=" position-relative">
                        <img alt="" src="/Assets/Group285.svg" className="w-img3 position-absolute z-0 top-0" />
                        <div className="px-40 d-md-flex flex-column flex-md-row align-items-center justify-content-between ">
                            <div className="w-50 position-relative">
                                <div className="card2 b-s br-5 w-full bg-white position-relative z-40">
                                    <p className="fs49 l30  f-w-b mb-0">
                                        Private <span class="purple-text ">Questions</span>
                                    </p>
                                    <p class="mt-30 fs24 text-black mb-0">We know you're shy, we are too.</p>
                                    <p class="mt-20 fs24 text-gray2 ls72 l30 mb-0">Ask questions that will be hidden from public, only viewable by those who have taken the class before.</p>
                                </div>
                                <img alt="" src="/Assets/Path398.svg" className="w-img3 position-absolute end-0 bottom-0 mmb-50 mmr-40 z-0" />
                            </div>
                            <div>
                                <img className="w-img2 mt-img ml-res" alt="" src="/Assets/GroupQuestion.png" />
                            </div>
                        </div>
                    </div>
                </div>


                {/* section 6 */}
                <div className="mt-189 w-full">
                    <div className="d-flex align-items-center justify-content-center w-full">
                        <img alt="" src="/Assets/Ellipse112.svg" class="mml-60 z-20"></img>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-88">
                        <img alt="" src="/Assets/Ellipse112.svg" class="z-20"></img>
                        <div className="fs50 text-dark f-w-b ml-64">Made for UCLA students by UCLA students</div>
                        <img alt="" src="/Assets/Ellipse112.svg" class="ml-64 z-20  mmt-30"></img>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <img alt="" src="/Assets/Ellipse112.svg" className="z-20"></img>
                        <p>*Not affiliated with UCLA</p>
                        <img alt="" src="/Assets/Ellipse112.svg" className="ml-64 z-20  mmt-30"></img>
                    </div>
                    <div className="mt-30 d-flex align-items-center justify-content-center">
                        <div className="fs27  t-s l13">
                            {/*See why millions of people across 195 <br /> countries use Boost.*/}
                        </div>
                    </div>
                    <div className="d-md-flex flex-column flex-md-row align-items-center justify-content-center mt-70">
                        {signupButton3()}
                    </div>
                </div>
            <ExternalFooter/>
            </div>
        </>
    );
};
export default HomeComponent;
