import React, {Component, useEffect, useState} from "react";
import axios from "axios";
import AdminListPublicQsComponent from "../components/AdminListPublicQs/AdminListPublicQsComponent";
import AdminListPrivateQsComponent from "../components/AdminListPrivateQs/AdminListPrivateQsComponent";


const AdminListPrivateQs = () => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        axios.get('/api/admin/questions/private').then(response => {
        setQuestions(response.data.questions);
        console.log("whole worlds gonna know", response.data)
        }).catch(err => {
            console.error(err.data)
        });
    },[]);

    return (
        <>
            <AdminListPrivateQsComponent questions={questions}/>
        </>
    );
};

export default AdminListPrivateQs
