import React, {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router';
import {AccountStateContext} from "../../Context";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {loadStripe} from "@stripe/stripe-js";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CounterofferMyQuestionModal from "../manage-counter-details/CounterofferMyQuestionModal";
import GeneralModal from "../common/GeneralModal";
import Popover from "react-bootstrap/Popover";
import {Link, useHistory} from "react-router-dom"
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);



export default function ManageQuestionDetailComponent({comments, question}) {

    const {QID} = useParams();
    const {accountState} = useContext(AccountStateContext);

    const handleCheckout = async(event) => {
        const stripe = await stripePromise;

        axios.post('/api/create-checkout-session', {QID: QID, UID: accountState.userID}).then(response => {
            return response.data;
        }).then(session => {
            return stripe.redirectToCheckout({sessionId: session.id})
        }).catch(err => {
            console.log("Error: ", err);
        });
    };



    const fileCards = (attachments) => {
        return attachments.map(attachment => {
            return(
                <div className="mt-27 bg-white br-24 py-14 px-10">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="text-dark2">
                            <p className="fs20 mb-0 f-w-b">Attachment</p>
                            {/*<p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                        </div>
                        <button
                            className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right"
                                 width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1={17} y1={7} x2={7} y2={17}/>
                                <polyline points="8 7 17 7 17 16"/>
                            </svg>
                        </button>
                    </div>
                    <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src={attachment.picURL}/>
                </div>
            );
        });
    }

    const fileAttachments = (attachments) => {
        if (attachments.length > 0){
            return(
                <div className="h-390px overflow-y pr-16">
                    <div className="d-flex justify-content-between w-full">
                        <p className="fs24 mb-0">Attachments</p>
                        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx={5} cy={12} r={1} />
                                <circle cx={12} cy={12} r={1} />
                                <circle cx={19} cy={12} r={1} />
                            </svg>
                        </button>
                    </div>
                    <div>
                        {fileCards(attachments)}
                    </div>
                </div>
            );
        }
    }

    const [showDeleteModalBool, setShowDeleteModal] = useState(false);
    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleHideDeleteModal = () => setShowDeleteModal(false);

    const deleteQuestionButton = () => {
        const bodyMessage = "This will remove your question from boost, and if not answered yet, it won't be answered after deleted.";
        return (
            <>
                <OverlayTrigger
                    key="auto"
                    delay={{ show: 250, hide: 400 }}
                    placement="bottom"
                    overlay={deleteQuestionPopover()}
                >
                    <button onClick={handleShowDeleteModal} className="border-0 py-14 br-12 shadow-2 px-20 bg-Dorange text-white fs12 f-w-b ml-24">Delete</button>
                </OverlayTrigger>
                {/*<CounterofferMyQuestionModal showCounterofferModal={showDeleteModalBool} handleCloseCounterofferModal={handleHideDeleteModal} />*/}
                <GeneralModal functionToExecuteUponSubmission={handleDeleteFunction} denyButtonMessage={"Cancel"} acceptButtonMessage={"Delete"}  bodyMessage={bodyMessage} showModal={showDeleteModalBool} titleMessage={"Delete question?"} handleCloseModal={handleHideDeleteModal}/>
            </>
        );
    };


    const deleteQuestionPopover = () => {
        // This is when the asker has already returned a counteroffer to our potential answerer
        return(
            <Popover id={`popover-positioned-bottom`}>
                <Popover.Title as="h3">Delete?😲</Popover.Title>
                <Popover.Content>
                    <strong>Your question will be removed from boost🤑 </strong>
                </Popover.Content>
            </Popover>
        );
    };

    const history = useHistory();
    const handleDeleteFunction = (e) => {
        e.preventDefault();

        const params = {
            QID: QID,
            UID: accountState.userID
        };
        axios.post('/api/question/remove',params).then(response => {
            alert("Successfully removed question");
            history.push("/questions")
        }).catch(err => {
            alert("Error removeing question. Try again. Maybe reload the page? idk")
            handleHideDeleteModal();
        });
    };


    return (
        <div className="container mx-auto class-detail py-30">
            <div className="d-xl-flex">
                <div className="w-70">

                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="fs20 mb-0">
                                {/*Cost: <span className="f-w-b">${question.offeringPrice}</span>*/}
                            </p>
                            <p className="mb-0 fs15 ml-24">Question</p>
                        </div>
                        <div className="d-flex align-items-center mt-res2">
                            {/*{awaitingPaymentButton(question.state)}*/}
                            {deleteQuestionButton()}
                        </div>
                    </div>
                    <div className="bg-white br-24 px-30 py-24 shadow-2 w-full mt-20">
                        <p className="mb-0 fs25 f-w-b">{question.questionTitle}</p>
                        <p className="mb-0 fs12">
                            {question.questionDescription}
                            {/*{finalAcceptance(counterofferAccepted, question)}*/}
                        </p>
                    </div>


                    {/*<QuestionBadges badges={question.courseTags}/>*/}
                    {/*<div className="mt-20 ">*/}
                    {/*    <p className="fs24 mb-0">Counteroffers</p>*/}
                    {/*    /!*{counterofferAccordian(question.counterPrices)}*!/*/}
                    {/*</div>*/}
                    <Comments conversation={comments} QID={QID} question={question}/>
                </div>

                <div className="w-30 pl-22 mt-res">
                    {fileAttachments(question.files)}
                    {/*TODO: Make a "more questions like this" feature*/}
                    {/*<div className="mt-35 h-390px overflow-y pr-16">*/}
                    {/*    <div className="d-flex justify-content-between w-full">*/}
                    {/*        <p className="fs24 mb-0">Attachments</p>*/}
                    {/*        <button className="border-0 h-36px br-10 bg-white shadow-2 ml-6 px-10 d-flex flex-column justify-content-center">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots purple-text" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                <circle cx={5} cy={12} r={1} />*/}
                    {/*                <circle cx={12} cy={12} r={1} />*/}
                    {/*                <circle cx={19} cy={12} r={1} />*/}
                    {/*            </svg>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mt-27 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-10 bg-white br-24 py-14 px-10">*/}
                    {/*            <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                <div className="text-dark2">*/}
                    {/*                    <p className="fs20 mb-0 f-w-b">Title of pdf</p>*/}
                    {/*                    <p className="fs14 mb-0">24/10/2021 (24 Pages)</p>*/}
                    {/*                </div>*/}
                    {/*                <button className="border-0 d-flex align-items-center justify-content-center rounded-full purple-bg text-white w-40px h-40px">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                    {/*                        <line x1={17} y1={7} x2={7} y2={17} />*/}
                    {/*                        <polyline points="8 7 17 7 17 16" />*/}
                    {/*                    </svg>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*            <img className="mt-13 br-18 h-114px w-full object-cov object-cen" alt="" src="/Assets/classalt.png" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}




const Comments = ({conversation, QID, question}) => {


    const section = () => {

        return (
            <div className="mt-20 ">
                <div className="d-flex align-items-center">
                    <div className="h-7px b-purple2 w-full br-12 pr-3 pl-3 pt-3 d-flex align-items-center overflow-y">

                    </div>
                </div>
                <div className="h-390px pr-16 overflow-y mt-20">
                    {comments()}
                    {addAdditionalComment()}

                </div>
            </div>
        );

    };



    const [commentText, setCommentText] = useState('');
    const {accountState} = useContext(AccountStateContext);

    const addAdditionalComment = () => {
        const changeTextArea = (e) => setCommentText(e.target.value);
        return (

            <>
                <Form onSubmit={clientPostComment}>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Post comment</Form.Label>
                        <Form.Control value={commentText} onChange={changeTextArea} as="textarea" rows={3} />
                    </Form.Group>

                    <button className="border-0 br-12 purple-bg f-w-b text-white px-30 py-14" type="submit">
                        Post comment
                    </button>
                </Form>
            </>
        );
    };

    const clientPostComment = (e) => {
        e.preventDefault();

        const params = {
            UID: accountState.userID,
            QID:QID,
            commentText: commentText
        }
        console.log("down to my core", params)

        axios.post('/api/client/post/question/comments', params).then(response => {
            alert("Successfully posted comment")
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setCommentText('');
        });
    };


    const comments = () => {

        const isCSQuestion = question?.courseTags?.some(tag => tag.courseSubjectAreaAbbrev === "COM SCI" || tag.courseSubjectAreaAbbrev === "COMPTNG")
        const isSpanishQuestion = question?.courseTags?.some(tag => tag.courseSubjectAreaAbbrev === "SPAN")
        const profileURL_julio = "/Images/julio2383224170.jpg"
        const profileURL_jianxing = "/Images/jianxing123.jpg"
        const messages = conversation?.map(comment => {

            const name = () => {
                if (comment?.sentBy?.role === 'answerer'){
                    if (isCSQuestion){
                        return "Jianxing Liu"
                    }else if (isSpanishQuestion){
                        return "Julio Ramirez"
                    }
                }else if (comment?.sentBy?.role === 'asker'){
                    return "You"
                }
            };
            const desc = () => {
                if (comment?.sentBy?.role === 'answerer'){
                    if (isCSQuestion){
                        return "Certified UCLA CS Tutor"
                    }else if (isSpanishQuestion){
                        return "Fluent in Spanish"
                    }
                }
            };

            const profileP = () => {
                if (comment?.sentBy?.role === 'answerer'){

                    if (isCSQuestion){
                        return profileURL_jianxing;
                    }else if (isSpanishQuestion){
                        return profileURL_julio;
                    }

                }else if (comment?.sentBy?.role === 'asker'){
                    return "/DefaultProfilePictures/profile2.jpg"
                }
            };
            return (
                <>
                    <div className=" bg-white br-24 px-30 py-14 shadow-2 w-full">
                        <div className="d-flex align-items-start justify-content-between">
                            <div className="d-flex align-items-start">
                                <img className="w-40px h-40px rounded-full object-cover" src={profileP()} alt="" />
                                <div className="ml-12">
                                    <p className="fs18 f-w-b mb-0"> {name()}</p>
                                    <p className="text-dark2 mb-0 mt-4 fs14">
                                        {desc()}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">

                                <div className="ml-20">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-share" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx={6} cy={12} r={3} />
                                        <circle cx={18} cy={6} r={3} />
                                        <circle cx={18} cy={18} r={3} />
                                        <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
                                        <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="mt-20 text-dark2">
                            {comment.text}
                        </p>
                    </div>
                </>
            );
        });


        if (conversation.length > 0){
            return (
                <>
                    {messages}
                </>
            );
        }else {
            return (
                <>
                    <p>Looks like this hasn't received any responses yet! :/</p>
                </>
            );
        }
    };



    return (
        <>

            {section()}

        </>
    );


};