import React, {useContext, useEffect, useState} from "react";
import BrowseQuestionsComponent from "../components/BrowseQuestions/BrowseQuestionsComponent";
import axios from "axios";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";
import Meta from "../components/Meta";
const BrowseQuestions = () => {

    const {accountState} = useContext(AccountStateContext);
    const [questions, setQuestions] = useState([]);


    useEffect(() => {

        let params = {
            UID: accountState.userID
        };

        axios.get('/api/explore/questions', {params: params}).then(response => {
            console.log(response, "out of my house");
            setQuestions(response.data.questions);
        }).catch(err => {
            console.error(err.message);
        })
    },[accountState.userID]);



    return (
      <>
          <Meta title={"Them Q's"}></Meta>
          <BrowseQuestionsComponent questions={questions}/>
      </>
    );

};

export default BrowseQuestions
