import React, {useContext} from "react";
import Questions from "../components/dashboard-questions/questions";
import Meta from "../components/Meta";
import {Redirect} from "react-router-dom";
import {AccountStateContext} from "../Context";
export default function Index() {

    const {accountState} = useContext(AccountStateContext);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }
    return (
        <>
            <Meta title={"Dash"}/>
            <Questions />
        </>
    );
}
