import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from 'react-bootstrap/Spinner';
import React, {useContext, useEffect, useState} from "react";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import GeneralModal from "./GeneralModal";


const defaultFormInformation = {
    questionTitle: '',
    questionDescription: '',
    dateNeededBy: '',
    remainAnonymousBool: true,
    offeringPrice: 0,
    theyWillGetThisPrice:0,
    wantQuestionPublicBool: false, // if user wants it to be not public, then only students with the badge can see it
    courseName: '',
    courseNumber: '',
    courseSubjectAreas:[''], // courseSubjectArea + courseNumber = courseName. e.g. COM SCI + 180 = COM SCI 180
    courseID: ''
};

const AskForm = ({showAskForm, handleCloseAskForm}) => {

    const {accountState} = useContext(AccountStateContext);
    const [askFormInformation, setAskFormInformation] = useState(defaultFormInformation);

    // Used for adding badges/tags to question
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [enteredTags, setEnteredTags] = useState([]);
    const [coursesApplicable, setCoursesApplicable] = useState([]);
    const [subArea, setSubArea] = useState('');
    const [formCourseNum, setFormCourseNum] = useState('');
    const [possibilities, setPossibilities] = useState([]);
    const [courseNumPossibilities, setCourseNumPossibilities] = useState([]);

    const changeCurrCourseNum = (event) => {
        setFormCourseNum(event.target.value);
        findCourseNumberPossibilities(subArea, event.target.value);
    };

    const changeCurrSubArea = (event) => {
        setSubArea(event.target.value);
        findSubjectAreaPossibilities(event.target.value);
        findCourseNumberPossibilities(event.target.value, null)
    };

    useEffect(() => {
        findSubjectAreaPossibilities('');
    },[]);

    const findSubjectAreaPossibilities = (searchedSubjectAreaAbbrev) => {
        let params = {
            searchedSubjectAreaAbbrev: searchedSubjectAreaAbbrev
        };

        axios.get("/api/speedy/fetchSubjectAreaAbbreviation", {params: params}).then(response => {
            setPossibilities(response.data.possibilities);
        }).catch(err => {
            console.error(err.response?.data);
        });
    };

    const findCourseNumberPossibilities = (subjectArea, courseNum) => {

        if (!subjectArea){
            return;
        }

        let params = {
            subArea: subjectArea,
            courseNum: courseNum
        };

        axios.get('/api/speedy/fetchCourseNumGivenSubjectArea', {params: params}).then(response => {
            setCourseNumPossibilities(response.data.possibilities);
        }).catch(err => {
            console.error(err.response?.data)
        });

    };


    const change = (event) => {
        const name = event.target.name;
        //If target type is a checkbox, then use target.checked, otherwise, use target.value
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (name === 'offeringPrice'){

            const theirValue = value * (1.0 - 0.045);
            setAskFormInformation(prevState => {

                // Only updates if offeringPrice is being updated
                return { ...prevState, theyWillGetThisPrice: theirValue }
            });
        }
        setAskFormInformation(prevState => {
            // to merge states and not simplyr eplace
            return { ...prevState, [name]: value }
        });
    }

    const currentApplicableElement = enteredTags.map((course, i) => {

        return (

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>Tag {i + 1}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control autoFocus id="subjectAreaAbbrev" name="subjectAreaAbbrev" type="text" placeholder="Subject Area" value={course.courseSubjectAreaAbbrev} autoComplete="off" disabled/>
                <Form.Control name="courseNumber" type="text" placeholder="Course Number" value={course.courseNumber} autoComplete="off" disabled/>
            </InputGroup>
        );
    });

    const addNewQuestionTag = (e) => {
        e.preventDefault();
        if (!!subArea && !!formCourseNum){
            let newTag = {
                courseSubjectAreaAbbrev: subArea,
                courseNumber: formCourseNum
            };
            setEnteredTags(oldArray => [...oldArray, newTag]);
            setCoursesApplicable(oldArray => [...oldArray, JSON.stringify(newTag)]);
            setSubArea('');
            setFormCourseNum('');
        }
    };

    const enterOrTabAddNewQuestionTag = (event) => {
        if (event.key === "Enter" || event.key === "Tab"){
            event.preventDefault();
            addNewQuestionTag();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowSpinner(true);
        const formData = new FormData();
        // Add files to form data
        for (let i = 0 ; i < filesToUpload.length ; i++){
            formData.append(`files`, filesToUpload[i]);
        }

        // Add tags to form data
        for (let i = 0 ; i < coursesApplicable.length ; i++){
            formData.append(`courseTags`, coursesApplicable[i]);
        }

        formData.append('UID', accountState.userID);
        formData.append('questionTitle', askFormInformation.questionTitle);
        formData.append('questionDescription', askFormInformation.questionDescription);
        formData.append('dateNeededBy', askFormInformation.dateNeededBy);
        formData.append('remainAnonymousBool', askFormInformation.remainAnonymousBool);
        // formData.append('offeringPrice', askFormInformation.offeringPrice);
        formData.append('wantQuestionPublicBool', askFormInformation.wantQuestionPublicBool);

        axios.post('/api/askquestion', formData, {headers: {"Content-type": "multipart/form-data"}}).then(response => {
            alert("Successfully asked question");
        }).catch(err => {
            console.error(err.response.data);
            setErr(err.response.data);
            handleShowErrModal();
        }).finally(() => {
            handleCloseAskForm();
            setShowSpinner(false);
        });
    }

    const [showDateNeededByField, setShowDateNeededByField] = useState(false);

    const ifNeededByCertainDate = (showComponent) => {
        if (!!showComponent){
            return (
                <>
                    <Form.Group >
                        <Form.Label>By when do you need it?</Form.Label>
                        <Form.Control autoComplete="off" required type="date" placeholder="yyyy-mm-dd" name="dateNeededBy" onChange={change} value={askFormInformation.dateNeededBy} />
                    </Form.Group>
                    <br/>
                </>
            );
        }else{
            return null;
        }
    };

    const switchShowDateNeededByField = () => {
        setShowDateNeededByField(prevState => !prevState);
    };

    const [showErrModal, setShowErrModal] = useState(false);
    const handleShowErrModal = () => setShowErrModal(true);
    const handleCloseErrModal = () => setShowErrModal(false);
    const [err, setErr] = useState('');

    const confirmHeaderType = (header) => {

        let isValid = false;
        let type;
        switch (header) {
            case "89504e47":
                type = "image/png";
                isValid = true;
                break;
            case "47494638":
                type = "image/gif";
                isValid = false;
                break;
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                type = "image/jpeg";
                isValid = true;
                break;
            case "00018":
            case "00020":
                type = "image/heic"
                isValid = true;
                break;
        }
        return isValid;
    };

    const validateAndChangeFiles = (event) => {
        const files = event.target.files;
        for (let i = 0 ; i < files.length ; i++){
            let blob = files[i];
            let fileReader = new FileReader();
            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                let header = "";
                for(let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }
                let isValidHeader = confirmHeaderType(header);
                if (!isValidHeader){
                    event.target.value = null;
                    handleCloseAskForm();
                    setErr("Invalid file type detected, we only accept images for now:/");
                    handleShowErrModal();
                    setAskFormInformation(defaultFormInformation);
                }

            }
            fileReader.readAsArrayBuffer(blob);
        }
        setFilesToUpload(event.target.files)
    };


    const [showSpinner, setShowSpinner] = useState(false);
    const mySpinner = () => {

        const style = {textAlign: 'center', height: "300px"};
        return (
            <div style={style}>
                <div className={"centerSpinner"}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">🥴</span>
                    </Spinner>
                </div>
            </div >
        );
    };

    const formContents = () => {
        return (
            <>
                <Modal.Body>
                    {/*{mySpinner()}*/}

                    <Form id="ask-question-form" onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Question Title</Form.Label>
                            <Form.Control autoComplete="off" required name="questionTitle" type="text" placeholder="Title" value={askFormInformation.questionTitle} onChange={change} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control autoComplete="off" name="questionDescription" as="textarea" placeholder="Some further details about your question, any additional/needed information. What class is this for?" rows={3} value={askFormInformation.questionDescription} onChange={change}/>
                        </Form.Group>

                        <Form.Label>What class is this for? Add at least 1 applicable tags</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Subj Area and Course#</InputGroup.Text>
                            </InputGroup.Prepend>
                            <input id={"subareaInput"} value={subArea} autoComplete="off" onChange={changeCurrSubArea} name={"subArea"} placeholder={"Subject Area"} type="text" list={"subAreas"}/>
                            <datalist id="subAreas">
                                {renderPossibilities(possibilities)}
                            </datalist>

                            <input id={"subareaInput"} value={formCourseNum}  autoComplete="off" onChange={changeCurrCourseNum} name={"subArea"} placeholder={"Course Num"} type="text" list={"courseNums"} onKeyDown={enterOrTabAddNewQuestionTag}/>
                            <datalist id="courseNums">
                                {renderCourseNumPossibilities(courseNumPossibilities)}
                            </datalist>
                        </InputGroup>
                        {currentApplicableElement}
                        <button className={"border-0 br-12 purple-bg f-w-b text-white px-30 py-14"} onClick={addNewQuestionTag}>
                            Add tag
                        </button>
                        <br/>
                        <hr/>

                        <Form.Group >
                            <Form.Check type="checkbox" label="Do you need it by a certain date?"   checked={showDateNeededByField} onChange={switchShowDateNeededByField} />
                        </Form.Group>
                        <br/>
                        {ifNeededByCertainDate(showDateNeededByField)}
                        {/*<br/>*/}
                        {/*<Form.Group controlId="formBasicCheckbox">*/}
                        {/*    <Form.Check type="checkbox" label="Remain anonymous"  name="remainAnonymousBool" checked={askFormInformation.remainAnonymousBool} onChange={change} />*/}
                        {/*</Form.Group>*/}


                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"  label="Make question public"  name="wantQuestionPublicBool" checked={askFormInformation.wantQuestionPublicBool} onChange={change} />
                            <Form.Text className="text-muted">
                                If private, general public won't see this question.
                            </Form.Text>
                        </Form.Group>
                        <br/>
                        <Form.Group>
                            <Form.Label>Additional attachments</Form.Label>
                            <Form.File accept={"image/*"}  onChange={validateAndChangeFiles} id="exampleFormControlFile1"  multiple label="" />
                            <Form.Text className="text-muted">
                                We currently only accept images
                            </Form.Text>
                        </Form.Group>

                        <hr/>

                        {/*<Form.Label>How much will you offer to pay? In USD</Form.Label>*/}
                        {/*<Form.Row >*/}
                        {/*    <Form.Group controlId="signinformID">*/}
                        {/*        /!*<Form.Label>How much are you offerring? remember that BookStack only takes a percentage to process credit/debit information. Maybe we can include a part that says how much they would get on the other side</Form.Label>*!/*/}
                        {/*        <Form.Control required type="number" name="offeringPrice" placeholder="You pay this" step={0.01} precision={2} value={askFormInformation.offeringPrice} onChange={change} />*/}
                        {/*        <Form.Text className="text-muted">*/}
                        {/*            If you send this much*/}
                        {/*        </Form.Text>*/}
                        {/*    </Form.Group>*/}

                        {/*    <Form.Group controlId="signinformID" >*/}

                        {/*        <Form.Control type="number" min={1} precision={2} placeholder="They get this" value={askFormInformation.theyWillGetThisPrice} readOnly />*/}
                        {/*        <Form.Text className="text-muted">*/}
                        {/*            They will receive this much. boost only takes a percentage to process credit/debit information*/}
                        {/*        </Form.Text>*/}
                        {/*    </Form.Group>*/}
                        {/*</Form.Row>*/}
                        {/*<br/>*/}
                        {/*<div>This is your last chance to back out. After this, unless they scam you,*/}
                        {/*    you must pay them if there is a dispute and we deem their answer was good enough.*/}
                        {/*    Check our policies on refunds*/}
                        {/*</div>*/}

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"border-0 br-12 bg-gray f-w-b text-white px-30 py-14"} onClick={handleCloseAskForm}>
                        Close
                    </button>
                    <button className={"border-0 br-12 purple-bg f-w-b text-white px-30 py-14"} type="submit" form="ask-question-form" >Ask</button>


                    {/*<ConfirmAction showConfirmAction={showConfirmAction} handleCloseConfirmAction={handleCloseConfirmAction}/>*/}
                </Modal.Footer>
            </>
        );
    };

    const decideModalContents = () => {
        if (showSpinner){
            return (
                <>
                    {mySpinner()}
                </>
            );
        }else{
            return (
                <>
                    {formContents()}
                </>
            );
        }
    };
    return(
        <>
            <Modal
                show={showAskForm}
                onHide={handleCloseAskForm}
                backdrop="static"
                size="lg"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Question: {askFormInformation.questionTitle}</Modal.Title>
                </Modal.Header>
                {decideModalContents()}
            </Modal>
            <GeneralModal functionToExecuteUponSubmission={handleCloseErrModal} handleCloseModal={handleCloseErrModal} bodyMessage={err}  showModal={showErrModal} acceptButtonMessage={"Close"} denyButtonMessage={"Close"} titleMessage={"Error"}  />
        </>
    );
}

const renderPossibilities = (possibilities) => {
    if (!possibilities){
        return null;
    }else if(Array.isArray(possibilities) && possibilities.length > 0){
        return possibilities?.map(possibility => {

            return (
                <>
                    <option value={possibility}/>
                </>
            );

        });
    }else{
        return null;
    }
};

const renderCourseNumPossibilities = (possibilities) => {
    if (!possibilities){
        return null;
    }else if(Array.isArray(possibilities) && possibilities.length > 0){

        return possibilities?.map(possibility => {
            return (
                <>
                    <option value={possibility}/>
                </>
            );
        });
    }else{
        return null;
    }
};

export default AskForm;