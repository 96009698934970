import React from "react";
import {Link} from "react-router-dom";
import queryString from "query-string";


export default function index({courses}) {

    const courseMapping = courses.map((course,i) => {

        const queryObject = {
            CID: course?.CID
        }

        const query = queryString.stringify(queryObject);
        const classURL = `/class?${query}`;



        const courseSection = () => {
            if (!!course?.section) {
                return(
                    <>
                        <p className="fs14 d-flex align-items-center text-dark2 whitespace-nowrap ml-24 mb-0">
                            <span className="f-w-b">Section: {course?.section} </span>
                        </p>
                    </>
                );
            }
        };

        return (
            <Link to={classURL}>
            <div key={course.id} className={`rotatebtn cursor-pointer bg-white shadow-2 w-full br-24 py-22 pr-24 pl-24 d-flex align-items-center justify-content-between ${i!==0 && "mt-20"}`}>
                <div className="text-dark2 fs20 f-w-s ml-24 whitespace-nowrap">{course?.courseSubjectAreaAbbrev} {course?.courseNumber}</div>
                <p className="text-dark2 fs14 mb-0 whitespace-nowrap ml-24">
                    {course?.quarterOffered.session} <span>{course?.quarterOffered.year}</span>
                </p>
                <p className="fs14 d-flex align-items-center text-dark2 whitespace-nowrap ml-24 mb-0">
                    <span className="f-w-b">{course?.professor} </span>
                </p>
                {courseSection()}
                <button className="flex-shrink-0 ml-24 tobe border-0 purple-text w-42px h-42px bg-arrow d-flex align-items-center justify-content-center rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right " width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1={5} y1={12} x2={19} y2={12} />
                        <line x1={15} y1={16} x2={19} y2={12} />
                        <line x1={15} y1={8} x2={19} y2={12} />
                    </svg>
                </button>
            </div>
            </Link>
        );
    });

    return courseMapping;
}
