import React, {Component, useContext, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import {AccountStateContext} from "../../Context";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const AnswerPrivateQComponent = ({question, comments}) => {

    const {accountState} = useContext(AccountStateContext);
    const searchQ = useLocation().search;
    const QID = queryString.parse(searchQ).QID;
    const [comment, setComment] = useState('');


    return (
        <>
            <hr/>
            {questionInformation(question)}
            {commentsRendering(comments)}
            {answerForm(QID, accountState.userID, comment, setComment)}
        </>
    );
};


const commentsRendering = (comments) => {
    console.log(comments)
    const commentMap = comments?.map(comment => {

        return (
            <>
                <hr/>
                <p>{comment.text}</p>
            </>
        );
    });
    return (
        <>
            <h5>Comments</h5>
            {commentMap}
        </>
    );
};

const dateParse = (date) => {

    if (!!date){
        let d = new Date(date);

        const month = monthDate(d.getMonth());
        const day = d.getDate();
        const year = d.getFullYear();
        const finalDateParsed = `${month} ${day}, ${year}`;
        return finalDateParsed;
    }else{
        return null;
    }
};

const monthDate = (num) => {
    switch (num) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
        default:
            return null;

    }
};

const questionInformation = (question) => {

    // const d = new Date(question.?dateAsked);
    const d = 'car'


    const attachments = question?.files.map(file => {
       return file.image.picURL;
    });
    const attachmentsLink = (attachments) => {
        return attachments?.map((picURL, i) => {
            return (
                <a href={picURL}>Attachment #{i + 1}</a>
            );
        });
    };


    const courseTags = (tags) => {
        return tags?.map((tag, i) => {
            return (
                <>
                    <p>{tag.courseSubjectAreaAbbrev} {tag.courseNumber}</p>
                </>
            );
        });
    };

    return (
        <>

            <h5>Title {question?.questionTitle}</h5>
            <h6>Date asked: {dateParse(question?.dateAsked)}</h6>
            <h6>Description: {question?.questionDescription}</h6>
            <hr/>
            <p>Question type: {question?.qType}</p>
            <hr/>
            <h6>Course Tags</h6>
            {courseTags(question?.courseTags)}
            <hr/>
            <p>State: {question?.state}</p>
            <hr/>
            <h6>Attachments:</h6>
            {attachmentsLink(attachments)}
            <hr/>
        </>
    );
};

const answerForm = (QID, UID, text, setText) => {



    const submitMe = (e) => {
        e.preventDefault();

        const params = {
            commentText: text,
            QID: QID,
            UID: UID
        };
        console.log(params, 'im so crazy about mine')
        axios.post('/api/admin/post/question/comments', params).then(response => {
            alert("Successfully posted question");
            console.log("w a lil pocahontas", response);
        }).catch(err => {
            console.log(err.data);
        }).finally(() => {
            setText('');
        });
    };

    const changeComment = (e) => setText(e.target.value);


    return (
        <>
            <hr/>
            <h4>Add comment</h4>
            <Form onSubmit={submitMe}>
                <Form.Group className="mb-3">
                    <Form.Label>Text:</Form.Label>
                    <Form.Control value={text} onChange={changeComment} as="textarea" rows={3} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </>
    );


};

export default AnswerPrivateQComponent
