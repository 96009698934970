import React, {useContext} from "react";
import SigninComponent from "../components/signin/signin";
import ExternalNavbar from "../components/common/external-navbar";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";
import Meta from "../components/Meta";
import ExternalFooter from "../components/common/external-footer";


export default function Signin(props) {
    const {accountState} = useContext(AccountStateContext);
    if (accountState.isSignedIn){
        return <Redirect to="/"/>
    }else{
        return (
            <div>
                <Meta title="Sign in" />
                <ExternalNavbar/>
                <SigninComponent/>
                <ExternalFooter/>
            </div>
        )
    }


}


