import React, {useContext, useEffect, useState} from "react";
import SettingsComponent from "../components/SettingsComponent/SettingsComponent";
import {AccountStateContext} from "../Context";
import axios from "axios";
import {Redirect} from "react-router-dom";
import Meta from "../components/Meta";

const Settings = () => {

    const {accountState} = useContext(AccountStateContext);

    const [userSettingsInfo, setUserSettingsInfo] = useState();
    useEffect(() => {
        const params = {
            UID: accountState.userID
        }
        axios.get("/api/userinfo", {params: params}).then(response => {
            setUserSettingsInfo(response.data?.userinfo)
        }).catch(err => {
           console.error(err.message);
        });
    },[accountState.userID]);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }
    return (
        <>
            <Meta title={"Settings"}/>
        <SettingsComponent userSettingsInfo={userSettingsInfo}/>
        </>
    );

};

export default Settings
