import {useLocation} from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import {AccountStateContext} from "../../Context";
import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";


const SearchStudents = () => {

    const {accountState} = useContext(AccountStateContext);
    const searchQ = useLocation().search;
    const searchTerm = queryString.parse(searchQ).searchTerm;
    const [students, setStudents] = useState([]);

    useEffect(() => {

        let params = {
            searchTerm: searchTerm
        }

        axios.get('/api/search/users', {params: params}).then(response => {
            setStudents(response.data.users);

        }).catch(err => {
            console.error(err.message);
        })

    },[accountState.isSignedIn, searchTerm]);


    const studentResults = students.map(student => {

        let params = {
            UID: student.UID
        }

        let query = queryString.stringify(params);

        let userURL = `/user?${query}`;

        return(
            <>
            <Link to={userURL} >
                <div className="pl-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between rotatebtn cursor-pointer">
                    <div className="d-flex align-items-center">

                            <img alt="" className="rounded-full h-52px w-52px rounded-full shadow-3 object-cover mml-26" src={student.profilePictureURL} />

                        <div className="text-dark2 fs20 f-w-s ml-24 w-156px">{student.firstname} {student.lastname}</div>
                    </div>
                    {/*<div className="text-dark2 fs14 whitespace-nowrap">Computer Science</div>*/}
                    <div className="fs14 d-flex align-items-center text-dark2 whitespace-nowrap ml-24">
                        {/*<div className="f-w-b">Session:</div> Spring 2021*/}
                    </div>
                    <button className="flex-shrink-0 ml-24 tobe border-0 purple-text w-42px h-42px bg-arrow d-flex align-items-center justify-content-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right " width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <line x1={15} y1={16} x2={19} y2={12} />
                            <line x1={15} y1={8} x2={19} y2={12} />
                        </svg>
                    </button>
                </div>
            </Link>
            </>
        );
    });

    if (studentResults.length > 0 && !!studentResults){
        return (
            <div className="w-full px-67  position-relative z-50">

                <h3>Student results</h3>

                <div className="mt-34 d-flex justify-content-center w-full">
                    <div className="student-main mx-auto container px-40 w-full">
                        {studentResults}
                    </div>
                </div>
            </div>
        );
    }else{
        return <h6>No students found😵‍💫 you ever listen to "Lonely" by Akon? Check it out its a banger, trust</h6>;
    }
};

export default SearchStudents;
