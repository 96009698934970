import React, {useState, useContext} from "react";
import styled from "styled-components"
import Form from "react-bootstrap/Form"
import {Link} from 'react-router-dom';
import axios from "axios";
import {AccountStateContext} from "../../Context";
import ErrorModal from "../common/ErrorModal";
const SignUpContainer = styled.div`
   width:60%;
   margin-top: 20px
`


export default function SignupComponent() {

    const {setAccountState} = useContext(AccountStateContext);

    const [signupInformation, setSignupInformation] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmpassword: '',
        botfilter: '',
        agreedtoTerms: false
    });

    const [showModal, setShowModal] = useState(false);
    const handleCloseShowModal = () => setShowModal(false);
    const handleOpenShowModal = () => setShowModal(true);
    const [errMessage, setErrMessage] = useState('');


    const change = (event) => {
        const name = event.target.name;
        //If target type is a checkbox, then use target.checked, otherwise, use target.value
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        setSignupInformation(prevState => {

            // to merge states and not simply replace
            return { ...prevState, [name]: value }
        });
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        let {firstname} = signupInformation;
        let {lastname} = signupInformation;
        let {email} = signupInformation;
        let {password} = signupInformation;
        let {confirmpassword} = signupInformation;
        let {botfilter} = signupInformation;
        let {agreedtoTerms} = signupInformation;

        // We want all fields to be filled out, and matching password

        // We say "|| botfilter" because we actually never want that field to be filled out
        // If it is, then it means a bot most likely filled out the form
        //CONFIRM ALL APPROPRIATE FIELDS ENTERED
        if (!firstname || !lastname || !email || !password || !confirmpassword || botfilter || !agreedtoTerms){
            alert("Please enter all fields");
            return;
        }

        //CONFIRM MATCHING PASSWORDS
        if(password !== confirmpassword){
            alert("Passwords are not matching")
            return;
        }


        axios.post('/api/signup', signupInformation)
            .then(response => {
                localStorage.setItem('accountState', JSON.stringify(response.data.userObject));
                setAccountState({
                    isSignedIn: true,
                    userID: response.data.userId,
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    profilepictureURL: response.data.profilepictureURL
                });

                setAccountState(prevAccountState => ({
                    ...prevAccountState,
                    isSignedIn : true,
                    userID : response.data.userObject.UID,
                    firstname : response.data.userObject.firstname,
                    lastname : response.data.userObject.lastname,
                    profilepictureURL: response.data.profilepictureURL
                }));


            })
            .catch(err => {
                setErrMessage(err.response.data);
                handleOpenShowModal();
                setSignupInformation(prevSignupInfo => ({
                   ...prevSignupInfo,
                    email: "",
                    password: "",
                    confirmpassword: ""

                }));
            });
    }





    return (
        <SignUpContainer className="container question-alt">

                <ErrorModal handleCloseModal={handleCloseShowModal} showModal={showModal} errMessage={errMessage}/>
                <h1> Sign Up </h1>
                <Form onSubmit={handleSubmit}>

                    <div>Already have an account? <Link to='/signin' >Sign In!</Link></div>
                    {/*FIRST NAME*/}
                    <Form.Group controlId="signinformID">
                        <Form.Label>First name</Form.Label>
                        <Form.Control autoComplete="off" type="text" placeholder="Joe" name="firstname" value={signupInformation.firstname} onChange={change}/>
                    </Form.Group>

                    {/*LAST NAME*/}
                    <Form.Group controlId="signinformID">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control autoComplete="off" type="text" placeholder="Bruin" name="lastname" value={signupInformation.lastname} onChange={change}/>
                    </Form.Group>


                    {/*EMAIL ADDRESS*/}
                    <Form.Group controlId="signinformID">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control autoComplete="off" type="email" placeholder="joebruin@ucla.edu"  name="email" value={signupInformation.email} onChange={change} />
                        <Form.Text className="text-muted">
                            We never share your email/personal information with anyone.
                        </Form.Text>
                    </Form.Group>

                    {/*PASSWORD*/}
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control  type="password" placeholder="Password" name="password" value={signupInformation.password} onChange={change} />
                    </Form.Group>

                    {/*CONFIRM PASSWORD*/}
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" name="confirmpassword" value={signupInformation.confirmpassword} onChange={change} />
                    </Form.Group>

                    {/*BOT FILTER*/}
                    <Form.Group controlId="signupBotfilter">
                        <Form.Control autoComplete="off" type="text" name="botfilter" value={signupInformation.botfilter} onChange={change} placeholder="Enter bot" hidden/>
                    </Form.Group>


                    {/*CHECKBOX*/}
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I agree with the terms of service and privacy policy"  name="agreedtoTerms" onChange={change} checked={signupInformation.agreedtoTerms || false} required/>
                    </Form.Group>

                    <button  className="btn-hero text-white purple-bg border-0 fs17" type="submit">
                        Sign Up
                    </button>
                </Form>

        </SignUpContainer>
    );
}