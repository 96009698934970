import React, {Component} from "react";
import PickSubComponent from "../components/PickSubPage/PickSubComponent";
import Meta from "../components/Meta";

const PickSubPage = () => {

    return (
        <>
            <Meta title={"Subscriptions"}/>
            <PickSubComponent/>
        </>
    );
};

export default PickSubPage
