import React, {useContext, useEffect, useState} from "react";
import ClassDetailaltPage from "../components/class-detail-alt";
import {useLocation} from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import {AccountStateContext} from "../Context";
import {Redirect} from "react-router-dom";


export default function ClassDetailalt() {

    const {accountState} = useContext(AccountStateContext);
    const searchQ = useLocation().search;
    const {CID} = queryString.parse(searchQ);
    const [course, setCourse] = useState();
    const [roster, setRoster] = useState([]);
    const [friendsListInClass, setFriendsListInClass] = useState([]);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {

        let params = {
            UID : accountState.userID,
            CID: CID
        }


        axios.get('/api/class/my/enrollmentstatus', {params: params}).then(response => {
            setIsEnrolled(response.data.isEnrolled);
        }).catch(err => {
           console.error(err.message);
        });
    }, [course, accountState.userID, CID, toggle]);


    // get roster and friends list
    useEffect(() => {
        const params2 = {
            CID: CID,
            UID: accountState.userID
        }
        axios.get('/api/courseroster', {params: params2}).then(response => {
            setRoster(response.data.roster);
        }).catch(err => {
            console.error(err.message);
        });


        axios.get('/api/findfriendsinthisclass', {params: params2}).then(response => {
            setFriendsListInClass(response.data.friendsInThisClass)
        }).catch(err => {
           console.error(err.message);
        });

    },[course, CID, accountState.userID, toggle])


    // get course information
    useEffect(() => {
        const params = {
            CID: CID
        }
        axios.get("/api/course", {params: params}).then(response => {
            setCourse(response.data.course)
        }).catch(err => {
            console.error(err.message);
        });

    },[CID, accountState.userID]);

    if (!accountState.isSignedIn){
        return <Redirect to="/"/>
    }

    return (
        <div className="w-full">
            <ClassDetailaltPage setToggle={setToggle} setCourse={setCourse} isEnrolled={isEnrolled} friendsList={friendsListInClass} roster={roster} course={course} />
        </div>
    );
}
