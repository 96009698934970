import Meta from "../components/Meta";
import React, {useEffect, useState} from "react";
import HomeComponent from "../components/home/home";
import axios from "axios";
export default function Home() {


    const [boostCourses, setBoostCourses] = useState([]);


    useEffect(() => {

        axios.get('/api/speedy/fetchboostcourses').then(response => {
            setBoostCourses(response.data.boostClasses)
        }).catch(err => {
            console.log(err.response.data);
        });
    }, []);



    return (
        <>
            <Meta title="Boost" />
            <HomeComponent boostCourses={boostCourses} />
        </>
    );
}
