import React, {useContext, useState} from "react";
import {AccountStateContext} from "../../Context";
import axios from "axios";
import {Link} from "react-router-dom";
import queryString from "query-string";

export default function NotMyProfile({friends, user, alreadyFriends, badges, profilePictureURL, coverPhoto}) {

    const {accountState} = useContext(AccountStateContext);
    const [sidebar, setSidebar] = useState(false);

    const addFriendButton = (areFriends) => {
        if (accountState.isSignedIn){
            if (!areFriends){
                return(
                    <button onClick={addFriend} className="border-0 br-12 purple-bg f-w-b text-white px-20  py-14">Add Friend</button>
                );
            }else {
                return(
                    <button className="border-0 br-12 purple-bg f-w-b text-white px-20  py-14">Already Friends</button>
                );
            }
        }
    }

    const nameAndMajor = () => {
        try {
            return (
                <>
                    <p className="fs35 mb-0">{user.firstname} {user.lastname}</p>
                    <p className="fs12">Computer Science</p>
                </>
            )
        }catch (e) {
            return(
                <>
                    <p>Damn</p>
                </>
            );
        }
    }


    const addFriend = (event) => {

        const params = {
            UID: accountState.userID,
            addThisFriendUID: user.UID
        }

        axios.post('/api/sendfriendrequest', params ).then(response => {
        }).catch(err => {
            console.error(err.message);
        });

    }

    const friendsList = (allFriends) => {
        return allFriends.map(friend => {

            let queryObject = {
                UID: friend.UID
            }
            let query = queryString.stringify(queryObject);
            let linkToTheirProfile = `/user?${query}`;

            return (
               <Link to={linkToTheirProfile}>
                   <div className="mt-20 bg-white shadow-2 w-full br-24 py-22 pr-24 d-flex align-items-center justify-content-between">
                       <div className="d-flex align-items-center">
                           <img alt="" className="rounded-full h-42px w-42px rounded-full shadow-3 object-cover mml-26" src={friend.profilePictureURL} />
                           <div className="text-dark2 fs14 f-w-s ml-24">{friend.firstname} {friend.lastname}</div>
                       </div>
                       {/*<img src="/Assets/icons-1.png" alt="" />*/}
                   </div>
               </Link>
           );
        });
    };

    return (
        <div className="d-xl-flex justify-content-between mt-46 mx-auto container">
            <div className={`${sidebar ? "w-60" : "w-full"} `}>
                <img alt="" className="w-full h-330px object-cen object-cov  br-24" src={coverPhoto} />
                <div className="pl-74 d-flex justify-content-between align-items-end">
                    <div className="d-lg-flex align-items-end mmt-40">
                        <img alt="" className=" w-129px h-129px rounded-full shadow-2 object-cover rounded-full b-white" src={profilePictureURL} />
                        <div className="ml-20">
                            {nameAndMajor()}
                        </div>
                    </div>
                    <div className="d-flex flex-column  align-items-end ml-res">
                        {addFriendButton(alreadyFriends)}
                        {/*<div className="mt-16 d-flex align-items-center">*/}
                        {/*    <img src="/Assets/instagram(1).png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/snapchat.png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/linkedin.png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/facebook(1).png" alt="" />*/}
                        {/*    <img className="ml-20" src="/Assets/twitter.png" alt="" />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="mt-53">
                    <p className="fs24">Class Badges Earned</p>
                    <div className="d-flex flex-wrap align-items-center mt-25">

                        {badgesRendering(badges)}
                    </div>
                </div>

            </div>

            <div className={`mt-res d-flex align-items-start ${sidebar ? "w-30" : "w-10"}`}>
                <button className="d-none d-xl-block flex-shrink-0 rounded-full border-0 d-flex align-items-center justify-content-center h-40px w-40px purple-bg text-white">
                    {sidebar ? (
                        <svg onClick={() => setSidebar(false)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setSidebar(true)} className="icon icon-tabler icon-tabler-chevron-left" width={24} height={24} viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                    )}
                </button>
                {sidebar && (
                    <div className="ml-10 w-full">
                        <div className="d-flex align-items-center justify-content-between mb-30">
                            <h2 className="fs24 n-props">Friends</h2>
                            <img src="/Assets/more.png" alt="" />
                        </div>
                        <div className="v-scroll3">
                            {friendsList(friends)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}



const badgesRendering = (badges) => {

    if (badges.length > 0){
        return badges.map(badge => {
            return(
                <div className="bg-orange rounded-full px-12 py-8 shadow-4 fs13 m-10">{badge.courseSubjectArea} {badge.courseNumber}</div>
            );
        });
    }else{
        return (
            <>
                {/*<h5>You haven't earned any badges yet! Go enroll in some classes so you get credit for them here!</h5>*/}
                <h5>Looks like they don't have any badges yet, kinda sus tbh 😶‍🌫</h5>
            </>
        );
    }

}


