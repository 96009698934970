import React, {Component} from "react";
import RequestPWResetComponent from "../components/RequestPWReset/RequestPWResetComponent";
import Meta from "../components/Meta";
import ExternalNavbar from "../components/common/external-navbar";
import ExternalFooter from "../components/common/external-footer";


const RequestPWReset = () => {

    return (
        <>
            <Meta title={"You really forgot smh :/"}/>
            <ExternalNavbar/>
            <RequestPWResetComponent/>
            <ExternalFooter/>
        </>
    );
};

export default RequestPWReset
